import { merge } from 'lodash'
import React, { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KGContext } from '../../KGProvider'
import { ContentEditContext } from '../context'
import { genKey } from 'draft-js'

export const CETableContext = createContext({})
const CETableProvider = ({ children, content, index, ...props }) => {
  const { t } = useTranslation()
  const { updateContent } = useContext(ContentEditContext)
  const { addAlert } = useContext(KGContext)
  const [selected, setSelected] = useState({ col: '', row: '' })
  const [state,setState] = useState({
    cell: null,
  });

  const store = {
    content,
    index,
    ...props,
    updateContent,
    addAlert,
    select: [selected, setSelected],
    states: [state,setState],
    genKey,
    t
  }
  return (
    <CETableContext.Provider value={store}>{children}</CETableContext.Provider>
  )
}
export const connectCETable = (Comp) => (props) =>
  (
    <CETableProvider {...props}>
      <Comp {...props} />
    </CETableProvider>
  )
