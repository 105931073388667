import React, { useContext, useState } from 'react'
import { PanelItem } from '../PanelContainer'
import {
  HilightContainer,
  HilightItem,
  HilightContent,
  HilightImage
} from '../../Highlight'
import {
  Button,
  IconButton,
  MenuItem,
  Menu,
  Box,
  withStyles
} from '@material-ui/core'
import { KGContext } from '../../KGProvider'
import { SelectContent } from '../select.content'
import { ContentEditContext } from '../context'
import { genKey } from 'draft-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { DialogRemove } from '../../DialogRemove'

const Actionbox = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex: 3,
    color: ({ variant }) =>
      variant === 'left' ? theme.palette.text.primary : 'white'
  }
}))(({ classes, variant, ...props }) => (
  <Box className={classes.root} {...props} />
))

export const ContentHightlight = ({ content, index, ...props }) => {
  const { t } = useContext(KGContext)
  const store = useContext(ContentEditContext)
  const [state, setState] = useState({
    remove: null
  })
  const [variantMenu, setVariantMenu] = useState({})
  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }))
  const handleRemoveConfirm = () => {
    if (state.remove) {
      const values = content.values.filter((v) => v.key !== state.remove.key)
      store.updateContent(index, 'values', values)
      handleRemove(null)()
    }
  }
  const handleUpdateVariant = (vindex) => (event) => {
    const { myValue } = event.currentTarget.dataset
    let values = [...(content.values || [])]
    values[vindex] = { ...values[vindex], variant: myValue }
    store.updateContent(index, 'values', values)
  }

  const handleAdd = (item) => {
    const { cover, id, label, title } = item
    const values = [...(content.values || [])]
    store.updateContent(index, 'values', [
      ...values,
      {
        cover,
        id,
        title: title || label,
        key: genKey(),
        variant: 'left'
      }
    ])
  }
  const image = {
    md5: '7036e401da36e89ed08ae079e19f144c',
    thumbnail:
      '//bucket.phra.in//thumbnail/7036e401da36e89ed08ae079e19f144c.jpg',
    name: '7036e401da36e89ed08ae079e19f144c.jpg',
    size: 168816,
    url: '//bucket.phra.in/files/7036e401da36e89ed08ae079e19f144c.jpg',
    ext: 'jpg',
    id: 'niOZzr2QxFnUok2zkFhp'
  }
  return (
    <PanelItem id={content.key} index={index}>
      <HilightContainer>
        {(content.values || []).map((item, vindex) => (
          <HilightItem variant={item.variant} key={item.key}>
            <HilightContent
              primary={item.title}
              //secondary={`Descripion`}
            />
            <Actionbox variant={item.variant}>
              <IconButton
                size='small'
                color='inherit'
                onClick={handleRemove(item)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
              <IconButton
                aria-haspopup='true'
                size='small'
                color='inherit'
                onClick={(e) => setVariantMenu({ [item.key]: e.currentTarget })}
              >
                <FontAwesomeIcon icon={faAlignLeft} />
              </IconButton>
              <Menu
                //id={item.key+`-menu`}
                open={Boolean(variantMenu[item.key])}
                anchorEl={variantMenu[item.key]}
                onClose={() => setVariantMenu({})}
                disableAutoFocusItem
              >
                {['full', 'left', 'right'].map((val) => (
                  <MenuItem
                    key={val}
                    data-my-value={val}
                    onClick={handleUpdateVariant(vindex)}
                    selected={val === item.variant}
                  >
                    {val.toLocaleUpperCase()}
                  </MenuItem>
                ))}
              </Menu>
            </Actionbox>
            <HilightImage image={item.cover} />
          </HilightItem>
        ))}
        <HilightItem variant='full'>
          {' '}
          <HilightContent
            primary={
              <SelectContent onConfirm={handleAdd}>
                <Button
                  variant='outlined'
                  style={{ borderColor: 'white', color: 'white' }}
                >
                  {t('ContentEdit.AddHighlight')}
                </Button>
              </SelectContent>
            }
            //secondary={`Descripion`}
          />
          <HilightImage image={image} />
        </HilightItem>
      </HilightContainer>
      <DialogRemove
        title='Remove Highlight'
        label='Do you want to remove this Highlight?'
        open={Boolean(state.remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </PanelItem>
  )
}
