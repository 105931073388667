import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  Box
} from '@material-ui/core'
import React, { cloneElement, forwardRef, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
))

export const DialogPre = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = (o) => () => setOpen(o)
  const handleConfirm = async () => {
    if (props.onConfirm) {
      await props.onConfirm()
      setOpen(false)
    }
  }

  return (
    <Fragment>
      {props.button &&
        cloneElement(props.button, {
          onClick: handleOpen(!open)
        })}
      <Dialog
        ref={ref}
        fullWidth
        maxWidth={props.maxWidth || 'xs'}
        open={open}
        onClose={handleOpen(false)}
        TransitionComponent={Transition}
        disableEnforceFocus
      >
        <DialogTitle>{props.title || 'Title'}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          {props.secondaryActions}
          <Box flex={1} />
          {props.onConfirm && (
            <Button
              color='primary'
              children={t('Confirm')}
              {...props.confirmButtonProps}
              onClick={handleConfirm}
            />
          )}
          <Button onClick={handleOpen(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
})
