import React, { createContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import { ThemeProvider, withWidth } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import './style.css'
import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { locale_th } from './locale/th'
import { locale_en } from './locale/en'
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles'
import { merge } from 'lodash'
import { defaultTheme } from './default.theme'

const generateClassName = createGenerateClassName({
  seed: 'docs'
})

console.log(
  '%c [Document] Link => https://bha-one.web.app/ ',
  'background: #bada55; color: #000'
)

const detectionOptions = {
  lookupLocalStorage: 'i18nextLng',
  caches: [localStorage]
}
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: locale_en
      },
      th: {
        translation: locale_th
      }
    },
    lng: window.localStorage.defaultLanguage,
    detection: detectionOptions,
    fallbackLng: 'th'
  })

const loadI18Next = async () => {
  if (firebase.apps.length) {
    return [
      {
        name: 'en',
        value: merge(
          locale_en,
          (
            await firebase.firestore().collection('i18next').doc('en').get()
          ).data()
        )
      },
      {
        name: 'th',
        value: merge(
          locale_th,
          (
            await firebase.firestore().collection('i18next').doc('th').get()
          ).data()
        )
      }
    ]
    // i18n.addResourceBundle('en', 'translation', en)
    // i18n.addResourceBundle('th', 'translation', { ...th, GoGo: 'ไป ไป' })
  }
  return []
}

export const KGContext = createContext({})

const genKey = () => Math.floor(Math.random() * 1000000)

const KGProvider = withWidth()(({ children, ...props }) => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    userFetched: false
  })
  const [user, setUser] = useState(null)
  const [alerts, setAlerts] = useState([])
  const theme = createTheme(merge(defaultTheme, props.theme))

  const store = {
    ...props,
    firebase,
    firebaseLocal: () => {
      firebase.functions().useEmulator('localhost', 5001)
    },
    user,
    state: [state, setState],
    alerts: [alerts, setAlerts],
    testFunc: () => firebase.functions().useEmulator('localhost', 5001),
    addAlert: ({ label, severity }) => {
      if (label || severity) {
        setAlerts((a) => [...a, { label, severity, key: genKey() }])
      }
    },
    theme,
    t
  }

  useEffect(() => {
    if (store.firebaseConfig && !firebase.apps.length) {
      firebase.initializeApp(store.firebaseConfig)
    }

    loadI18Next().then((languages) => {
      languages.forEach(({ name, value }) =>
        i18n.addResourceBundle(name, 'translation', value)
      )
      const current = i18n.language
      i18n.changeLanguage(current)
    })

    if (!state.userFetched) {
      const watchUser = firebase.auth().onAuthStateChanged((user) => {
        setUser(user)
        setState((s) => ({ ...s, userFetched: true }))
      })
      return () => watchUser()
    }
  }, [store.firebaseConfig])

  return (
    <KGContext.Provider value={store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </KGContext.Provider>
  )
})

export const connectKG = (config) => (Comp) => (props) =>
  (
    <StylesProvider generateClassName={generateClassName}>
      <KGProvider {...props} {...(config || {})}>
        <Comp {...props} />
      </KGProvider>
    </StylesProvider>
  )
