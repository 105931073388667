import React, { useContext } from 'react'
import { Avatar, Box, Dialog, makeStyles, Paper } from '@material-ui/core'
import { StyledFirebaseAuth } from 'react-firebaseui'
import { KGContext } from '../KGProvider'
import { ForgetPassword } from './forget.password'
import { Alerts } from '../Alerts'

const avatarSize = 128
const useStyles = makeStyles((theme) => ({
  content: {
    background: `var(--sign-in-background, ${theme.palette.primary.light})`,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    minWidth: 360,
    '& .firebaseui-container': {
      boxShadow: 'none'
    }
  },
  avatarWrap: {
    position: 'relative',
    marginBottom: avatarSize / 2
  },
  avatar: {
    position: 'absolute',
    top: -(avatarSize / 2),
    left: `calc(50% - ${avatarSize / 2}px)`,
    width: avatarSize,
    height: avatarSize
  }
}))

export const EnhanceSignInOnly = () => {
  const { firebase } = useContext(KGContext)
  const classes = useStyles()

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  }

  return (
    <Dialog fullScreen open={true}>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div className={classes.avatarWrap}>
            <Avatar className={classes.avatar} />
          </div>
          <Box py={2}>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </Box>
          <Box py={2} textAlign="center">
            <ForgetPassword />
          </Box>
        </Paper>
      </div>
      <Alerts />
    </Dialog>
  )
}
