import React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core'
import { ViewParagraph } from './view.paragraph'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

import { ImageDisplay } from './image.display'

const Primary = ({ value, ...props }) => {
  if (!value || !value.type) {
    return props.label || 'Empty content'
  } else {
    switch (value.type) {
      case 'paragraph':
        return <ViewParagraph value={value.value} />
      case 'image':
        if (value.data && value.data.thumbnail) {
          return <ImageDisplay image={value.data} />
        }
      default:
        return 'Empty content'
    }
  }
}

export const ChoiceItem = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      border: `solid 1px ${theme.palette.primary.dark}`,
      '& .docs-MuiListItemIcon-root': {
        color: theme.palette.primary.contrastText
      },
      '& .docs-MuiTypography-colorTextSecondary': {
        color: 'white'
      }
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '& .public-DraftStyleDefault-block': {
      margin: 0
    }
  },
  listitemicon: {
    minWidth: 38,
    color: theme.palette.text.secondary
  }
}))(({ classes, value, ...props }) => {
  return (
    <ListItem className={classes.root} button {...props}>
      <ListItemIcon className={classes.listitemicon}>
        <FontAwesomeIcon
          color='inherit'
          size='lg'
          icon={props.selected ? faCheckCircle : faCircle}
        />
      </ListItemIcon>
      <ListItemText primary={<Primary value={value} {...props} />} />
    </ListItem>
  )
})
