import { withStyles } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { ParagraphEdit } from './Editor'
import { ParagraphView } from './View'

export const parseParagraph = (value) =>
  value &&
  Array.isArray(value.blocks) &&
  value.blocks.map((block) => block.text).join(" \n")

export const Paragraph = ({
  value,
  onChange,
  editable,
  editOnly,
  raw,
  ...props
}) => {
  const [edit, setEdit] = useState(false)

  const handleEdit = () => editable && setEdit(true)
  const handleChange = (value) => {
    setEdit(false)
    if (onChange) {
      onChange(value)
    }
  }

  if (editOnly) {
    return (
      <ParagraphEdit
        show={true}
        editOnly={editOnly}
        value={value}
        onChange={handleChange}
      />
    )
  } else if (raw) {
    return (
      value &&
      Array.isArray(value.blocks) &&
      value.blocks.map((block) => block.text).join(' ')
    )
  } else {
    return (
      <Fragment>
        <ParagraphEdit
          show={edit}
          editOnly={editOnly}
          value={value}
          onChange={handleChange}
        />
        {!edit && (
          <div onClick={handleEdit}>
            <ParagraphView value={value} displayEmpty={props.displayEmpty} />
          </div>
        )}
      </Fragment>
    )
  }
}
