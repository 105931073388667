import React, { Fragment, useContext, useState } from 'react'
import { PanelItem } from '../PanelContainer'
import { Button, MenuItem } from '@material-ui/core'

import { FeatureImage, DialogMove } from '../../FeatureImage'
import { ImagePicker } from '../../imagepicker'
import { ContentEditContext } from '../context'

export const ContentCover = ({ index, content, ...props }) => {
  const { updateContent } = useContext(ContentEditContext)
  const [open, setOpen] = useState({
    position: false
  })

  const handleOpen = (key, value) => () =>
    setOpen((o) => ({ ...o, [key]: value }))
  const handleChange = ([img]) => {
    if (img) {
      const { content, id, blurhash } = img
      updateContent(index, 'value', { ...content, id, blurhash })
    }
  }
  const handleMoveChange = (pos) =>
    updateContent(index, 'value', { ...content.value, pos })

  return (
    <Fragment>
      <PanelItem
        id={content.key}
        index={index}
        content={content}
        actions={
          <MenuItem
            onClick={handleOpen('position', true)}
            disabled={!Boolean(content.value)}
          >
            Position
          </MenuItem>
        }
      >
        <FeatureImage loading image={content.value || {}} size='large' />
        <ImagePicker onConfirm={handleChange}>
          <Button
            variant='outlined'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}
          >
            Select
          </Button>
        </ImagePicker>
      </PanelItem>
      <DialogMove
        open={open.position}
        image={content.value}
        onClose={handleOpen('position', false)}
        onSave={handleMoveChange}
      />
    </Fragment>
  )
}
