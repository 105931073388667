import React from 'react'
import { Multiple } from './multiple'
import { TrueFalse } from './TrueFalse'
import { Matching } from './matching'
import { Sorting } from './sorting'

export const QuizDisplay = ({ value, ...props }) => {
  if (!value || !value.type) {
    return null
  }
  switch (value.type) {
    case 'multiple':
      return <Multiple value={value} {...props} />
    case 'truefalse':
      return <TrueFalse value={value} {...props} />
    case 'matching':
      return <Matching value={value} {...props} />
    case 'sorting':
      return <Sorting value={value} {...props} />
    default:
      return null
  }
}

export const isQuizComplete = (data, checked) => {
  switch (data.type) {
    case 'multiple':
      return Boolean(checked)
    case 'truefalse':
      return typeof checked === 'boolean'
    case 'sorting':
      return Array.isArray(checked) && checked.length === data.options.length
    case 'matching':
      return (
        checked &&
        Object.values(checked).filter((v) => v).length ===
          data.answers.length
      )
    default:
      return false
  }
}
