import { Grid, MenuItem, Select, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '../container'
import { ImageDisplay } from '../image.display'
import { Question } from '../question'
import { shuffle } from '../shuffle'
import { ViewParagraph } from '../view.paragraph'

const MatchItem = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2)
  }
}))(({ classes, ...props }) => (
  <div className={classes.root}>
    <Grid container spacing={1} alignItems='center' {...props} />
  </div>
))

const Quiz = ({ value }) => {
  switch (value.type) {
    case 'image':
      return <ImageDisplay image={value.data} />
    case 'paragraph':
      return <ViewParagraph value={value.label} />
    default:
      console.log(value)
      return null
  }
}

export const Matching = ({ value, checked, onCheckChange, ...props }) => {
  const {t} = useTranslation()
  const [options, setOptions] = useState([])
  const [lists, setLists] = useState([])

  const getValue = (key) => (checked && checked[key]) || ""
  const handleChange = key => ({ target }) => {
    if(onCheckChange){
      const value = target.value;
      let newChecked = { ...(checked || {}) };
      newChecked[key] = value;
      onCheckChange(newChecked);
    }
  }

  useEffect(() => {
    if (value && value.answers) {
      setOptions(value.answers)
      setLists(shuffle(value.answers.map((a) => a.value)))
      // setLists(value.answers.map((a) => a.value))
    }
  }, [value])

  return (
    <Container type={value.type}>
      <Question value={value} {...props} />
      {options.map((option) => (
        <MatchItem key={option.key}>
          <Grid item xs={8}>
            <Quiz value={option} />
          </Grid>
          <Grid item xs={4}>
            <Select
              displayEmpty
              fullWidth
              variant='outlined'
              value={getValue(option.key)}
              onChange={handleChange(option.key)}
            >
              <MenuItem value=''>-- {t("Question.SelectAnswer")} --</MenuItem>
              {lists.map((list,index) => (
                <MenuItem value={list} key={index}>
                  {list}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </MatchItem>
      ))}
    </Container>
  )
}
