import React, { createContext, useContext, useEffect, useState } from 'react'
import { KGContext } from '../KGProvider'
import update from 'react-addons-update'

const PageEditContext = createContext({})

const defaultEditData = {
  contents: []
}
const PageEditProvider = ({ children, defaultData, ...props }) => {
  const { t, addAlert, user } = useContext(KGContext)
  const [data, setData] = useState({ ...defaultEditData })
  const [state, setState] = useState({
    focus: 1,
    limit: 'lg',
    sorting: false,
  })

  const store = {
    ...props,
    datas: [data, setData],
    states: [state, setState],
    addAlert,
    update,
    user,
    t
  }

  useEffect(() => {
    if (defaultData) {
      setData({ ...defaultEditData, ...defaultData })
    }
  }, [defaultData])

  return (
    <PageEditContext.Provider value={store}>
      {children}
    </PageEditContext.Provider>
  )
}

export const connectPageEdit = (Comp) => (props) =>
  (
    <PageEditProvider {...props}>
      <Comp {...props} />
    </PageEditProvider>
  )

export const injectPageEdit = (Comp) => (props) => {
  const store = useContext(PageEditContext)
  return <Comp {...props} store={store} />
}
