import React, { useContext, createContext } from 'react'
import 'firebase/firestore';
import { KGContext } from '../KGProvider';

export const Context = createContext({})
export const ContextProvider = ({ children, ...props }) => {
  const { user, width, firebase } = useContext(KGContext);
  const [state, setState] = React.useState({
    tab: 0,
    docs: [],
    selected: [],
  });

  const store = {
    ...props,
    state: [state, setState],
    firebase,
    user,
    width,
  }

  return <Context.Provider value={store}>{children}</Context.Provider>
}
export const connect = (config) => (Comp) => (props) =>
  (
    <ContextProvider config={config} {...props}>
      <Comp {...props} />
    </ContextProvider>
  )
