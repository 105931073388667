import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import React from 'react'
import { Fragment } from 'react'
import { ImageDisplay } from '../ImageDisplay'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  }
}))

export const CardCourse = ({ docs, actions, ...props }) => {
  const classes = useStyles()
  return docs ? (
    <Container className={classes.cardGrid} maxWidth='md'>
      <Grid container spacing={4}>
        {docs.map((doc) => (
          <Grid item key={doc.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              {doc.cover ? (
                <ImageDisplay image={doc.cover} ratio={1 / 3} />
              ) : (
                <CardMedia
                  className={classes.cardMedia}
                  image='https://source.unsplash.com/random'
                  title='Image title'
                  style={{ paddingTop: 'calc(100% / 3)' }}
                />
              )}
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant='h5' component='h2'>
                  {doc.title}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {doc.desc}
                </Typography>
                <Typography variant='caption' color='textSecondary'>
                  {doc.teacher}
                </Typography>
              </CardContent>
              <CardActions>
                {actions ? actions : "No"}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  ) : (
    'No data'
  )
}
