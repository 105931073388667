import { createContext, useState, useEffect, useContext } from 'react'
import React from 'react'
import { KGContext } from '../KGProvider'
import axios from 'axios'
import { SkeletonController } from '../functions'

export const ContextViewCourse = createContext({})

const ProviderViewCourse = ({ children, selected = 'home', ...props }) => {
  const { user, firebase, ...store } = useContext(KGContext)
  const [st] = store.state
  const [state, setState] = useState({
    fetched: false,
    permission: true
  })
  const [data, setData] = useState({})
  const [student, setStudent] = useState(null)

  const stores = {
    ...props,
    selected,
    data: [data, setData],
    state: [state, setState],
    student
  }
  const studentDetail = async (id) => {
    if (id) {
      const userdetail = await firebase
        .firestore()
        .collection('students')
        .doc(id)
        .get()
      return userdetail.data()
    }
  }

  useEffect(() => {
    if (stores.id && st.userFetched) {
      ;(async () => {
        const control = new SkeletonController(firebase)
        let token = user ? await user.getIdToken() : null
        try {
          const snapshot = await axios.get(
            `https://pi-nest.mek.network/course/${stores.id}/${props.prefix}/${token}`
          )
          setState((s) => ({ ...s, fetched: true, permission: true }))
          const student = await studentDetail(user.uid)
          setStudent(student)
          setData(snapshot.data)
          // console.log(snapshot.data)
          document.title = `${snapshot.data.title} | ${props.sitename || ''}`
        } catch (error) {
          setState((s) => ({ ...s, fetched: true, permission: false }))
          setData({})
          document.title = `${props.sitename}`
        }
      })()
    }
  }, [stores.id, st.userFetched, user])

  useEffect(() => {
    if (props.data) {
      setData((s) => ({ ...s, ...props.data }))
      setState((s) => ({ ...s, fetched: true, permission: true }))
    }
  }, [props.data])

  return (
    <ContextViewCourse.Provider value={stores}>
      {children}
    </ContextViewCourse.Provider>
  )
}

export const connectViewCourse = (Comp) => (props) =>
  (
    <ProviderViewCourse {...props}>
      <Comp {...props} />
    </ProviderViewCourse>
  )
