import { Divider, Box } from '@material-ui/core'
import React from 'react'
import { PanelItem } from './PanelContainer'

export const ContentDivider = ({ content, index, ...props }) => {
  return (
    <PanelItem id={content.key} index={index}>
      <Box mt={6} />
      <Divider />
    </PanelItem>
  )
}
