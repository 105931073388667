import {
  faBook,
  faFileAlt,
  faHeading,
  faHorizontalRule,
  faImage,
  faImages,
  faLineHeight,
  faNewspaper,
  faParagraph,
  faPlay,
  faPlus,
  faTable,
  faVideo
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { forwardRef, Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DuotoneButton } from '../DuotoneButton'
import { ContentEditContext } from './context'
import { genKey } from 'draft-js'

const Transition = forwardRef((props, ref) => <Grow ref={ref} {...props} />)

export const panelsList = [
  { label: 'Heading', type: 'heading', icon: faHeading },
  { label: 'Paragraph', type: 'paragraph', icon: faParagraph },
  { label: 'Image', type: 'image', icon: faImage },
  { label: 'Cover', type: 'cover', icon: faImage },
  { label: 'Slide', type: 'slide', icon: faImages },
  { label: 'Highlight', type: 'highlight', icon: faNewspaper },
  { label: 'Card', type: 'card', icon: faTable },
  { label: 'VDO', type: 'vdo', icon: faVideo },
  { label: 'Book', type: 'book', icon: faBook },
  { label: 'Spacing', type: 'spacing', icon: faLineHeight },
  { label: 'Collection', type: 'collection', icon: faPlay },
  { label: 'Table', type: 'table', icon: faTable },
  { label: 'Divider', type: 'divider', icon: faHorizontalRule },
  { label: 'File', type: 'file', icon: faFileAlt }
]

const AddContentButton = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&>span': {
      display: 'block'
    },
    '& .fa-3x': {
      ...theme.palette.duotone,
      marginBottom: theme.spacing(1)
    }
  }
}))(({ children, icon, ...props }) => (
  <Box fullWidth component={Button} {...props}>
    {icon && <FontAwesomeIcon size='3x' icon={icon} />}
    <Typography variant='caption' color='textSecondary'>
      {children}
    </Typography>
  </Box>
))

export const AddPanel = ({ ...props }) => {
  const { t } = useTranslation()
  const store = useContext(ContentEditContext)
  const [, setData] = store.data
  const [open, setOpen] = useState(false)

  const handleOpen = (o) => () => setOpen(o)
  const handleAdd = (type) => () => {
    setData((s) => {
      let contents = [...(s.contents || [])]
      contents.push({
        type,
        key: genKey()
      })
      return { ...s, contents }
    })
  }

  return (
    <Fragment>
      <DuotoneButton
        icon={faPlus}
        children={t('Add')}
        onClick={handleOpen(!open)}
      />
      <Dialog
        fullWidth
        maxWidth='sm'
        open={open}
        onClose={handleOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle>{t('AddPanel')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {panelsList.map((item) => {
              if (store.isShow(item.type)) {
                return (
                  <Grid item xs={6} sm={4} key={item.type}>
                    <AddContentButton
                      icon={item.icon}
                      onClick={handleAdd(item.type)}
                    >
                      <Typography>{t(`ContentEdit.${item.label}`)}</Typography>
                    </AddContentButton>
                  </Grid>
                )
              }
              return null
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
