import { Container as OContainer, withStyles } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'

export const Container = withStyles((theme) => ({
  rootContainer: {
    padding: theme.spacing(0, 3)
  },
  in: {
    padding: 0
  }
}))(({ classes, ...props }) => (
  <div className={classes.rootContainer}>
    <OContainer className={classes.in} {...props} />
  </div>
))
Container.propTypes = {
  maxWidth: propTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
}
