import { Button, Typography, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { Context } from './context'
import { Blurhash } from 'react-blurhash'
import { isBlurhashValid } from 'blurhash'
import { Container } from '../Container'
import {
  faChevronCircleRight,
  faUser
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from '@material-ui/lab'

export const Cover = withStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    height: `calc(100vh - ${theme.shape.toolbarHeight || 0}px)`,
    overflow: 'hidden',
    zIndex: 1101,
  },
  blurhash: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    filter: `brightness(0.7)`,
    objectFit: 'cover'
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'white',
    textShadow: `0 0 ${theme.spacing(0.5)}px #0003, 0 0 ${theme.spacing(
      2
    )}px #0003`
  },
  separate: {
    marginBottom: theme.spacing(2)
  },
  read: {
    border: `solid 1px white`,
    borderRadius: 23,
    padding: theme.spacing(1, 3),
    color: 'white',
    backgroundColor: '#fff2',
    transition: 'all 0.25s',
    boxShadow: '0 0 0 #0004',
    '&:hover': {
      backgroundColor: '#fff2',
      boxShadow: '2px 2px 32px #0004, 0 0 2px #0008'
    }
  }
}))(({ classes, ...props }) => {
  const { data, width, t, ...store } = useContext(Context)
  const url =
    data &&
    data.cover &&
    (data.cover.large || data.cover.medium || data.cover.thumbnail)
  const isMobile = ['xs', 'sm', 'md'].includes(width)
  const [state, setState] = store.state

  const handleRead = () => setState((s) => ({ ...s, open: 1 }))

  return state.open === 'cover' ? (
    <div className={classes.root}>
      {data && data.cover && (
        <React.Fragment>
          {data.cover.blurhash && isBlurhashValid(data.cover.blurhash) && (
            <Blurhash
              className={classes.blurhash}
              hash={data.cover.blurhash}
              resolutionX={32}
              resolutionY={32}
            />
          )}
          {url && <img className={classes.image} src={url} alt='cover' />}
          <div className={classes.content}>
            <Container maxWidth='sm'>
              <Typography variant={isMobile ? 'h3' : 'h1'}>
                {data.title}
              </Typography>
              <div className={classes.separate} />
              <Typography variant='body2'>
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ marginRight: '0.5rem' }}
                />
                {state.usersFetched && state.users[data.user] ? (
                  state.users[data.user].displayName ||
                  state.users[data.user].email ||
                  data.user
                ) : (
                  <Skeleton
                    style={{
                      display: 'inline-block',
                      width: 128,
                      backgroundColor: '#fff8'
                    }}
                  />
                )}
              </Typography>
              <div className={classes.separate} />
              <Button
                className={classes.read}
                startIcon={<FontAwesomeIcon icon={faChevronCircleRight} />}
                onClick={handleRead}
              >
                <span className='text'>{t('Read')}</span>
              </Button>
            </Container>
          </div>
        </React.Fragment>
      )}
    </div>
  ) : null
})
