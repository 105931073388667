import { faFolderOpen, faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Context } from './index.context'
import axios from 'axios'
import { encode } from 'blurhash'
import { KGContext } from '../KGProvider'
import { DuotoneButton } from '../DuotoneButton'
import { useTranslation } from 'react-i18next'

const genKey = () => Math.floor(Math.random() * 1000000)

export const encodeBlurhash = (file) =>
  new Promise((res, rej) => {
    try {
      const url = URL.createObjectURL(file)
      const img = new Image()
      img.src = url
      img.onload = () => {
        const width = Math.floor(img.width / 10),
          height = Math.floor(img.height / 10)
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, width, height)
        const data = context.getImageData(0, 0, width, height)
        const hash = encode(data.data, width, height, 4, 4)
        res(hash)
      }
    } catch (err) {
      console.log(err.message)
      res(null)
    }
  })

const uploadURL = 'https://nas.phra.in/upload.php'
const Uploading = async (fileReceive, { user, callback, firebase }) => {
  let file;
  if(fileReceive.length){
    file = fileReceive[0]
  } else {
    file = fileReceive
  }
  const data = new FormData()
  data.append('upload', file)
  data.append('uid', user.uid)
  const config = {
    onUploadProgress: (progressEvent) => {
      let progress = (progressEvent.loaded / progressEvent.total) * 100
      progress = Math.floor(progress * 1)
      if (typeof callback === 'function') {
        if (progress < 100) {
          callback(progress, 'Uploading')
        } else {
          callback(0, 'Converting')
        }
      }
    }
  }
  const result = await axios.post(uploadURL, data, config)
  if (result.data.error) {
    return result.data
  } else {
    const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp
    const query = await firebase
      .firestore()
      .collection('documents')
      .where('user', '==', user.uid)
      .where('md5', '==', result.data.md5)
      .get()
    if (callback) {
      callback(0, 'Fetching')
    }
    if (query.docs.length) {
      await firebase
        .firestore()
        .collection('documents')
        .doc(query.docs[0].id)
        .update({ dateedit: dbTimestamp() })
      return query.docs[0].id
    } else {
      if (callback) {
        callback(0, 'Blurhash Encoding')
      }
      const blurhash = await encodeBlurhash(file)
      const { md5, ...content } = result.data
      const imageData = {
        content,
        md5,
        user: user.uid,
        dateedit: dbTimestamp(),
        visibility: 'private',
        type: 'image',
        parent: 0,
        blurhash
      }
      const addResult = await firebase
        .firestore()
        .collection('documents')
        .add({
          ...imageData,
          datecreate: dbTimestamp()
        })
      return addResult.id
    }
  }
}

const defaultUpload = {
  uploading: false,
  file: null,
  label: '',
  progress: 0
}
export const UploadButton = (props) => {
  const { t } = useTranslation()
  const { addAlert } = useContext(KGContext)
  const store = useContext(Context)
  const [upload, setUpload] = React.useState({ ...defaultUpload })

  const key = genKey()

  const handleUploadFiles = async ({ target }) => {
    if (target.files.length) {
      ;[...target.files].forEach(async (file) => {
        setUpload((s) => ({
          ...s,
          uploading: true,
          file,
          progress: 0
        }))
        const config = {
          user: store.user,
          callback: (progress, label) =>
            setUpload((s) => ({ ...s, label, progress })),
          firebase: store.firebase
        }
        const result = await Uploading(file, config)
        if (result.error) {
          console.log(result)
          addAlert({ label: result.message, severity: 'error' })
        }
        setUpload({ ...defaultUpload })
      })
    }
  }

  return (
    <React.Fragment>
      <input
        type='file'
        id={`upload-${key}`}
        hidden
        accept='image/*'
        onChange={handleUploadFiles}
      />
      <label htmlFor={`upload-${key}`}>
        <DuotoneButton
          component='span'
          variant='outlined'
          startIcon={
            <FontAwesomeIcon
              icon={upload.uploading ? faSpinner : faFolderOpen}
              pulse={upload.uploading}
            />
          }
          disabled={upload.uploading}
        >
          {upload.uploading
            ? `${upload.label} ${upload.progress}%`
            : t('Upload')}
        </DuotoneButton>
      </label>
    </React.Fragment>
  )
}
