export const locale_en = {
  Add: 'Add',
  AddPanel: 'Add Panel',
  Back: 'Back',
  Browse: 'Browse',
  Cancel: 'Cancel',
  Card: 'Card',
  Change: 'Change',
  ChangeLanguage: 'Change Language',
  Class: 'Class',
  Clear: 'Clear',
  Close: 'Close',
  Component: 'Component',
  Confirm: 'Confirm',
  ContentEdit: {
    Title: 'Title',
    Feature: 'Feature',
    Heading: 'Heading',
    Paragraph: 'Paragraph',
    Image: 'Image',
    Cover: 'Cover',
    Slide: 'Slide',
    Table: 'Table',
    File: 'File',
    Highlight: 'Highlight',
    Card: 'Card',
    VDO: 'VDO',
    Book: 'Book',
    Collection: 'Collection',
    AddSlide: 'Add Slide',
    AddHighlight: 'Add Highlight',
    Divider: 'Divider'
  },
  Course: 'Course',
  Courses: {
    Course: 'Course',
    AddQuestion: 'Add Question',
    Amount: 'Amount',
    Description: 'Description',
    DoYouWantToRemove: 'Do you want to remove "{{name}}"?',
    Lesson: 'Lesson',
    LessonContent: 'Lesson Content',
    ManageStudent: 'Manage Student',
    MyCourse: 'My Course',
    QuestionBank: 'Question Bank',
    Question: 'Question',
    QuestionTitle: 'Question Title',
    Quiz: 'Quiz',
    Section: 'Section',
    Score: 'Score',
    Teacher: 'Teacher'
  },
  Choose: 'Choose',
  Close: 'Close',
  Date: 'Date',
  Detail: 'Detail',
  DisplayName: 'Display Name',
  Download: "Download",
  Edit: 'Edit',
  Empty: 'Empty',
  False: 'False',
  FeatureImage: 'Feature Image',
  File: 'File',
  From: 'From',
  Grading: 'Grading',
  Header: 'Header',
  Highlight: 'Highlight',
  Image: 'Image',
  Language: 'Language',
  LastModified: 'Last Modified',
  Line: 'Line',
  Number: 'Number',
  Paragraph: 'Paragraph',
  PleaseWait: 'Please Wait',
  Private: 'Private',
  Public: 'Public',
  Question: {
    MultipleChoice: 'Multiple Choice',
    TrueFalse: 'True / False',
    Matching: 'Matching',
    Sorting: 'Sorting',
    QuestionType: 'Question Type',
    Question: 'Question',
    Choice: 'Choice',
    AddPanel: 'Add Panel',
    Answer: 'Answer',
    SelectRightAnswer: 'Select right answer',
    AddMatching: 'Add Matching',
    AddAnswer: 'Add Answer',
    AnswerText: 'Answer Text',
    Quiz: 'Quiz',
    SelectAnswer: 'Select Answer',
    Match: 'Match',
    SubQuestion: 'Sub Question',
    Sequence: 'Sequence',
    Dmultiple: 'Choose to best answer.',
    Dmatching: 'Match the answers to the questions.',
    Dsorting: 'Sorting the answer options.',
    Dtruefalse: 'Identify if the following true or false.'
  },
  Read: 'Read',
  Remove: 'Remove',
  RowPerPage: 'Row per page',
  Save: 'Save',
  Search: 'Search',
  Select: 'Select',
  Setting: 'Setting',
  SignOut: 'Sign Out',
  Student: 'Student',
  Subject: 'Subject',
  Teacher: 'Teacher',
  Title: 'Title',
  TitleEdit: {
    NoTitle: 'No Title',
    ChangeTitle: 'Change Title'
  },
  True: 'True',
  Type: 'Type',
  Untitled: 'Untitled',
  Update: 'Update',
  Upload: 'Upload',
  Users: {
    DisplayName: 'Display Name',
    ProfileImage: 'Profile Image',
    NameLast: 'Student Name',
    StudentNumber: 'Student No'
  },
  Value: 'Value',
  Video: 'Video',
  Visibility: 'Visibility'
}
