import React from 'react'
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-duotone-svg-icons'

const SortHandle = sortableHandle((props) => (
  <IconButton size='small'>
    <FontAwesomeIcon icon={props.icon || faBars} />
  </IconButton>
))

export const SortListItem = sortableElement(
  ({ children, listItemProps, icon, ...props }) => (
    <ListItem ContainerComponent='div' {...listItemProps}>
      <ListItemIcon>
        <SortHandle icon={icon} />
      </ListItemIcon>
      {children}
    </ListItem>
  )
)

const SortList = sortableContainer(({ children }) => {
  return <List disablePadding>{children}</List>
})

export const Sortable = ({ docs, onSortEnd, component, ...props }) => {
  return (
    <SortList onSortEnd={onSortEnd} useDragHandle>
      {props.divider && <Divider />}
      {docs.map((doc, index) => component(doc, index))}
    </SortList>
  )
}
