import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  withStyles
} from '@material-ui/core'
import React from 'react'
import { injectPageEdit } from '../../edit.context'
import update from 'react-addons-update'
import { Back } from './others'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight
} from '@fortawesome/pro-duotone-svg-icons'
import { GridSize } from './grid.size'

const Field = withStyles((theme) => ({
  inputxxx: ({ size = 'h6', textAlign = 'left' }) => {
    return {
      ...theme.typography[size],
      '& textarea': {
        textAlign
      }
    }
  }
}))(({ classes, size, textAlign, ...props }) => (
  <TextField InputProps={{ className: classes.inputxxx }} {...props} />
))

export const EditTypeHeader = injectPageEdit(
  ({
    content,
    index,
    store: {
      datas: [, setData]
    },
    ...props
  }) => {
    const handleChange = ({ target: { value } }) =>
      setData((d) =>
        update(d, { contents: { [index]: { value: { $set: value } } } })
      )
    return (
      <Box p={2}>
        <Field
          size={content.variant}
          textAlign={content.textAlign}
          multiline
          value={content.value || ''}
          onChange={handleChange}
        />
      </Box>
    )
  }
)

export const EditTypeHeaderSidebar = injectPageEdit(
  ({
    content,
    index,
    store: {
      t,
      datas: [, setData],
      states: [, setState]
    },
    ...props
  }) => {
    const handleChange = (key) => (data) => {
      const value =
        typeof data === 'object' && data.target ? data.target.value : data
      setData((d) =>
        update(d, { contents: { [index]: { [key]: { $set: value } } } })
      )
    }

    return (
      <List>
        <Back />
        <ListItem>
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='select-style-label'>Style</InputLabel>
            <Select
              labelWidth={40}
              value={content.variant || 'h6'}
              onChange={handleChange('variant')}
            >
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <MenuItem key={num} value={'h' + num}>
                  H{num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <ButtonGroup
            fullWidth
            size='large'
            variant='outlined'
            disableElevation
          >
            {((textAlign) => {
              return [
                { icon: faAlignLeft, value: 'left' },
                { icon: faAlignCenter, value: 'center' },
                { icon: faAlignRight, value: 'right' }
              ].map((item) => (
                <Button
                  key={item.value}
                  color={item.value === textAlign ? 'primary' : 'default'}
                  variant={item.value === textAlign ? 'contained' : 'outlined'}
                  onClick={() => handleChange('textAlign')(item.value)}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </Button>
              ))
            })(content.textAlign || 'left')}
          </ButtonGroup>
        </ListItem>
        <GridSize content={content} index={index} />
      </List>
    )
  }
)
