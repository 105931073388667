import {
  Box,
  Button,
  IconButton,
  List,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { useContext } from 'react'
import { QuizEditorContext } from '../context'
import { Panel } from '../panel'
import { SelectType } from '../select.type'
import arrayMove from 'array-move'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-duotone-svg-icons'

import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import { genKey } from 'draft-js'
import { useTranslation } from 'react-i18next'

const AnswerBox = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  }
}))(Box)

const SortHandle = sortableHandle((props) => (
  <IconButton size='small' style={{ cursor: 'move' }}>
    <FontAwesomeIcon icon={faBars} />
  </IconButton>
))

const SortListItem = sortableElement(({ children, ...props }) => (
  <AnswerBox>{children}</AnswerBox>
))

const SortList = sortableContainer(({ children }) => {
  return <List disablePadding>{children}</List>
})

export const OptionsSorting = (props) => {
  const { t } = useTranslation()
  const store = useContext(QuizEditorContext)
  const [data, setData] = store.data

  const handleTypeChange = (key) => (type) => {
    const index = data.options.findIndex((o) => o.key === key)
    setData((d) => {
      let options = [...(d.options || [])]
      options[index] = { ...options[index], type, value: null }
      return { ...d, options }
    })
  }
  const handleValueChange = (key) => (value) => {
    const index = data.options.findIndex((o) => o.key === key)
    setData((d) => {
      let options = [...(d.options || [])]
      options[index] = { ...options[index], value }
      return { ...d, options }
    })
  }
  const handleMove = ({ newIndex, oldIndex }) => {
    const answers = arrayMove(data.answers, oldIndex, newIndex)
    setData((d) => ({ ...d, answers }))
  }
  const handleRemove = (index) => () => {
    setData((d) => {
      const answers = d.answers.filter((s, i, a) => i !== index)
      return { ...d, answers }
    })
  }
  const handleAdd = () => {
    setData((d) => {
      let newData = { ...d }
      const key = genKey()
      newData.answers.push(key)
      newData.options.push({
        key,
        value: null,
        type: 'paragraph'
      })
      return newData
    })
  }

  return (
    <Panel id='answer' title={t('Question.Answer')}>
      <SortList onSortEnd={handleMove} useDragHandle>
        {Array.isArray(data.answers) &&
          data.answers.map((key, index) => {
            const ans = data.options.find((o) => o.key === key)
            return (
              ans && (
                <SortListItem index={index} key={key}>
                  <SelectType
                    type={ans.type}
                    value={ans.value}
                    onTypeChange={handleTypeChange(key)}
                    onValueChange={handleValueChange(key)}
                    actions={
                      <React.Fragment>
                        <SortHandle />
                        <Box mr={1} />
                        <Typography variant='body1'>
                          <strong>
                            {t('Question.Sequence') + ' ' + (index + 1)}
                          </strong>
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {Array.isArray(data.answers) && data.answers.length > 2 && (
                    <Box mt={1}>
                      <Button color='secondary' onClick={handleRemove(index)}>
                        {t('Remove')}
                      </Button>
                    </Box>
                  )}
                </SortListItem>
              )
            )
          })}
      </SortList>
      <Box textAlign='right' mt={2}>
        <Button color='primary' onClick={handleAdd}>
          {t('Question.AddAnswer')}
        </Button>
      </Box>
    </Panel>
  )
}
