import React, { useContext } from 'react'
import { Panel } from '../panel'
import { MenuItem, Select } from '@material-ui/core'
import { QuizEditorContext } from '../context'
import { useTranslation } from 'react-i18next'

export const OptionsTrueFalse = (props) => {
  const {t} = useTranslation()
  const store = useContext(QuizEditorContext)
  const [data, setData] = store.data

  const handleChange = ({ target }) =>
    setData((d) => ({ ...d, answers: target.value }))

  return (
    <Panel id='answer' title={t('Question.Answer')}>
      <Select
        fullWidth
        variant='outlined'
        value={Boolean(data.answers)}
        onChange={handleChange}
      >
        <MenuItem value={true}>{t('True')}</MenuItem>
        <MenuItem value={false}>{t('False')}</MenuItem>
      </Select>
    </Panel>
  )
}
