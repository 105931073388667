import { faSignOut } from '@fortawesome/pro-duotone-svg-icons'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  Typography
} from '@material-ui/core'
import React, { Fragment, useContext, useRef, useState } from 'react'
import { KGContext } from '../KGProvider'
import { Setting } from './Setting'

const avatarSize = 128
const useStyles = makeStyles((theme) => ({
  infobox: {
    minWidth: theme.shape.sidebarWidth,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: avatarSize,
    height: avatarSize,
    marginBottom: '0.5em'
  }
}))

export const ProfileSigned = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ref = useRef()
  const { user, firebase, ...store } = useContext(KGContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleSignOut = () => firebase.auth().signOut()
  const handleOpenMenu = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    user && (
      <Fragment>
        <IconButton ref={ref} edge='end' onClick={handleOpenMenu}>
          <Avatar src={user.photoURL} />
        </IconButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <Box className={classes.infobox}>
            <Avatar className={classes.avatar} src={user.photoURL} />
            <Typography variant='body1'>
              {user.displayName || user.email || user.uid}
            </Typography>
          </Box>
          <List>
            {store.profileSecondaryActions}
            <Setting />
            <ListItem button onClick={handleSignOut}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOut} />
              </ListItemIcon>
              <ListItemText primary={t('SignOut')} />
            </ListItem>
          </List>
        </Menu>
      </Fragment>
    )
  )
}
