import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'

export const TableGrid = (props) => {
  const [state, setState] = useState({
    loading: true,
    rows: [],
    columns: []
  })

  useEffect(() => {
    if (props.docs && !props.rows) {
      setState((s) => ({ ...s, ...convertConfig(props) }))
    } else {
      setState((s) => ({ ...s, ...props }))
    }
  }, [props])

  return (
    <DataGrid pageSize={10} autoHeight disableSelectionOnClick {...state} />
  )
}

const convertConfig = (config) => {
  const {
    fetched,
    columns: oldColumns,
    checkbox,
    search,
    docs,
    defaultChecked,
    defaultSort,
    defaultOrder,
    rowsPerPageOptions,
    ...others
  } = config
  const columns = oldColumns.map((col) => {
    const { label, id, width, sortable, value, align } = col
    return {
      field: id,
      headerName: label || ' ',
      width: width || 200,
      sortable: Boolean(sortable),
      renderCell: value || null,
      filterable: search?.includes(id),
      align: align || 'left'
    }
  })
  const rows = config.docs
  return {
    columns,
    rows,
    checkboxSelection: Boolean(checkbox),
    loading: !Boolean(fetched),
    selectionModel: defaultChecked || [],
    sortModel: [
      {
        field: defaultSort || 'edit',
        sort: defaultOrder || 'desc'
      }
    ],
    rowsPerPageOptions: rowsPerPageOptions || [5, 10, 25, 50, 100]
  }
}
