import { withStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

export const BarIcon = withStyles((theme) => ({
  root: {
    width: "1em",
    height: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    overflow: "hidden",
  },
  main: {
    backgroundColor: "var(--fa-primary-color, currentColor)",
    width: "100%",
    height: "0.2em",
    transition: "all 1s cubic-bezier(0,.88,.69,1.19)",
  },
  fade: {
    backgroundColor: "var(--fa-secondary-color, currentColor)",
    opacity: 0.5,
  },
  f: {
    transform: "translateY(0.41em) rotate(45deg)",
  },
  s: {
    transform: "translateX(-1em)",
    opacity: 0,
  },
  t: {
    transform: "translateY(-0.41em) rotate(-45deg)",
  },
}))(({ classes, open, onClick }) => {
  return (
    <div className={classes.root} onClick={onClick}>
      <div className={clsx(classes.main, { [classes.f]: open })}></div>
      <div
        className={clsx(classes.main, classes.fade, { [classes.s]: open })}
      ></div>
      <div className={clsx(classes.main, { [classes.t]: open })}></div>
    </div>
  );
});