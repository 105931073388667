import React, { useContext } from 'react'
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileAlt } from '@fortawesome/pro-duotone-svg-icons'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { KGContext } from '../KGProvider'

const ListItemBlock = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1)
  }
}))(ListItem)

export const FileDisplay = ({ content, ...props }) => {
  const { t } = useTranslation()
  const { Link } = useContext(KGContext)
  return (
    <React.Fragment>
      {' '}
      <ListItemBlock divider>
        <ListItemIcon>
          <FontAwesomeIcon icon={faFileAlt} size='2x' />
        </ListItemIcon>
        <ListItemText
          primary={content.value ? content.value.name : t('Untitled')}
          secondary={
            t('Size') +
            ` : ${
              content.value
                ? (content.value.size / (1024 * 1024)).toFixed(2) + ' MB'
                : t('UnknownSize')
            }`
          }
          secondaryTypographyProps={{ variant: 'caption' }}
        />
        <Box mt={2} />
        {content.value && (
          <IconButton
            size='small'
            color='primary'
            component={'a'}
            href={content.value.original}
            target='_blank'
          >
            <FontAwesomeIcon size='1x' icon={faDownload} />
          </IconButton>
        )}

        {/* <ActionIcon icon={faFileDownload} color="primary" disabled={content.value ? false : true} /> */}
      </ListItemBlock>
    </React.Fragment>
  )
}
