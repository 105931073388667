import React, { forwardRef, Fragment, useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import { Context } from './index.context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { DialogRemove } from '../DialogRemove'
import { KGContext } from '../KGProvider'
import { useTranslation } from 'react-i18next'

export const RemoveImage = (props) => {
  const {t} = useTranslation();
  const { addAlert, firebase } = useContext(KGContext)
  const store = useContext(Context)
  const [open, setOpen] = useState(false)
  const [state, setState] = store.state

  const handleOpen = (o) => () => setOpen(o)
  const handleConfirm = async () => {
    if(state.selected.length){
      const batch = firebase.firestore().batch();
      const ref = firebase.firestore().collection('documents');
      state.selected.forEach(image => {
        batch.delete(ref.doc(image.id));
      });
      await batch.commit();
      addAlert({label:'Remove success'});
    }
    setState(s=>({ ...s, selected:[] }));
    setOpen(false);
  }

  return (
    <Fragment>
      {Boolean(state.selected.length) && (
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<FontAwesomeIcon icon={faTrash} />}
          onClick={handleOpen(true)}
        >
          {t("Remove")}
        </Button>
      )}
      <DialogRemove open={open} onClose={handleOpen(false)} onConfirm={handleConfirm} />
    </Fragment>
  )
}