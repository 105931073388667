import React from 'react'
import { useContext } from 'react'
import { NotFound } from '../NotFound'
import { Context } from './context'
import { ViewPost } from '../ViewPost'

export const ContentBody = () => {
  const [state, setState] = useContext(Context).state
  const content =
    state.pages && state.pages[state.open - 1]
      ? state.posts[state.pages[state.open - 1]]
      : null

  return state.postFetched && state.usersFetched ? (
    content ? (
      <ViewPost
        fetched={true}
        data={content}
        userInfo={state.users[content.user]}
        hideCover
      />
    ) : (
      <NotFound />
    )
  ) : (
    <ViewPost fetched={false} />
  )
}
