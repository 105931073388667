import React, { useEffect } from 'react'
import {
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  withStyles,
  Checkbox,
  alpha,
  InputBase,
  Typography,
  Toolbar,
  Button
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { useTranslation } from 'react-i18next'
import { TableGrid } from './data.grid'

const Cell = withStyles((theme) => ({
  head: {
    backgroundColor: '#333',
    color: theme.palette.primary.contrastText + ' !important',
    textAlign: 'center'
  },
  root: {
    color: theme.palette.text.secondary
  }
}))(TableCell)

const HeadCheckbox = withStyles((theme) => ({
  root: {
    color: 'white',
    '&.Mui-checked': {
      color: 'white'
    }
  }
}))(Checkbox)

const SortLabel = withStyles((theme) => ({
  root: {
    color: 'white',
    '&>svg': {
      color: 'white !important'
    }
  }
}))(TableSortLabel)

const SearchBox = withStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.grey[300], 0.25),
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[300], 0.5)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    transitionDelay: '1s',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '9ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}))(({ classes, ...props }) => {
  const { t } = useTranslation()
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <InputBase
        placeholder={t('Search')}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
        {...props}
      />
    </div>
  )
})

export const TableDocs = withStyles((theme) => ({
  pagination: {
    color: theme.palette.text.secondary
  }
}))(({ classes: cs, ...props }) => {
  const {
    columns,
    checkbox,
    docs,
    fetched,
    defaultSort,
    defaultOrder,
    defaultChecked
  } = props
  const { t } = useTranslation()
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    sort: defaultSort || '',
    order: defaultOrder || 'asc',
    search: '',
    checked: []
  })

  const getWidth = (width) => {
    if (typeof width === 'number') {
      return `${width}%`
    } else if (typeof width === 'string') {
      return width.includes('%') ? width : `${width}%`
    }
    return null
  }
  const getSearch = () => {
    let newDocs = [...docs]
    if (props.search && state.search) {
      const queries = state.search.split(' ')
      if (typeof props.search === 'string') {
        queries.forEach((q) => {
          newDocs = newDocs.filter(
            (doc) =>
              doc[props.search] &&
              doc[props.search].toLowerCase().includes(q.toLowerCase())
          )
        })
      } else if (Array.isArray(props.search)) {
        queries.forEach((q) => {
          newDocs = newDocs.filter(
            (doc) =>
              props.search
                .map(
                  (s) =>
                    doc[s] &&
                    `${doc[s]}`.toLowerCase().includes(q.toLowerCase())
                )
                .filter((d) => d).length
          )
        })
      }
    }
    return newDocs
  }
  const getDocs = () => {
    let newDocs = getSearch()
    newDocs = newDocs.sort((a, b) => {
      if (props.onSort) {
        return props.onSort(a, b)
      } else {
        if (a[state.sort] && b[state.sort]) {
          const [aData, bData] =
            state.order === 'asc'
              ? [a[state.sort], b[state.sort]]
              : [b[state.sort], a[state.sort]]
          if (typeof aData === 'string') {
            return aData.localeCompare(bData)
          } else if (typeof aData === 'number') {
            return aData - bData
          }
        }
      }
      return false
    })

    const start = state.page * state.rowsPerPage
    return newDocs.slice(start, start + state.rowsPerPage)
  }

  const handleChangePage = (event, page) => setState((s) => ({ ...s, page }))
  const handleChangeRowsPerPage = (event) => {
    setState((s) => ({
      ...s,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    }))
  }
  const handleChangeSort = (id) => () =>
    setState((s) => {
      let { sort, order } = s
      if (id === sort) {
        order = order === 'asc' ? 'desc' : 'asc'
      } else {
        sort = id
        order = 'asc'
      }
      return { ...s, sort, order }
    })
  const handleChangeSearch = ({ target }) =>
    setState((s) => ({ ...s, search: target.value, page: 0, checked: [] }))
  const handleClearSearch = () =>
    setState((s) => ({ ...s, search: '', page: 0, checked: [] }))
  const handleChangeChecked = (id) => () =>
    setState((s) => {
      let checked = [...s.checked]
      if (checked.includes(id)) {
        checked = checked.filter((c) => c !== id)
      } else {
        checked.push(id)
      }
      if (props.onChangeChecked) {
        props.onChangeChecked(checked)
      }
      return { ...s, checked }
    })
  const handleChangeHeadChecked = () => {
    if (state.checked.length === 0) {
      const checked = getSearch().map((d) => d.id)
      setState((s) => ({ ...s, checked }))
    } else {
      setState((s) => ({ ...s, checked: [] }))
    }
  }
  const handleClearChecked = () => setState((s) => ({ ...s, checked: [] }))
  const handleCheckedActions = (func) => async () => {
    await func(state.checked)
    setState((s) => ({ ...s, checked: [] }))
  }

  const Pagination = (
    <TablePagination
      className={cs.pagination}
      labelRowsPerPage={t('RowPerPage')}
      rowsPerPageOptions={props.rowsPerPageOptions || [5, 10, 25]}
      count={getSearch().length || 1}
      rowsPerPage={state.rowsPerPage}
      page={state.page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      component='div'
    />
  )

  useEffect(() => {
    if (defaultChecked) {
      setState((s) => ({ ...s, checked: defaultChecked }))
    }
  }, [defaultChecked])

  return props.datagrid ? (
    <TableGrid {...props} />
  ) : (
    <React.Fragment>
      {Boolean(state.checked.length) ? (
        <Toolbar variant='dense'>
          <Typography variant='caption'>
            Select {state.checked.length} items
          </Typography>
          <Box flex={1} />
          {Array.isArray(props.checkedActions) &&
            props.checkedActions.map(({ label, onClick, ...props }, index) => (
              <Button
                size='small'
                onClick={handleCheckedActions(onClick)}
                {...props}
                key={index}
              >
                {label}
              </Button>
            ))}
          <Button size='small' onClick={handleClearChecked}>
            Clear
          </Button>
        </Toolbar>
      ) : (
        <Box display='flex' alignItems='center' mb={1}>
          <SearchBox value={state.search} onChange={handleChangeSearch} />
          {state.search && (
            <IconButton
              size='small'
              color='primary'
              style={{ marginLeft: 8 }}
              onClick={handleClearSearch}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          )}
          <Box flex={1} />
          {Pagination}
        </Box>
      )}
      <TableContainer>
        <Table size={props.size || 'small'}>
          <TableHead>
            <TableRow>
              {checkbox && (
                <Cell padding='checkbox'>
                  <HeadCheckbox
                    indeterminate={Boolean(
                      state.checked.length &&
                        state.checked.length !== getSearch().length
                    )}
                    checked={state.checked.length === getSearch().length}
                    onChange={handleChangeHeadChecked}
                  />
                </Cell>
              )}
              {Array.isArray(columns) &&
                columns.map((col) => (
                  <Cell key={col.id} width={getWidth(col.width)}>
                    {col.sortable ? (
                      <SortLabel
                        active={col.id === state.sort}
                        direction={state.order}
                        onClick={handleChangeSort(col.id)}
                        style={{ color: 'inherit' }}
                      >
                        {col.label}
                      </SortLabel>
                    ) : (
                      col.label
                    )}
                  </Cell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetched ? (
              Array.isArray(docs) && getDocs().length ? (
                getDocs().map((doc) => (
                  <TableRow
                    hover
                    key={props.rowId ? doc[props.rowId] : doc.key}
                  >
                    {checkbox && (
                      <Cell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={state.checked.includes(doc.id)}
                          onChange={handleChangeChecked(doc.id)}
                        />
                      </Cell>
                    )}
                    {Array.isArray(columns) &&
                      columns.map(
                        (
                          { id, width, style, value, sortable, ...props },
                          index
                        ) => (
                          <Cell
                            key={id}
                            width={getWidth(width)}
                            style={style}
                            {...props}
                          >
                            {(() => {
                              if (typeof value === 'function') {
                                return value(doc, index)
                              } else {
                                return doc[id]
                              }
                            })()}
                          </Cell>
                        )
                      )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  {checkbox && <Cell padding='checkbox' />}
                  <Cell colSpan={100}>
                    <Typography variant='caption' color='textSecondary'>
                      empty row
                    </Typography>
                  </Cell>
                </TableRow>
              )
            ) : (
              <TableRow>
                {checkbox && <Cell padding='checkbox' />}
                <Cell colSpan={100}>
                  <Skeleton width='25%' />
                </Cell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {Pagination}
    </React.Fragment>
  )
})
TableDocs.defaultProps = {
  fetched: false,
  docs: []
}
