import { List } from '@material-ui/core'
import React, { Fragment, useContext } from 'react'
import { ContentEditContext } from './context'

import { BackLink } from '../BackLink'
import { TitleEdit } from '../TitleEdit'
import { FeatureImageEdit } from '../FeatureImage'
import { SaveButton } from '../SaveButton'
import { KGContext } from '../KGProvider'

export const Sidebar = (props) => {
  const { addAlert } = useContext(KGContext)
  const { config, isShow, onTitleChange, ...store } = useContext(ContentEditContext)
  const [data, setData] = store.data

  const handleChange = (key) => (value) => {
    if(key==="title" && typeof onTitleChange === "function"){
      onTitleChange(value);
    }
    setData((s) => ({ ...s, [key]: value }))
  }
  const handleSave = async () => {
    if (store.onSave) {
      const result = await store.onSave(data)
      if(result!==false){
        addAlert({ label: 'Save Success' })
      }
    }
  }

  return (
    <Fragment>
      <List disablePadding>
        <BackLink to={config.back} />
        <SaveButton onSave={handleSave} />
        {isShow('title') && (
          <TitleEdit value={data.title} onChange={handleChange('title')} />
        )}
        {isShow('feature') && (
          <FeatureImageEdit
            value={data.cover}
            onChange={handleChange('cover')}
          />
        )}
      </List>
      {store.sidebarSecondaryActions}
    </Fragment>
  )
}
