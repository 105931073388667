import React, { useContext } from 'react'
import { Container } from '../Container'
import { ContentEditContext } from './context'
import { PanelItem } from './PanelContainer'

import { Heading } from '../Heading'

export const ContentHeading = ({ content, index, ...props }) => {
  const { updateContent, ...store } = useContext(ContentEditContext)
  const [, setData] = store.data

  const handleChange = (n) => {
    setData((s) => {
      let contents = [...s.contents]
      contents[index] = { ...contents[index], ...n }
      return { ...s, contents }
    })
  }

  return (
    <PanelItem id={content.key} index={index}>
      <Heading editable {...content} onChange={handleChange} />
    </PanelItem>
  )
}
