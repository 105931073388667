import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core'
import { faCamera, faGlobe } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KGContext } from '../../KGProvider'

const types = {
  photoby: faCamera,
  url: faGlobe
}

export const ImageCredit = withStyles((theme) => ({
  root: Object.assign(
    {},
    {
      position: 'absolute',
      top: 0,
      left: 0,
      color: 'white',
      padding: theme.spacing(0, 0.5),
      backgroundColor: 'rgba(0,0,0,0.25)',
    },
    theme.typography.caption
  )
}))(({ classes, label, link, type }) => {
  const { Link } = useContext(KGContext)
  return (
    <Link to={link} className={classes.root} target='_blank'>
      <FontAwesomeIcon icon={types[type]} style={{ marginRight: '0.25rem' }} />
      {label}
    </Link>
  )
})
