import React, { useContext } from 'react'
import { MainContainer } from '../MainContainer'
import propTypes from 'prop-types'
import { connectChat, ContextChat } from './ctx'
import { ChatSidebar } from './sidebar'
import { ChatContent } from './content'

export const Chat = connectChat((props) => {
  return (
    <MainContainer dense signInOnly sidebar={<ChatSidebar />}>
      <ChatContent />
    </MainContainer>
  )
})
Chat.propTypes = {
  as: propTypes.string.isRequired,
  back: propTypes.string,
  baseURL: propTypes.string.isRequired,
  contacts: propTypes.arrayOf(
    propTypes.shape({
      uid: propTypes.string.isRequired,
      displayName: propTypes.string,
      unread: propTypes.number
    })
  ).isRequired,
  sidebar: propTypes.any,
  view: propTypes.string,
}
