import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, withStyles } from '@material-ui/core'
import {grey} from '@material-ui/core/colors'
import React from 'react'

export const DuotoneButton = withStyles((theme) => ({
  root: {
    ...theme.palette.duotone,
    "--fa-secondary-color": grey[600]
  }
}))(({ icon, ...props }) => (
  <Button
    variant='outlined'
    startIcon={icon && <FontAwesomeIcon icon={icon} />}
    {...props}
  />
))
