import React, { useContext } from 'react'
import {
  BubbleContainer,
  BubbleDate,
  BubbleItem,
  BubbleText,
  BubbleFile,
  BubbleTime  
} from './bubble'
import { ContextChat } from './ctx'
import { ChatInputMsgBox } from './input.msg.box'

export const ChatContent = (props) => {
  const {
    datas: [data],
    control
  } = useContext(ContextChat)

  return (
    <React.Fragment>
      <BubbleContainer>
        {Boolean(data.fs && data.fr) &&
          control
            .getMessages(data?.sender?.contents, data?.receiver?.contents)
            .map((msg, index, msgs) => (
              <React.Fragment key={msg?.date}>
                <BubbleItem isMy={msg?.isMy}>
                  {
                    {
                      "text": <BubbleText msg={msg} />,
                      "file": <BubbleFile msg={msg} />,
                    }[msg.type]
                  }
                  <BubbleTime date={msg?.date} />
                </BubbleItem>
                <BubbleDate
                  current={msg?.date}
                  after={msgs?.[index + 1]?.date}
                />
              </React.Fragment>
            ))}
      </BubbleContainer>
      <ChatInputMsgBox />
    </React.Fragment>
  )
}
