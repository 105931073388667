import { SkeletonController } from '../functions'

export class ChatController extends SkeletonController {
  watch = (as, view, callback) => {
    if (!Boolean(as && view && callback)) throw 'Parameter not found'

    const unwatchSender = this.path('messages', `${as}-${view}`).onSnapshot(
      (snap) => {
        callback({ sender: snap.data(), fs: true })
      }
    )

    const unwatchReceiver = this.path('messages', `${view}-${as}`).onSnapshot(
      (snap) => {
        callback({ receiver: snap.data(), fr: true })
      }
    )

    return () => {
      unwatchSender()
      unwatchReceiver()
    }
  }
  getMessages = (sMessages, rMessages) => {
    const messages = [
      ...(sMessages?.map((msg) => ({ ...msg, isMy: true })) ?? []),
      ...(rMessages?.map((msg) => ({ ...msg, isMy: false })) ?? [])
    ]
      .map((msg) => ({ ...msg, date: msg?.date?.toMillis() || Date.now() }))
      .sort((a, b) => b?.date - a?.date)
    return messages
  }
  send = async (as, view, data) => {
    const ref = this.path('messages', `${as}-${view}`)
    const setData = {
      contents: [data],
      latest: this.timestamp(),
      to: view,
      user: this?.currentUser()?.uid
    }
    const updateData = {
      contents: this.arrayUnion(data),
      latest: this.timestamp()
    }
    return await this.setupdate(ref, setData, updateData)
  }
  clearUnread = async (as, view, unread) => {
    if(!Boolean(as && view && unread)) throw 'Missing Parameter';
    if(unread>0){
      return this.path('messages',`${view}-${as}`).update({ unread:0 })
    }
    return false;
  }
}
