import React, { createContext, useEffect, useState, useContext } from 'react'
import { KGContext } from '../KGProvider'

export const ContentEditContext = createContext({})

const defaultConfig = {
  prefix: '',
  back: '/',
  show: ['title', 'feature', 'heading', 'paragraph', 'image']
}
const ContentEditProvider = ({ children, ...props }) => {
  const { firebase, user, width } = useContext(KGContext)
  const [data, setData] = useState({})
  const [config, setConfig] = useState({ ...defaultConfig })
  const [state, setState] = useState({
    quizFetched: false,
    docsFetched: false,
    cats: [],
    docs: [],
    anchor: null,
    anchorIndex: null,
  })

  const isShow = (key) => config.show && Boolean(config.show.includes(key))
  const updateContent = (index, key, value) =>
    setData((s) => {
      let contents = [...(s.contents || [])]
      contents[index][key] = value
      return { ...s, contents }
    })

  const store = {
    ...props,
    config,
    data: [data, setData],
    isShow,
    updateContent,
    state: [state, setState],
    width
  }

  useEffect(() => {
    if (firebase && user) {
      firebase
        .firestore()
        .collection('documents')
        .where('user', '==', user.uid)
        .where('type', 'in', ['post', 'book'])
        .get()
        .then((snapshot) => {
          const docs = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          })).sort((a,b)=>{
            const getLabel = d => d.label || d.title || "";
            return (getLabel(a)).localeCompare(getLabel(b),'th');
          })
          setState((s) => ({ ...s, docs, docsFetched: true }))
        })
    }
  }, [firebase, user])

  useEffect(() => {
    if (props.data) {
      setData((s) => ({ ...s, ...props.data }))
    }
  }, [props.data])

  useEffect(() => {
    if (props.config) {
      setConfig({ ...defaultConfig, ...props.config })
    }
  }, [props.config])

  return (
    <ContentEditContext.Provider value={store}>
      {children}
    </ContentEditContext.Provider>
  )
}

export const connectContentEdit = (Comp) => (props) =>
  (
    <ContentEditProvider {...props}>
      <Comp {...props} />
    </ContentEditProvider>
  )
