import { Box, Toolbar, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/pro-duotone-svg-icons'

export const AccessDenied = withStyles(theme=>({
  root: {
    ...(theme.palette.duotone || {}),
  },
  icon: {
    marginBottom: theme.spacing(2)
  }
}))(({classes:cs,...props}) => {
  return <Box className={cs.root} textAlign="center" py={6}>
    <Toolbar />
    <FontAwesomeIcon className={cs.icon} size="8x" icon={faBan} />
    <Typography variant="h6">Access Denied</Typography>
  </Box>
})