import React from 'react'
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import { usePostEdit } from "../context"
import { KuiActionIcon } from '../../Theme'

export const PEMap = props => {
  const {t,datas:[data]} =  usePostEdit()

  return <List disablePadding>
    <ListItem>
      <ListItemText secondary={t('Map')} />
      <ListItemSecondaryAction>
        <KuiActionIcon tx="add" />
      </ListItemSecondaryAction>
    </ListItem>
    {
      Array.isArray(data.maps) && data.maps.length
        ? null
        : <ListItem>
          <ListItemText secondary="no map" />
        </ListItem>
    }
    <Divider />
  </List>
}