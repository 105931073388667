import React from 'react'
import { Button, IconButton, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'

export const SaveImageButton = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: 'rgba(255,255,255,0.5)',
    border: `solid 1px rgba(255,255,255,0.5)`,
    backgroundColor: 'rgba(255,255,255,0.1)',
    opacity: 0.5,
    transition: 'opacity 0.25s',
    '&:hover': {
      opacity: 1
    }
  }
}))(({ classes: cs, ...props }) => {
  return (
    <IconButton size="small" className={cs.root} {...props}>
      <FontAwesomeIcon size="xs" icon={faSave} />
    </IconButton>
  )
})
