import React, { Fragment, useContext } from 'react'
import { Box, Button, MenuItem, Select, Typography } from '@material-ui/core'
import { QuizEditorContext } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons'

import { Paragraph } from '../Paragraph'
import { ImageDisplay } from '../ImageDisplay'
import { ImagePicker } from '../ImagePicker'
import { useTranslation } from 'react-i18next'

export const SelectType = ({
  type,
  value,
  onTypeChange,
  onValueChange,
  ...props
}) => {
  const { t } = useTranslation()
  const store = useContext(QuizEditorContext)
  const [data] = store.data

  const handleChangeType = ({ target }) =>
    onTypeChange && onTypeChange(target.value)
  const handleChangeImage = ([img]) => {
    if (onValueChange) {
      const { content, id } = img
      onValueChange({ ...content, id })
    }
  }
  const handleChangeParagraph = (e) => onValueChange && onValueChange(e)

  return (
    <Fragment>
      <Box display='flex' justifyContent='flex-end' alignItems='center' mb={2}>
        {props.actions}
        <Box flex={1} />
        <Typography variant='caption' color='textSecondary'>
          {t('Type')}:&nbsp;
        </Typography>
        <Select
          value={['paragraph', 'image'].includes(type) ? type : 'paragraph'}
          onChange={handleChangeType}
        >
          <MenuItem value='paragraph'>{t('Paragraph')}</MenuItem>
          <MenuItem value='image'>{t('Image')}</MenuItem>
        </Select>
      </Box>
      {(() => {
        switch (type) {
          case 'image':
            return (
              <Box textAlign='center'>
                {value && (
                  <Box mb={2}>
                    <ImageDisplay image={value} />
                  </Box>
                )}
                <ImagePicker onConfirm={handleChangeImage}>
                  <Button
                    variant='outlined'
                    startIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                  >
                    Browse
                  </Button>
                </ImagePicker>
              </Box>
            )
          default:
            return (
              <Paragraph
                editOnly
                value={value}
                onChange={handleChangeParagraph}
              />
            )
        }
      })()}
    </Fragment>
  )
}
