import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Paper, TextField } from '@material-ui/core'
import { Container } from '../../Container'
import { merge } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'
import { CETableContext } from './context'
import { genKey } from 'draft-js'

const defaultData = {
  columns: 2,
  rows: 2
}

export const NewTable = (props) => {
  const { t, index, updateContent, ...store } = useContext(CETableContext)
  const ref = useRef({})
  const [data, setData] = useState({ ...defaultData })

  const genProps = (key) => ({
    inputRef: (r) => (ref.current[key] = r),
    type: 'number',
    value: data[key],
    onChange: handleChange(key),
    style: {
      marginBottom: '1rem'
    }
  })
  const handleChange =
    (field) =>
    ({ target }) =>
      setData((d) => ({ ...d, [field]: parseInt(target.value) }))
  const handleSave = () => {
    if (data.columns < 2) {
      ref.current.columns.focus()
      return false
    }
    if (data.rows < 2) {
      ref.current.rows.focus()
      return false
    }

    const createArray = (num) =>
      Array.from({ length: parseInt(num) }, (_, i) => ({ key: genKey() }))

    const values = {
      columns: createArray(data.columns),
      rows: createArray(data.rows)
    }
    updateContent(index, 'columns', values.columns)
    updateContent(index, 'rows', values.rows)
    updateContent(index, 'values', {})
  }

  useEffect(() => {
    setData({ ...defaultData })
  }, [])

  return (
    <Paper>
      <Box p={2}>
        <TextField label='Column' {...genProps('columns')} />
        <TextField label='Row' {...genProps('rows')} />
        <Button
          size='large'
          variant='contained'
          fullWidth
          color='primary'
          startIcon={<FontAwesomeIcon icon={faSave} />}
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      </Box>
    </Paper>
  )
}
