import { Box } from '@material-ui/core'
import React, { useContext } from 'react'
import { connectContentEdit, ContentEditContext } from './context'
import { Sidebar } from './sidebar'

import { MainContainer } from '../MainContainer'
import { Container } from '../Container'
import { ContentHeader } from '../ContentHeader'
import { FeatureImage } from '../FeatureImage'
import { AddPanel } from './add'
import { PanelContainer, PanelItem } from './PanelContainer'
import arrayMove from 'array-move'

import { ContentHeading } from './content.heading'
import { ContentParagraph } from './content.paragraph'
import { ContentImage } from './content.image'
import { ContentVideo } from './video'
import { ContentQuiz } from './quiz'
import { ContentCover } from './cover'
import { ContentSlide } from './slide'
import { ContentTable } from './table'
import { ContentFile } from './file'
import { ContentHightlight } from './highlight'
import { ContentDivider } from './content.divider'

import propTypes from 'prop-types'

const Comp = {
  heading: ContentHeading,
  paragraph: ContentParagraph,
  image: ContentImage,
  vdo: ContentVideo,
  quiz: ContentQuiz,
  cover: ContentCover,
  slide: ContentSlide,
  table: ContentTable,
  file: ContentFile,
  highlight: ContentHightlight,
  divider: ContentDivider
}

export const ContentEdit = connectContentEdit((props) => {
  const store = useContext(ContentEditContext)
  const [data, setData] = store.data

  const handleSortEnd = ({ newIndex, oldIndex }) =>
    setData((s) => {
      let contents = arrayMove(s.contents || [], oldIndex, newIndex)
      return { ...s, contents }
    })

  return (
    <MainContainer dense sidebar={<Sidebar />}>
      <div style={{ maxWidth: 1366, margin: '0 auto' }}>
        {data.cover && <FeatureImage image={data.cover} />}
        <Box py={6}>
          <Container maxWidth='sm'>
            <ContentHeader label={data.title} actions={<AddPanel />} />
          </Container>
          <PanelContainer onSortEnd={handleSortEnd} useDragHandle>
            {Array.isArray(data.contents) &&
              data.contents.map((content, index) => {
                const Panel = Comp[content.type]
                if (Panel) {
                  return (
                    <Panel
                      id={content.key}
                      index={index}
                      content={content}
                      key={content.key}
                    />
                  )
                } else {
                  return process.env.NODE_ENV === 'development' ? (
                    <PanelItem id={content.key} index={index} key={content.key}>
                      {JSON.stringify(content)}
                    </PanelItem>
                  ) : null
                }
              })}
          </PanelContainer>
        </Box>
      </div>
    </MainContainer>
  )
})

ContentEdit.propTypes = {
  config: propTypes.shape({
    prefix: propTypes.string.isRequired,
    show: propTypes.arrayOf(
      propTypes.oneOf([
        'title',
        'feature',
        'heading',
        'paragraph',
        'vdo',
        'quiz',
        'highlight',
        'image',
        'cover',
        'slide',
        'book',
        'collection',
        'card',
        'table',
        'file',
        'divider'
      ])
    ),
    back: propTypes.string.isRequired,
  }).isRequired,
  data: propTypes.object,
  onSave: propTypes.func.isRequired,
  sidebarSecondaryActions: propTypes.node,
}
