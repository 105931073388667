import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import React, { forwardRef, Fragment, useState } from 'react'

export const Heading = forwardRef(({ editable, onChange, ...props }, ref) => {
  const [edit, setEdit] = useState(false)

  const handleEdit = () => {
    if (editable) {
      setEdit(true)
    }
  }
  const handleClose = () => setEdit(false)
  const handleChange = (key) => (e) => {
    if (onChange) {
      let content = { ...props }
      content[key] = typeof e === 'object' && e.target ? e.target.value : e
      onChange(content)
    }
  }

  return (
    <Fragment>
      {edit && (
        <Box mb={2}>
          <Box display='flex' alignItems='center' mb={2} py={1}>
            <FormControl>
              <InputLabel id='variant-select-label'>Size</InputLabel>
              <Select
                labelId='variant-select-label'
                id='variant-select'
                value={props.variant || 'h5'}
                onChange={handleChange('variant')}
              >
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <MenuItem key={num} value={`h${num}`}>
                    H{num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mr={2} />
            <FormControl>
              <InputLabel id='align-select-label'>Align</InputLabel>
              <Select
                labelId='align-select-label'
                id='align-select'
                value={props.align || 'left'}
                onChange={handleChange('align')}
              >
                <MenuItem value={'left'}>Left</MenuItem>
                <MenuItem value={'center'}>Center</MenuItem>
                <MenuItem value={'right'}>Right</MenuItem>
              </Select>
            </FormControl>
            <Box flex={1} />
          </Box>
          <TextField
            ref={ref}
            autoFocus
            fullWidth
            label='Heading'
            variant='outlined'
            value={props.value || ''}
            onChange={handleChange('value')}
          />
        </Box>
      )}
      <Box textAlign={props.align || 'left'}>
        <Typography
          variant={props.variant || 'h5'}
          color={props.value ? 'textPrimary' : 'textSecondary'}
          onClick={handleEdit}
        >
          {props.value || '[Heading]'}
        </Typography>
      </Box>
      {edit && (
        <Box textAlign='right' mt={1} pb={1}>
          <Button variant='outlined' size='small' onClick={handleClose}>
            Close
          </Button>
        </Box>
      )}
    </Fragment>
  )
})
