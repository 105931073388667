import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  // Tab,
  // Tabs
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FromUrl } from './from.url'
// import { AppBarTabs } from './index.appbar'
import { Context, connect } from './index.context'
import { RemoveImage } from './index.remove'
import { UploadButton } from './index.upload.button'
import { TabBrowse } from './tab.browse'
import { TabPublic } from './tab.public'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
))

export const ImagePicker = connect()((props) => {
  const { t } = useTranslation()
  const store = React.useContext(Context)
  const [state, setState] = store.state
  const [open, setOpen] = React.useState(false)

  const handleOpen = (o) => () => setOpen(o)
  const handleChangeTab = (e, n) => setState((s) => ({ ...s, tab: n }))
  const handleConfirm = () => {
    store.onConfirm(state.selected)
    handleOpen(false)()
  }

  return (
    <React.Fragment>
      {props.children &&
        React.cloneElement(props.children, {
          onClick: handleOpen(!open)
        })}
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        TransitionComponent={Transition}
        onClose={handleOpen(false)}
      >
        <DialogTitle>Image Picker</DialogTitle>
        {/* <AppBarTabs>
          <Tabs value={state.tab} onChange={handleChangeTab}>
            <Tab label={t('Browse')} />
            <Tab label={t('Public')} />
          </Tabs>
        </AppBarTabs> */}
        <DialogContent dividers>
          <div id='test' />
          {/* {state.tab === 0 && <TabBrowse />}
          {state.tab === 1 && <TabPublic />} */}
          <TabBrowse />
        </DialogContent>
        <DialogActions
          style={{
            flexDirection: ['xs', 'sm'].includes(store.width)
              ? 'column'
              : 'row',
            alignItems: ['xs', 'sm'].includes(store.width) ? 'flex-end' : null
          }}
        >
          <UploadButton />
          <FromUrl />
          <RemoveImage />
          <Box flex={1} />
          {typeof store.onConfirm === 'function' && (
            <Button
              disabled={!Boolean(state.selected.length)}
              color='primary'
              onClick={handleConfirm}
            >
              {t('Confirm')}
            </Button>
          )}
          <Button onClick={handleOpen(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
})
