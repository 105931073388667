import {
  faFileAlt,
  faHeading,
  faImage,
  faNewspaper,
  faParagraph,
  faTable,
  faVideo
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, MenuItem, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import React from 'react'
import { injectPageEdit } from './edit.context'

const lists = [
  { label: 'Header', id: 'header', icon: faHeading },
  { label: 'Paragraph', id: 'paragraph', icon: faParagraph },
  { label: 'Image', id: 'image', icon: faImage },
  { label: 'Highlight', id: 'highlight', icon: faNewspaper },
  { label: 'Card', id: 'card', icon: faTable },
  { label: 'Video', id: 'video', icon: faVideo },
  { label: 'File', id: 'file', icon: faFileAlt }
]

const DuotoneIcon = withStyles((theme) => ({
  root: {
    ...theme.palette.duotone
  }
}))(({ classes, ...props }) => (
  <FontAwesomeIcon className={classes.root} {...props} />
))

export const PageEditAddMenu = injectPageEdit(
  ({ store: { t }, anchor, onClose, onAdd }) => {
    const handleAdd = (type) => () => onAdd(type) || onClose()

    return (
      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={onClose}>
        {lists.map((item) => (
          <MenuItem key={item.id} onClick={handleAdd(item.id)}>
            <Typography variant='body2'>
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  width: 18,
                  marginRight: '1em'
                }}
              >
                <DuotoneIcon icon={item.icon} />
              </span>
              {t(item.label)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    )
  }
)
PageEditAddMenu.propTypes = {
  anchor: propTypes.any,
  onClose: propTypes.func.isRequired,
  onAdd: propTypes.func.isRequired
}
