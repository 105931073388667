import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

export const ImageDisplay = withStyles((theme) => ({
  rootImageDisplay: {
    position: 'relative',
    backgroundImage: ({ image }) =>
      image && image.url ? 'none' : theme.palette.background.gradient,
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: ({ ratio }) => (ratio ? `calc(100% * ${ratio})` : '100%')
    },
    '&>img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: ({ pos }) => {
        const getPer = (v) => (typeof v === 'number' ? `${v}%` : v)
        return pos && (pos.left > -1 || pos.top > -1)
          ? `${getPer(pos.left)} ${getPer(pos.top)}`
          : '50% 50%'
      }
    }
  },
  normal: {
    width: '100%',
    "&>img": {
      maxWidth: '100%',
    }
  },
}))(({ classes, image, pos, ratio, imgProps, ...props }) => {
  const [srcset, setSrcset] = useState('')

  useEffect(() => {
    if (typeof image === 'object') {
      let srcset = []
      if (image.url) {
        srcset.push(`${image.url} 1280w`)
      }
      if (image.medium) {
        srcset.push(`${image.medium} 960w`)
      }
      if (image.thumbnail) {
        srcset.push(`${image.thumbnail} 704w`)
      }
      setSrcset(srcset.join(', '))
    }
  }, [image])

  if (ratio) {
    return (
      <div className={classes.rootImageDisplay} {...props}>
        {Boolean(image && image.thumbnail) && (
          <img src={image.thumbnail} srcSet={srcset} {...imgProps} />
        )}
      </div>
    )
  } else {
    return (
      <div className={classes.normal} {...props}>
        {Boolean(image && image.thumbnail) && (
          <img src={image.thumbnail} srcSet={srcset} {...imgProps} />
        )}
      </div>
    )
  }
})
