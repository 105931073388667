import { Box, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { Container } from '../Container'
import { ContentHeader } from '../ContentHeader'
import { FeatureImage } from '../FeatureImage'
import { KGContext } from '../KGProvider'
import { DateToStr } from '../functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEdit } from '@fortawesome/pro-duotone-svg-icons'
import { Paragraph } from '../Paragraph'
import { ImageDisplay } from '../ImageDisplay'
import { Skeleton } from '@material-ui/lab'
import propTypes from 'prop-types'

const PaddingBox = ({ content, children }) => {
  const top =
    typeof content.top === 'number' && content.top > -1 ? content.top : 0
  const bottom =
    typeof content.bottom === 'number' && content.bottom > -1
      ? content.bottom
      : 2
  return (
    <Box pt={top} pb={bottom}>
      {children}
    </Box>
  )
}

export const ViewPost = ({ data, fetched, userInfo, ...props }) => {
  const { t } = useContext(KGContext)

  if (Boolean(fetched) === false) {
    return (
      <Box p={6}>
        <Container maxWidth='sm'>
          <ContentHeader
            label={<Skeleton width='50%' />}
            secondary={<Skeleton width='35%' />}
          />
          <Skeleton width='100%' />
          <Skeleton width='100%' />
          <Skeleton width='75%' />
        </Container>
      </Box>
    )
  }

  return data ? (
    <React.Fragment>
      {data.cover && !Boolean(props.hideCover) && <FeatureImage image={data.cover} />}
      <Box py={6}>
        <Container maxWidth='sm'>
          <ContentHeader
            label={data.title}
            secondary={
              <span>
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{ marginRight: '0.5em' }}
                />
                {DateToStr(data, 'L LT')}
                <span style={{ margin: '0 0.5rem' }}>|</span>
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ marginRight: '0.5em' }}
                />
                {userInfo
                  ? userInfo.displayName || userInfo.email || userInfo.uid
                  : data.user}
              </span>
            }
            breadcrumbs={props.breadcrumbs}
          />
          {Array.isArray(data.contents) &&
            data.contents.map((content) => {
              switch (content.type) {
                case 'heading':
                  return (
                    <PaddingBox content={content} key={content.key}>
                      <Typography variant={content.variant || 'h6'}>
                        {content.value}
                      </Typography>
                    </PaddingBox>
                  )
                case 'paragraph':
                  return (
                    <PaddingBox content={content} key={content.key}>
                      <Paragraph value={content.value} />
                    </PaddingBox>
                  )
                case 'image':
                  return (
                    <PaddingBox content={content} key={content.key}>
                      <ImageDisplay image={content.value} />
                    </PaddingBox>
                  )
                default:
                  if (process.env.NODE_ENV === 'development') {
                    console.log(content)
                    return content.type
                  } else {
                    return null
                  }
              }
            })}
        </Container>
      </Box>
    </React.Fragment>
  ) : null
}
ViewPost.propTypes = {
  hideCover: propTypes.bool,
}