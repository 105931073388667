import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import { KGContext } from '../KGProvider'

export const ForgetPassword = withStyles((theme) => ({
  typo: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))(({ classes, ...props }) => {
  const { firebase, addAlert } = useContext(KGContext)
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(false)

  const handleOpen = (o) => () => setOpen(o)
  const handleSend = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        addAlert({ label: 'Send reset password link to your e-mail success.' })
        setOpen(false)
      })
      .catch((err) => {
        addAlert({ label: err.message, severity: 'error' })
        setOpen(false)
      })
  }

  return (
    <React.Fragment>
      <Typography
        className={classes.typo}
        variant='caption'
        onClick={handleOpen(true)}
      >
        Forget Password?
      </Typography>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={Boolean(open)}
        onClose={handleOpen(false)}
      >
        <DialogTitle>Forget Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            System will send reset password link to your E-mail
          </DialogContentText>
          <Box mb={1} />
          <TextField
            label='E-mail'
            type='email'
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!Boolean(email)}
            color='primary'
            onClick={handleSend}
          >
            Send
          </Button>
          <Button onClick={handleOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
})
