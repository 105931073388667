import { Box, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { KGContext } from '../KGProvider'

export const LogoLink = withStyles(theme=>({
  root: {
    height: 48,
    "&>img": {
      height: '100%',
      width: 'auto',
    }
  },
}))(({ classes, ...props }) => {
  const { logo, Link } = useContext(KGContext)

  return (
    Boolean(logo && Link) &&  (
      <Box className={classes.root} component={Link} to="/">
        <img src={logo} />
      </Box>
    )
  )
})
