import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCactus, faChevronLeft } from '@fortawesome/pro-duotone-svg-icons'
import { Container } from '../Container'
import { KGContext } from '../KGProvider'
import { Box, Button, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const NotFound = (props) => {
  const { Link } = useContext(KGContext)

  return (
    <Box py={6}>
      <Container maxWidth='xs'>
        <Box textAlign='center' flex={1}>
          <FontAwesomeIcon
            size='6x'
            icon={faCactus}
            style={{ color: green[500] }}
          />
          <Box mb={2} />
          <Typography variant='h1' color='primary' style={{ lineHeight: 1 }}>
            <strong>404</strong>
          </Typography>
          <Typography
            variant='h5'
            color='textSecondary'
            style={{ lineHeight: 1 }}
          >
            NOT FOUND
          </Typography>
          <Box mb={6} />
          <Button
            variant='outlined'
            startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            color='primary'
            component={Link}
            to={`/`}
          >
            Go to HOME
          </Button>
        </Box>
      </Container>
    </Box>
  )
}
