import React from 'react'
import { MainContainer } from '../MainContainer'
import { connectPageEdit } from './edit.context'
import { PageEditSidebar } from './edit.sidebar'
import propTypes from 'prop-types'
import { PageEditBody } from './edit.body'

export const PageEdit = connectPageEdit((props) => {
  return <MainContainer dense sidebar={<PageEditSidebar />}>
    <PageEditBody />
  </MainContainer>
})
PageEdit.propTypes = {
  back: propTypes.string,
  onSave: propTypes.func.isRequired,
}