import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChoiceItem } from '../choice.item'

import { Container } from '../container'
import { Question } from '../question'

export const TrueFalse = ({ value, onCheckChange, checked, ...props }) => {
  const {t} = useTranslation()
  const handleCheck = (value) => () => onCheckChange && onCheckChange(value)

  return (
    <Container type={value.type}>
      <Question value={value} />
      <ChoiceItem
        label={t('True')}
        onClick={handleCheck(true)}
        selected={checked === true}
      />
      <ChoiceItem
        label={t('False')}
        onClick={handleCheck(false)}
        selected={checked === false}
      />
    </Container>
  )
}
