import React, { createContext, useEffect } from 'react'

export const ContextContentView = createContext({});

const ProviderContentView = ({ children, ...props}) => {
  const store = {
    ...props,
  }

  useEffect(()=>{
    if(props.data && props.data.title){
      document.title = `${props.data.title} | ${process.env.REACT_APP_SITE_NAME}`;
    } else {
      document.title = process.env.REACT_APP_SITE_NAME;
    }
  }, [props.data])

  return <ContextContentView.Provider value={store}>
    {children}
  </ContextContentView.Provider>
}

export const connectContentView = Comp => props => <ProviderContentView {...props}>
  <Comp {...props} />
</ProviderContentView>