import { withStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

export const AppIcon = withStyles((theme) => ({
  root: {
    display: 'grid',
    width: '1em',
    height: '1em',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    columnGap: '0.1em',
    rowGap: '0.1em',
    '&>div': {
      backgroundColor: 'var(--fa-secondary-color, currentColor)',
      width: '100%',
      height: '100%',
      fontSize: '0.3rem',
      justifySelf: 'center',
      alignSelf: 'center',
      transition: 'all 0.25s',
      opacity: 'var(--fa-secondary-opacity, 0.4)'
    },
    '&>div:nth-child(4)': {
      backgroundColor: 'var(--fa-primary-color, currentColor)',
      opacity: 1
    },
    '&>div:nth-child(6)': {
      backgroundColor: 'var(--fa-primary-color, currentColor)',
      opacity: 1
    },
    '&>div:nth-child(8)': {
      backgroundColor: 'var(--fa-primary-color, currentColor)',
      opacity: 1
    }
  },
  open: {
    '&>div:nth-child(2)': {
      backgroundColor: 'var(--fa-primary-color, currentColor)',
      opacity: 1
    },
    '&>div:nth-child(8)': {
      backgroundColor: 'var(--fa-secondary-color, currentColor)',
      opacity: 'var(--fa-secondary-opacity, 0.4)'
    }
  }
}))(({ classes, open, onClick }) => {
  const items = [0, 1, 2, 3, 4, 5, 6, 7, 8]
  return (
    <div
      className={clsx(classes.root, { [classes.open]: Boolean(open) })}
      onClick={onClick}
    >
      {items.map((key) => (
        <div key={key} />
      ))}
    </div>
  )
})
