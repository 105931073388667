import React, { useContext } from 'react'
import { ContextChat } from './ctx'
import { BackLink } from '../BackLink'
import { List, ListItem, ListItemText } from '@material-ui/core'

export const ChatSidebar = (props) => {
  const { back, view, contacts, baseURL, Link, sidebar } =
    useContext(ContextChat)

  return (
    <React.Fragment>
      {back && <BackLink to={back} />}
      <List>
        {contacts?.map((contact) => (
          <ListItem
            button
            divider
            dense
            key={contact.uid}
            selected={view === contact?.uid}
            component={Link}
            to={`${baseURL}/${contact?.uid}`}
          >
            <ListItemText primary={contact.displayName} />
          </ListItem>
        ))}
        {sidebar}
      </List>
    </React.Fragment>
  )
}
