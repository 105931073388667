import React from 'react'
import { PanelItem } from '../PanelContainer'
import { ColRowMenu } from './col.row.menu'
import { NewTable } from './new'
import { connectCETable } from './context'
import { TableEdit } from './edit'

export const ContentTable = connectCETable(({ content, index, ...props }) => {
  return (
    <PanelItem
      id={content.key}
      index={index}
      secondaryActions={<ColRowMenu {...{ content, index }} />}
    >
      {content.columns && content.rows && content.values ? (
        <TableEdit />
      ) : (
        <NewTable />
      )}
    </PanelItem>
  )
})
