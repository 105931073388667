import React from 'react';
import { connectPostEdit } from './context';
import { MainContainer } from '../MainContainer';
import { PESidebar } from './sidebar';
import propTypes from 'prop-types'
import { PEContent } from './content';

export const PostEdit = connectPostEdit(props => {
  return <MainContainer dense signInOnly sidebar={<PESidebar />}>
    <PEContent />
  </MainContainer>
});
PostEdit.propTypes = {
  back: propTypes.string,
  mapLists: propTypes.array.isRequired,
}