import {
  faCheck,
  faCopy,
  faEdit,
  faInfoCircle,
  faPlus,
  faTimes,
  faTrash
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'

const FAIcon = (props) => <FontAwesomeIcon size='xs' {...props} />

export const KuiActionIcon = ({ tx, ...props }) => {
  let newProps = {}

  switch (tx) {
    case 'add':
      newProps = {
        children: <FAIcon icon={faPlus} />,
        ...props
      }
      break
    case 'cancel':
      newProps = {
        children: <FAIcon icon={faTimes} />,
        ...props
      }
      break
    case 'check':
      newProps = {
        children: <FAIcon icon={faCheck} />,
        color: 'primary',
        ...props
      }
      break
    case 'copy':
      newProps = {
        children: <FAIcon icon={faCopy} />,
        ...props
      }
      break
    case 'edit':
      newProps = {
        children: <FAIcon icon={faEdit} />,
        color: 'primary',
        ...props
      }
      break
    case 'info':
      newProps = {
        children: <FAIcon icon={faInfoCircle} />,
        ...props
      }
      break
    case 'remove':
      newProps = {
        children: <FAIcon icon={faTrash} />,
        color: 'secondary',
        ...props
      }
      break
    default:
      newProps = {
        ...props
      }
      break
  }

  return <IconButton size='small' {...newProps} />
}
const lists = ['add', 'cancel', 'check', 'copy', 'edit', 'info', 'remove']
KuiActionIcon.prototype.lists = lists
KuiActionIcon.propTypes = {
  tx: propTypes.oneOf(lists)
}
