import React, { useContext } from 'react'
import { PanelItem } from '../PanelContainer'
import { FilePicker } from '../../FilePicker'
import { MenuItem } from '@material-ui/core'
import { FileDisplay } from '../../FileDisplay'
import { Container } from '../../Container'
import { ContentEditContext } from '../context'

export const ContentFile = ({ content, index, ...props }) => {
  const { updateContent, ...store } = useContext(ContentEditContext)
  const handleChange = (datas) => {
    updateContent(index, 'value', datas[0].content)
  }
  const File = React.forwardRef((props, ref) => (
    <FilePicker onConfirm={handleChange}>
      <MenuItem ref={ref}>Change</MenuItem>
    </FilePicker>
  ))

  return (
    <PanelItem id={content.key} index={index} actions={<File />}>
      <FileDisplay content={content} />
    </PanelItem>
  )
}
