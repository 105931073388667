import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { ContentEditContext } from '../context'
import { genKey } from 'draft-js'
import { ContextSlide } from './slide.ctx'
import { KGContext } from '../../KGProvider'

const defaultData = {
  from: 'post',
  id: ''
}
export const SwiperAdd = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: theme.palette.background.gradient
  },
  button: {
    borderColor: 'white',
    color: 'white'
  }
}))(({ classes, className, ...props }) => {
  const { t } = useContext(KGContext)
  const store = useContext(ContentEditContext)
  const { content, index } = useContext(ContextSlide);
  const [open, setOpen] = useState(false)
  const [state] = store.state
  const [data, setData] = useState({ ...defaultData })
  const isComplete = Boolean(data.from && data.id)

  const getDocs = () => state.docs.filter((d) => d.type === data.from)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setData({ ...defaultData })
    setOpen(false)
  }
  const handleChange =
    (key) =>
    ({ target }) =>
      setData((d) => ({ ...d, [key]: target.value }))
  const handleAdd = () => {
    const item = state.docs.find((d) => d.id === data.id)
    if (item) {
      const { cover, id, label, title } = item
      const values = [...(content.values || [])]
      store.updateContent(index, 'values', [
        ...values,
        { cover, id, title: title || label, key:genKey() },
      ])
      handleClose()
    }
  }

  useEffect(() => {
    if (open) {
      setData({ ...defaultData })
    }
  }, [open])

  return (
    <div className={clsx(className, classes.root)}>
      <Button
        className={classes.button}
        variant='outlined'
        disableElevation
        size='large'
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={handleOpen}
      >
        {t("ContentEdit.AddSlide")}
      </Button>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
        <DialogTitle>{t("Add")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant='outlined'>
            <InputLabel>{t("From")}</InputLabel>
            <Select
              labelWidth={40}
              value={data.from}
              onChange={handleChange('from')}
            >
              <MenuItem value='post'>Post</MenuItem>
              <MenuItem value='book'>Book</MenuItem>
            </Select>
          </FormControl>
          <Box mb={2} />
          <FormControl fullWidth variant='outlined'>
            <InputLabel>{t("Choose")}</InputLabel>
            <Select
              labelWidth={42}
              value={
                getDocs()
                  .map((d) => d.id)
                  .includes(data.id)
                  ? data.id
                  : ''
              }
              onChange={handleChange('id')}
            >
              <MenuItem value='' disabled>
                -- {t("Choose")} --
              </MenuItem>
              {getDocs().map((doc) => (
                <MenuItem value={doc.id} key={doc.id}>
                  {doc.label || doc.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color='primary' disabled={!isComplete} onClick={handleAdd}>
            {t("Add")}
          </Button>
          <Button onClick={handleClose}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})
