export const locale_th = {
  Add: 'เพิ่ม',
  AddPanel: 'เพิ่มกรอบ',
  Back: 'กลับ',
  Browse: 'เลือก',
  Cancel: 'ยกเลิก',
  Card: 'การ์ด',
  Change: 'เปลี่ยน',
  ChangeLanguage: 'เปลี่ยนภาษา',
  Class: 'คาบเรียน',
  Clear: 'ล้าง',
  Close: 'ปิด',
  Component: 'ส่วนประกอบ',
  Confirm: 'ยืนยัน',
  ContentEdit: {
    Title: 'ชื่อเรื่อง',
    Feature: 'ภาพปก',
    Heading: 'หัวเรื่อง',
    Paragraph: 'ย่อหน้า',
    Image: 'รูปภาพ',
    Cover: 'ภาพปก',
    Slide: 'สไลด์',
    Table: 'ตาราง',
    File: 'ไฟล์',
    Highlight: 'ไฮไลท์',
    Card: 'การ์ด',
    VDO: 'วีดีโอ',
    Book: 'หนังสือ',
    Collection: 'คอลเล็คชัน',
    AddSlide: 'เพิ่มสไลด์',
    AddHighlight: 'เพิ่มไฮไลท์',
    Divider: 'เส้นคั่น'
  },
  Course: 'หลักสูตร',
  Courses: {
    Course: 'คอส',
    AddQuestion: 'เพิ่มข้อสอบ',
    Amount: 'จำนวน',
    Description: 'รายละเอียด',
    DoYouWantToRemove: 'คุณต้องการลบ "{{name}}" หรือไม่?',
    Lesson: 'บทเรียน',
    LessonContent: 'เนื้อหาบทเรียน',
    ManageStudent: 'จัดการผู้เรียน',
    MyCourse: 'หลักสูตรของฉัน',
    QuestionBank: 'คลังแบบทดสอบ',
    Question: 'ข้อสอบ',
    QuestionTitle: 'ชื่อข้อสอบ',
    Quiz: 'แบบทดสอบ',
    Section: 'กลุ่ม',
    Score: 'คะแนน',
    Teacher: 'ผู้สอน'
  },
  Choose: 'เลือก',
  Close: 'ปิด',
  Date: 'วันที่',
  Detail: 'รายละเอียด',
  DisplayName: 'ชื่อที่ใช้แสดง',
  Download: "ดาวน์โหลด",
  Edit: 'แก้ไข',
  Empty: 'ว่างเปล่า',
  False: 'ผิด',
  FeatureImage: 'ภาพปก',
  File: 'ไฟล์',
  From: 'จาก',
  Grading: 'ตัดเกรด',
  Header: 'หัวเรื่อง',
  Highlight: 'ไฮไลท์',
  Image: 'รูปภาพ',
  Language: 'ภาษา',
  LastModified: 'ปรับปรุงล่าสุด',
  Line: 'ไลน์',
  Number: 'ตัวเลข',
  Paragraph: 'ย่อหน้า',
  PleaseWait: 'กรุณารอสักครู่',
  Private: 'ส่วนตัว',
  Public: 'สาธารณะ',
  Question: {
    MultipleChoice: 'หลายตัวเลือก',
    TrueFalse: 'ถูก / ผิด',
    Matching: 'จับคู่',
    Sorting: 'เรียงลำดับ',
    QuestionType: 'ประเภทคำถาม',
    Question: 'คำถาม',
    Choice: 'ตัวเลือก',
    AddPanel: 'เพิ่มตัวเลือก',
    Answer: 'คำตอบ',
    SelectRightAnswer: 'เลือกคำตอบที่ถูกต้อง',
    AddMatching: 'เพิ่มการจับคู่',
    AddAnswer: 'เพิ่มคำตอบ',
    AnswerText: 'ข้อความคำตอบ',
    Quiz: 'แบบทดสอบ',
    SelectAnswer: 'เลือกคำตอบ',
    Match: 'คู่ที่',
    SubQuestion: 'คำถามย่อยที่',
    Sequence: 'ลำดับ',
    Dmultiple: 'ให้เลือกคำตอบที่ถูกต้องที่สุด',
    Dmatching: 'ให้จับคู่ตัวเลือกกับคำถามให้ถูกต้อง',
    Dsorting: 'ให้เรียงลำดับตัวเลือกให้ถูกต้อง',
    Dtruefalse: 'ให้เลือกว่าข้อความที่ให้มาถูกหรือผิด'
  },
  Read: 'อ่าน',
  Remove: 'ลบ',
  RowPerPage: 'จำนวนแถวต่อหน้า',
  Save: 'บันทึก',
  Search: 'ค้นหา',
  Select: 'เลือก',
  Setting: 'ตั้งค่า',
  SignOut: 'ออกจากระบบ',
  Student: 'ผู้เรียน',
  Subject: 'หมวดหมู่',
  Teacher: 'ผู้สอน',
  Title: 'ชื่อ',
  TitleEdit: {
    NoTitle: 'ไม่มีชื่อ',
    ChangeTitle: 'แก้ไขชื่อ'
  },
  True: 'ถูก',
  Type: 'ประเภท',
  Untitled: 'ยังไม่มีชื่อ',
  Update: 'อัปเดต',
  Upload: 'อัปโหลด',
  Users: {
    DisplayName: 'ชื่อที่ใช้แสดง',
    ProfileImage: 'รูปประจำตัว',
    NameLast: 'ชื่อ-นามสกุล',
    StudentNumber: 'รหัสนักศึกษา',
    Change: 'ข้อมูลนักศึกษา'
  },
  Value: 'ค่า',
  Video: 'วีดิโอ',
  Visibility: 'การมองเห็น'
}
