import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { Box, Typography, withStyles } from '@material-ui/core'
import { Fragment } from 'react'
import Swiper from 'react-id-swiper'
import { ImageDisplay } from '../ImageDisplay'

import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs
} from 'swiper'

SwiperCore.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs
])

export const HilightContainer = withStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: 'calc(100% * 360 / 960)',
    '--swiper-theme-color': 'white',
  },
  wrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '& .swiper-container': {
      width: '100%',
      height: '100%',
      position: 'relative',
      listStyle: 'none',
      overflow: 'hidden',
      zIndex: 1
    },
    '& .swiper-wrapper': {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      zIndex: 1
    },
    '& .swiper-slide': {
      width: '100%',
      height: '100%',
      flexShrink: 0
    }
  },
}))(({ classes, ...props }) => {
  const swiperRef = useRef(null)
  const [params] = useState({
    slidesPerView: 'auto',
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    observer: true,
    ref: swiperRef
  })

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.wrap}>
          <Swiper {...params} {...props} />
        </div>
      </div>
    </Fragment>
  )
})

export const HilightItem = withStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius * 2,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
    overflow: 'hidden',
    '&:active': {
      cursor: 'grabbing'
    }
  },
  left: {},
  right: {
    flexDirection: 'row-reverse'
  },
  full: {
    '& img': {
      filter: 'brightness(75%)'
    },
    '& .hilight-content': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      textShadow: '0 0 32px rgba(0,0,0,0.5)',
      backgroundColor: 'rgba(0,0,0,0)',
      zIndex: 2
    }
  }
}))(({ classes, variant, children, placeholder, ...props }) => {
  return (
    <div {...props}>
      <Box className={clsx(classes.root, classes[variant || 'left'])}>
        {placeholder && (
          <Fragment>
            <HilightImage />
            <HilightContent>
              <Typography variant='h4'>Sample Title</Typography>
              <Typography variant='caption'>Sample Detail</Typography>
            </HilightContent>
          </Fragment>
        )}
        {children}
      </Box>
    </div>
  )
})

export const HilightImage = withStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    backgroundImage: theme.palette.background.gradient,
    width: '100%',
    minWidth: 'calc(100% * 600 / 960)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }
}))(({ classes, id, image, ...props }) => {
  return (
    <div className={classes.root}>
      {image && <ImageDisplay image={image} />}
    </div>
  )
})

export const HilightContent = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  primary: {
    marginBottom: theme.spacing(2)
  },
  secondary: {
    marginBottom: theme.spacing(2)
  }
}))(({ classes, children, ...props }) => {
  return (
    <div className={clsx('hilight-content', classes.root)}>
      {props.primary && (
        <Typography
          className={classes.primary}
          variant='h6'
          {...props.primaryTypographyProps}
        >
          {props.primary}
        </Typography>
      )}
      {props.secondary && (
        <Typography
          className={classes.secondary}
          variant='caption'
          {...props.secondaryTypographyProps}
        >
          {props.secondary}
        </Typography>
      )}
      {children}
    </div>
  )
})
