import React, { useEffect, useState } from 'react'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import { Typography, withStyles } from '@material-ui/core'
import { Paragraph } from '../Paragraph'

export const ViewParagraph = withStyles((theme) => ({
  root: {
    display: 'inline'
  }
}))(({ classes, value, ...props }) => {
  return <Paragraph value={value} />
})
