import { faDownload, faFileAlt, faPaperPlane, faSave } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { UploadFileButton } from './uploadFile';
import { DuotoneButton } from '../DuotoneButton';

const useStyles = makeStyles((theme) => ({
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    messageArea: {
        height: '50vh',
        overflowY: 'auto',
        padding: theme.spacing(2)
    },
    messageBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        "&:not(:last-child)": {
            marginBottom: theme.spacing(1)
        },
        "& .value-text": {
            maxWidth: "60%",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "normal",
            display: "inline-block",
        }
    },
    my: {
        flexDirection: "row-reverse",
        "& .value-text": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    }
}))

export const QuizSubjective = ({ docs, isTeacher, onConfirm, onOpenfile, ...props }) => {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const handleChange = ({ target }) => setMessage(target.value);
    const handleConfirm = () => {
        let sender = {
            value: message,
            sender: (isTeacher ? "teacher" : "student"),
            key: Math.floor(Math.random() * 1000000),
            timestamp: Date.now(),
            type: "text"
        }
        if (onConfirm && (message + "").trim()) {
            onConfirm(sender)
            setMessage("")
        }
    }
    const handleUploadFile = (id) => {
        let fileSend = {
            value: id,
            sender: (isTeacher ? "teacher" : "student"),
            key: Math.floor(Math.random() * 1000000),
            timestamp: Date.now(),
            type: "file"
        }
        if (onConfirm && id) {
            onConfirm(fileSend)
        }
    }
    const OpenFile = (key) => () => {
        if (onOpenfile) {
            onOpenfile(key);
        }
    }

    return (<div>
        <Paper className={classes.messageArea}>
            {docs.map(data => (
                <Box className={clsx(classes.messageBox, {
                    [classes.my]: Boolean((isTeacher && data.sender === "teacher") || (!isTeacher && data.sender === "student"))
                })} key={data.key}>
                    <Paper className="value-text" style={{ padding: "0.5em 1em" }}>
                        {data.type === "file"
                            ? (
                                <Box display="flex" flexDirection="column">
                                    <Box textAlign="center" pb={1} >
                                        <FontAwesomeIcon size="4x" icon={faFileAlt} />
                                    </Box>
                                    <Box children={data.value} pb={1} />
                                    <Box>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            disableElevation
                                            startIcon={<FontAwesomeIcon icon={faDownload} />}
                                            onClick={OpenFile(data.key)}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                </Box>
                            )
                            : data.value}
                    </Paper>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ margin: "0 0.5em" }}
                    >
                        {moment(data.timestamp).format("LT")}
                    </Typography>
                </Box>
            ))}
        </Paper>
        <Divider />
        <Box display="flex" alignItems="center" py={2}>
            <Box flex={1}>
                <TextField
                    id="outlined-basic-email"
                    label="Message"
                    fullWidth value={message || ''}
                    onChange={handleChange}
                    multiline
                    autoFocus />
            </Box>
        </Box>
        <Box display="flex">
            <UploadFileButton onUploadSuccess={handleUploadFile} />
            <Box flex={1} />
            <DuotoneButton onClick={handleConfirm} icon={faPaperPlane} disabled={!Boolean((message + "").trim())}>
                send
            </DuotoneButton>
        </Box>
    </div>)
}