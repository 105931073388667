import React, { useContext } from 'react'
import clsx from 'clsx'
import { Box, withStyles } from '@material-ui/core'
import { Toolbar } from './AppBar'
import { MainContaxt } from './context'

export const Contain = withStyles((theme) => ({
  pc: {
    marginLeft: 272
  },
  rightbar: {
    marginRight: 272
  }
}))(({ classes, children, ...props }) => {
  const { isMobile, open, rightBar, sidebar, dense, protection, ...store } =
    useContext(MainContaxt)

  return (
    <div
      className={clsx(classes.root, {
        [classes.pc]: !Boolean(isMobile || !sidebar),
        [classes.rightbar]: Boolean(rightBar && !isMobile)
      })}
      onContextMenu={(e) => {
        if (protection) {
          e.preventDefault()
        }
      }}
    >
      <Toolbar style={{ marginBottom: 1 }} />
      <Box py={dense ? 0 : 6}>{children}</Box>
    </div>
  )
})
