import React, { useRef, useState, useEffect } from 'react'
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faVideo } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CardMedia, withStyles } from '@material-ui/core'
import videojs from 'video.js'

const facebook_parser = (url) => {
  const preg = /(?=src=").*?(?=[*"])/g
  const results = url.match(preg)
  if (results && results.length) {
    return results[0]
  }
  return null
}

export const youtube_parser = (url) => {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  let match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : null
}

const VideoPlayer = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '& .video-js': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  }
}))(({ classes, ...props }) => {
  const videoNode = useRef(null)
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    if (!player) {
      const currentPlayer = videojs(videoNode.current, props)
      setPlayer(currentPlayer)
      return () => {
        if (player) {
          player.dispose()
        }
      }
    }
  }, [props, player])

  return (
    <div className={classes.root}>
      <div data-vjs-player>
        <video id='show' ref={videoNode} className='video-js'></video>
      </div>
    </div>
  )
})

const Placeholder = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: theme.palette.background.gradient
  }
}))(({ from, ...props }) => (
  <Box {...props}>
    <FontAwesomeIcon
      size='4x'
      color='inherit'
      icon={{ youtube: faYoutube, facebook: faFacebook }[from] || faVideo}
    />
  </Box>
))

export const VideoDisplay = withStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundImage: ({ content }) =>
      Boolean(content && content.value)
        ? 'none'
        : theme.palette.background.gradient,
    '&:after': {
      content: '""',
      display: 'block',
      paddingTop: ({ content }) =>
        Boolean(content && content.ratio)
          ? Math.ceil(100 / content.ratio) + '%'
          : 'calc(100% * 9 / 16)'
    },
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  media: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(({ classes, content, secondaryActions, ...props }) => {
  return (
    <Box className={classes.root}>
      {(() => {
        switch (content.from) {
          case 'facebook':
            if (facebook_parser(content.value)) {
              return (
                <CardMedia
                  className={classes.media}
                  component='iframe'
                  title='VDO'
                  src={facebook_parser(content.value)}
                />
              )
            }
          case 'link':
            if (content.value) {
              return (
                <VideoPlayer
                  sources={{ src: content.value, type: 'video/mp4' }}
                  controls
                />
              )
            }
          case 'youtube':
            if (youtube_parser(content.value)) {
              return (
                <CardMedia
                  className={classes.media}
                  component='iframe'
                  title='VDO'
                  src={`https://www.youtube.com/embed/${youtube_parser(
                    content.value
                  )}`}
                />
              )
            }
          default:
            return <Placeholder from={content.from} />
        }
      })()}
      {secondaryActions}
    </Box>
  )
})
