import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Divider,
  Drawer,
  Fab,
  IconButton,
  withStyles
} from '@material-ui/core'
import React, { Fragment, useContext } from 'react'
import { Toolbar } from './AppBar'
import { MainContaxt } from './context'

export const RightBar = withStyles((theme) => ({
  paper: {
    width: 272,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.default,
  },
  fab: {
    position: 'fixed',
    bottom: 16,
    right: 32
  }
}))(({ classes, ...props }) => {
  const { rightBar, open, handleOpen, isMobile, ...store } =
    useContext(MainContaxt)

  return (
    <Fragment>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor='right'
        open={open.right}
        onClose={handleOpen('right', false)}
        PaperProps={{ className: classes.paper }}
      >
        <Toolbar>
          <Box flex={1} />
          <IconButton
            edge='end'
            style={{ width: 48 }}
            onClick={handleOpen('right', false)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        </Toolbar>
        <Divider />
        {rightBar}
      </Drawer>
      {isMobile && (
        <Fab
          className={classes.fab}
          color='primary'
          aria-label='add'
          onClick={handleOpen('right', !open.right)}
        >
          <FontAwesomeIcon
            size='2x'
            icon={faChevronLeft}
            rotation={open.right ? 180 : 0}
            style={{ transition: 'all 0.25s' }}
          />
        </Fab>
      )}
    </Fragment>
  )
})
