import { faPaperclip } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress, IconButton } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { ContextChat } from './ctx'

const defaultState = {
  uploading: false,
  progress: 0,
  value: ''
}
export const ChatUploadButton = (props) => {
  const { as, view, control } = useContext(ContextChat)
  const [state, setState] = useState({ ...defaultState })

  const handleChangeFile = async ({ target: { value, files } }) => {
    setState((s) => ({ ...s, progress: 0, value, uploading: true }))
    const data = await control.upload(
      files,
      ({ progress }) => progress && setState((s) => ({ ...s, progress }))
    )
    await control.send(as, view, { ...data, type: 'file', date: new Date() })
    setState({ ...defaultState })
  }

  return (
    <React.Fragment>
      {state.uploading ? (
        <IconButton edge="start" disabled>
          <CircularProgress
            variant='determinate'
            size={24}
            color='inherit'
            value={state.progress * 0.75}
          />
        </IconButton>
      ) : (
        <label>
          <input
            type='file'
            value={state.value}
            hidden
            onChange={handleChangeFile}
          />
          <IconButton component='span' edge='start' style={{ marginRight: 8 }}>
            <FontAwesomeIcon size='xs' icon={faPaperclip} />
          </IconButton>
        </label>
      )}
    </React.Fragment>
  )
}
