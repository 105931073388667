import React, { useContext } from 'react'

import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import { FeatureImage } from '../FeatureImage'
import { MainContainer } from '../MainContainer'
import { ContentHeader } from '../ContentHeader'
import { Container } from '../Container'
import { connectContentView, ContextContentView } from './context'
import { DateToStr } from '../functions'

import { Paragraph } from '../Paragraph'
import { ImageDisplay } from '../ImageDisplay'
import { VideoDisplay } from '../VideoDisplay'
import { ListItemButton } from '../ListItemButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faClock,
  faListOl
} from '@fortawesome/pro-duotone-svg-icons'
import { KGContext } from '../KGProvider'
import { TableXY } from '../TableXY'
import { Fragment } from 'react'
import pt from 'prop-types'
import { SaveImageButton } from './image/save'
import { ImageCredit } from './image/credit'
import { CVImageURL } from './image/url'

const PaddingBox = ({ top, bottom, children, maxWidth = 'sm' }) => (
  <Container maxWidth={maxWidth}>
    <Box mt={top || 0} mb={bottom || 3} children={children}>
      <Box style={{ position: 'relative' }}>{children}</Box>
    </Box>
  </Container>
)

export const ContentView = connectContentView((props) => {
  const { Link } = useContext(KGContext)
  const { data, post, hideTitle, savephoto, credit, ...store } =
    useContext(ContextContentView)

  const handleSaveImage = (id) => () => {
    savephoto(id)
  }

  const children = (
    <React.Fragment>
      {data.cover && (
        <Box position='relative'>
          <FeatureImage image={data.cover} />
          {savephoto && (
            <SaveImageButton onClick={handleSaveImage(data.cover.id)} />
          )}
          {credit?.[data.cover?.id] && (
            <ImageCredit {...(credit?.[data.cover?.id] || {})} />
          )}
        </Box>
      )}
      <Box py={6}>
        <Container maxWidth={!post ? 'lg' : 'sm'}>
          {!hideTitle && (
            <ContentHeader
              breadcrumbs={store.breadcrumbs}
              label={data.title}
              secondary={
                <Box display='flex' alignItems='center'>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ marginRight: '0.5em' }}
                  />
                  {DateToStr(data, 'L')}
                  <Box children='|' mx={1} />{' '}
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ marginRight: '0.5em' }}
                  />
                  {DateToStr(data, 'LT')}
                </Box>
              }
            />
          )}
        </Container>
        {Array.isArray(data.contents) &&
          data.contents.map((content) => {
            switch (content.type) {
              case 'heading':
                return (
                  <PaddingBox
                    {...content}
                    maxWidth={!post ? 'lg' : 'sm'}
                    key={content.key}
                  >
                    <Box textAlign={content.align}>
                      <Typography
                        variant={content.variant || 'h5'}
                        color='textPrimary'
                      >
                        {content.value}
                      </Typography>
                    </Box>
                  </PaddingBox>
                )
              case 'paragraph':
                return (
                  content.value && (
                    <PaddingBox
                      {...content}
                      maxWidth={!post ? 'lg' : 'sm'}
                      key={content.key}
                    >
                      <Paragraph value={content.value} />
                    </PaddingBox>
                  )
                )
              case 'image':
                return (
                  content.value && (
                    <Fragment key={content.key}>
                      <PaddingBox {...content} maxWidth={!post ? 'lg' : 'sm'}>
                        <CVImageURL content={content}>
                          <ImageDisplay image={content.value} />
                        </CVImageURL>
                        {savephoto && (
                          <SaveImageButton
                            onClick={handleSaveImage(content.value.id)}
                          />
                        )}
                        {credit?.[content?.value?.id] && (
                          <ImageCredit
                            {...(credit?.[content?.value?.id] || {})}
                          />
                        )}
                      </PaddingBox>
                    </Fragment>
                  )
                )
              case 'vdo':
                return (
                  <PaddingBox
                    {...content}
                    maxWidth={!post ? 'lg' : 'sm'}
                    key={content.key}
                  >
                    <VideoDisplay content={content} />
                  </PaddingBox>
                )
              case 'quiz':
                return (
                  <PaddingBox
                    {...content}
                    maxWidth={!post ? 'lg' : 'sm'}
                    key={content.key}
                  >
                    <ListItemButton
                      component={Link}
                      to={`${store.quizLinkURL || '#'}${content.value.id}`}
                      target='_blank'
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon size='2x' icon={faListOl} />
                      </ListItemIcon>
                      <ListItemText
                        primary={content.value.label || content.value.title}
                        secondary={`จำนวน ${content.amount} ข้อ`}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </ListItemButton>
                  </PaddingBox>
                )
              case 'table':
                return (
                  <PaddingBox
                    {...content}
                    maxWidth={!post ? 'lg' : 'sm'}
                    key={content.key}
                  >
                    <TableXY data={content} />
                  </PaddingBox>
                )
              case 'divider':
                return (
                  <PaddingBox
                    {...content}
                    maxWidth={!post ? 'lg' : 'sm'}
                    key={content.key}
                  >
                    <Divider />
                  </PaddingBox>
                )
              default:
                return null
            }
          })}
      </Box>
      {props.children}
    </React.Fragment>
  )

  return props.noContainer ? (
    children
  ) : (
    <MainContainer dense loading={store.loading} sidebar={props.sidebar}>
      {children}
    </MainContainer>
  )
})
ContentView.propTypes = {
  savephoto: pt.func,
  credit: pt.objectOf(
    pt.shape({
      label: pt.node.isRequired,
      link: pt.string.isRequired,
      type: pt.string.isRequired
    })
  )
}
