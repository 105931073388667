import { Divider, Drawer, IconButton, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { Toolbar } from './AppBar'
import { MainContaxt } from './context'
import { BarIcon } from '../Icons'

export const LeftSidebarToggle = withStyles((theme) => ({
  root: {
    width: 48,
    fontSize: 24,
    ...theme.palette.duotone,
    marginRight: theme.spacing(1)
  }
}))((props) => {
  const { sidebar, handleOpen, isMobile, ...store } = useContext(MainContaxt)

  return (
    Boolean(sidebar && isMobile) && (
      <IconButton edge='start' onClick={handleOpen('left', true)} {...props}>
        <BarIcon open={Boolean(store.open.left)} />
      </IconButton>
    )
  )
})

export const LeftSidebar = withStyles((theme) => ({
  rootLB: {
    zIndex: theme.zIndex.appBar - 1
  },
  paper: {
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.default,
    width: 272,
    boxSizing: 'border-box'
  },
  icon: {
    width: 48,
    fontSize: 24,
    ...theme.palette.duotone
  },
  toolbar: {
    borderBottom: `solid 1px ${theme?.palette?.grey[300]}`,
    boxSizing: 'border-box'
  }
}))(({ classes, ...props }) => {
  const { open, handleOpen, isMobile, ...store } = useContext(MainContaxt)

  return store.sidebar ? (
    <Drawer
      className={classes.rootLB}
      variant={isMobile ? 'temporary' : 'permanent'}
      open={open.left}
      onClose={handleOpen('left', false)}
      PaperProps={{ className: classes.paper }}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.icon}
          edge='start'
          onClick={handleOpen('left', false)}
        >
          <BarIcon open={Boolean(open.left)} />
        </IconButton>
      </Toolbar>
      {store.sidebar}
      <Toolbar />
    </Drawer>
  ) : null
})
