import { withWidth } from '@material-ui/core'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { KGContext } from '../KGProvider'

const getUser = async (firebase, uids) => {
  const result = await firebase.functions().httpsCallable('getUsersInfo')({
    uids
  })
  return result.data
}
const getPosts = async (firebase, ids) => {
  const promps = [...(Array.isArray(ids) ? ids : [])]
    .filter((s, i, a) => a.indexOf(s) === i)
    .map(async (id) => {
      const snapshot = await firebase
        .firestore()
        .collection('documents')
        .doc(id)
        .get()
      return snapshot.exists ? { [id]: { ...snapshot.data(), id } } : null
    })
  return Object.assign({}, ...(await Promise.all(promps)))
}

export const Context = createContext({})

const defaultState = {
  open: "cover",
  usersFetched: false,
  users: {},
  pages: [],
  pagesKey: [],
  postFetched: false,
  posts: {},
}
export const Provider = withWidth()(({ children, ...props }) => {
  const { firebase, t } = useContext(KGContext)
  const [state, setState] = useState({ ...defaultState })

  const store = {
    ...props,
    state: [state, setState],
    t,
    changePage: (open) => {
      setState(s=>({ ...s, open }));
      document.querySelector('html').scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (store.data && store.data.user) {
      if (store.data.items) {
        const pages = store.data.items.reduce((total, item) => {
          switch (item.type) {
            case 'post':
              return [...total, item.id]
            case 'folder':
              return Array.isArray(item.child)
                ? [...total, ...item.child.map((c) => c.id)]
                : total
            default:
              return total
          }
        }, [])
        const pagesKey = store.data.items.reduce((total, item) => {
          switch (item.type) {
            case 'post':
              return [...total, `${item.key}`]
            case 'folder':
              return Array.isArray(item.child)
                ? [...total, ...item.child.map((c) => `${item.key}${c.key}`)]
                : total
            default:
              return total
          }
        }, [])
        getPosts(firebase, pages).then(async (posts) => {
          const usersId = [
            store.data.user,
            ...Object.values(posts).map((post) => post.user)
          ].filter((s, i, a) => a.indexOf(s) === i)
          const users = await getUser(firebase, usersId)
          setState((s) => ({
            ...s,
            postFetched: true,
            pages,
            posts,
            pagesKey,
            users,
            usersFetched: true,
          }))
        })
        setState((s) => ({ ...s, pages }))
      }
    } else {
      setState({ ...defaultState })
    }
  }, [store.data])

  return <Context.Provider value={store}>{children}</Context.Provider>
})

export const connect = (Comp) => (props) =>
  <Provider {...props} children={<Comp {...props} />} />
