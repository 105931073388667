import React, { Fragment, useContext, useState } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  withStyles
} from '@material-ui/core'
import { QuizEditorContext } from '../context'
import { Panel } from '../panel'
import { SelectType } from '../select.type'
import { DialogRemove } from '../../DialogRemove'
import { useTranslation } from 'react-i18next'

const AnswerBox = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  }
}))(Box)

export const OptionsMultiple = (props) => {
  const {t} = useTranslation()
  const { genKey, ...store } = useContext(QuizEditorContext)
  const [data, setData] = store.data
  const [del, setDel] = useState(null)

  const handleTypeChange = (index) => (value) =>
    setData((d) => {
      let options = [...(d.options || [])]
      options[index] = { ...options[index], type: value, value: null }
      return { ...d, options }
    })
  const handleValueChange = (index) => (value) =>
    setData((d) => {
      let options = [...(d.options || [])]
      if (options[index].type === 'image') {
        options[index].data = value
      } else if (options[index].type === 'paragraph') {
        options[index].value = value
      }
      return { ...d, options }
    })
  const handleChangeAnswer = ({ target }) =>
    setData((d) => ({ ...d, answer: target.value }))
  const handleAddOption = () =>
    setData((d) => {
      let options = [...(d.options || [])]
      options.push({ key: genKey(), value: null, type: 'paragraph' })
      return { ...d, options }
    })
  const handleDelete = (key) => () => setDel(key)
  const handleDeleteConfirm = () =>
    setData((d) => {
      let options = [...(d.options || [])].filter((i) => i.key !== del)
      setDel(null)
      return { ...d, options }
    })

  return (
    Array.isArray(data && data.options) && (
      <Fragment>
        <Panel
          id='choice'
          title={t('Question.Choice')}
          actions={
            <Button color='primary' onClick={handleAddOption}>
              {t("Question.AddPanel")}
            </Button>
          }
        >
          {data.options.map((item, index) => (
            <AnswerBox key={item.key}>
              <SelectType
                actions={
                  <Typography>
                    <b>{t('Question.Choice')} {index + 1}</b>
                  </Typography>
                }
                type={item.type}
                value={item.type === 'image' ? item.data : item.value}
                onTypeChange={handleTypeChange(index)}
                onValueChange={handleValueChange(index)}
              />
              {data.options.length > 2 && (
                <Box mt={1}>
                  <Button color='secondary' onClick={handleDelete(item.key)}>
                  {t('Remove')}
                  </Button>
                </Box>
              )}
            </AnswerBox>
          ))}
          <DialogRemove
            title='Remove'
            label='Do you want to remove this option?'
            open={Boolean(del)}
            onClose={handleDelete(null)}
            onConfirm={handleDeleteConfirm}
          />
        </Panel>
        <Panel id='answer' title={t('Question.Answer')}>
          <Select
            fullWidth
            variant='outlined'
            displayEmpty
            value={
              (data.options || []).map((o) => o.key).includes(data.answer)
                ? data.answer
                : ''
            }
            onChange={handleChangeAnswer}
          >
            <MenuItem value='' disabled>
              -- {t("Question.SelectRightAnswer")} --
            </MenuItem>
            {Array.isArray(data.options) &&
              data.options.map((option, index) => (
                <MenuItem key={option.key} value={option.key}>
                  {t('Question.Choice')} {index + 1}
                </MenuItem>
              ))}
          </Select>
        </Panel>
      </Fragment>
    )
  )
}
