import { Container } from '@material-ui/core'
import React, { useContext } from 'react'
import { MainContainer } from '../MainContainer'
import { NotFound } from '../NotFound'
import { connect, Context } from './context'

import { Sidebar } from './sidebar'
import { Cover } from './cover'
import { Content } from './content'

export const ViewBook = connect(({ fetched, data, ...props }) => {
  const store = useContext(Context)
  const [state] = store.state

  return (
    <MainContainer loading={!fetched} dense sidebar={<Sidebar />}>
      {data && data.type === 'book' ? (
        <React.Fragment>
          {state.open === 'cover' ? <Cover /> : <Content />}
        </React.Fragment>
      ) : (
        <NotFound />
      )}
    </MainContainer>
  )
})
