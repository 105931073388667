import React, { createContext, useContext, useEffect, useState } from 'react'
import { KGContext } from '../KGProvider'
import { ChatController } from './ctl'

export const ContextChat = createContext({})

const Pvd = ({ children, ...props }) => {
  const { firebase, Link, t } = useContext(KGContext)
  const [control, setControl] = useState()
  const [data, setData] = useState({
    fs: false,
    fr: false,
    sender: null,
    receiver: null
  })

  const store = {
    ...props,
    Link,
    datas: [data, setData],
    control,
    t,
  }

  useEffect(() => {
    if (store?.as && store?.view) {
      const control = new ChatController(firebase)
      setControl(control)
      return control.watch(store?.as, store?.view, (data) =>
        setData((d) => ({ ...d, ...data }))
      )
    }
  }, [store?.as, store?.view])

  useEffect(() => {
    if (store?.as && store?.view && data?.receiver?.unread && control) {
      control.clearUnread(store?.as, store?.view, data?.receiver?.unread)
    }
  }, [store?.as, store?.view, data?.receiver?.unread, control])

  return <ContextChat.Provider value={store}>{children}</ContextChat.Provider>
}

export const connectChat = (Comp) => (props) =>
  (
    <Pvd {...props}>
      <Comp {...props} />
    </Pvd>
  )
