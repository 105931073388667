import { Paragraph } from '../Paragraph'
import { ImageDisplay } from '../ImageDisplay'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles,
  alpha
} from '@material-ui/core'
import React from 'react'

const ViewCell = ({ data, ...props }) => {
  switch (data.type) {
    case 'paragraph':
      return <Paragraph value={data.value} />
    case 'image':
      return <ImageDisplay image={data.value} />
    case 'number':
      return (
        <Box textAlign='right'>
          <Typography variant='body2' color="textSecondary">{parseFloat(data.value)}</Typography>
        </Box>
      )
    default:
      return process.env.NODE_ENV === 'development' ? (
        <pre>{JSON.stringify(data, null, 4)}</pre>
      ) : null
  }
}

const Row = withStyles((theme) => ({
  root: {}
}))(TableRow)
const Cell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey[300]}`,
    "&:last-child": {
      paddingRight: theme.spacing(1),
    }
  }
}))(TableCell)

export const TableXY = ({ data, ...props }) => {
  const { columns, rows, values } = data
  return columns && rows && values ? (
    <TableContainer>
      <Table size='small'>
        <TableBody>
          {Array.isArray(columns) &&
            Array.isArray(rows) &&
            rows.map((row) => (
              <Row hover key={row.key}>
                {columns.map((col) => (
                  <Cell key={col.key}>
                    {values[col.key + '-' + row.key] && (
                      <ViewCell data={values[col.key + '-' + row.key]} />
                    )}
                  </Cell>
                ))}
              </Row>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null
}
