import React, { useEffect, useState } from 'react'
import { List, Typography, withStyles } from '@material-ui/core'

import { shuffle } from '../shuffle'
import { ChoiceItem } from '../choice.item'
import { Question } from '../question'
import { Container } from '../container'

export const Multiple = withStyles((theme) => ({
  question: {
    display: 'flex'
  }
}))(({ classes, value, checked, onCheckChange, ...props }) => {
  const [options, setOptions] = useState([])
  const handleCheck = (id) => () => onCheckChange && onCheckChange(id)

  useEffect(() => {
    if (value.options) {
      setOptions(value.notShuffle ? value.options : shuffle(value.options))
    }
  }, [value])

  return (
    <Container type={value.type}>
      {typeof props.number === 'number' ? (
        <div>{props.number + '.'}&nbsp;</div>
      ) : (
        ''
      )}
      <Question value={value} />
      <List>
        {Array.isArray(value.options) &&
          options.map((option) => option && (
            <ChoiceItem
              value={option}
              selected={option.key === checked}
              onClick={handleCheck(option.key)}
              key={option.key}
            />
          ))}
      </List>
    </Container>
  )
})
