import { withStyles } from '@material-ui/core'
import React from 'react'

export const CardMore = withStyles(theme=>({
  root: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    zIndex: 1,
    color: 'white',
  }
}))(({ classes:cs, children, ...props}) => {
  return (<div className={cs.root}>{children}</div>)
})