import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'

import { ContentEditContext } from '../context'

const defaultData = {
  from: 'link',
  value: '',
  ratio: 1.78
}
export const DialogChange = forwardRef(({ content, index, ...props }, ref) => {
  const { updateContent } = useContext(ContentEditContext)
  const [data, setData] = useState({ ...defaultData })
  const [open, setOpen] = useState(false)

  const handleOpen = (op) => () => setOpen(op)
  const handleConfirm = () => {
    updateContent(index, 'from', data.from)
    updateContent(index, 'value', data.value)
    updateContent(index, 'ratio', data.ratio)
    setOpen(false)
  }
  const handleChange =
    (key) =>
    ({ target }) => {
      setData((s) => ({ ...s, [key]: target.value }))
      if (key === 'value') {
        if (target.value.includes('youtu')) {
          setData((s) => ({ ...s, from: 'youtube' }))
        } else if (target.value.includes('facebook')) {
          setData((s) => ({ ...s, from: 'facebook' }))
        }
      }
    }

  useEffect(() => {
    setData((s) => ({ ...s, ...content }))
  }, [content.value, content.from, content.ratio])

  return (
    <Fragment>
      <MenuItem ref={ref} onClick={handleOpen(true)}>
        Change
      </MenuItem>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={handleOpen(false)}>
        <DialogTitle>Change</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='video-from-label'>From</InputLabel>
            <Select
              labelId='video-from-label'
              labelWidth={38}
              value={data.from}
              onChange={handleChange('from')}
            >
              <MenuItem value='youtube'>Youtube</MenuItem>
              <MenuItem value='facebook'>Facebook</MenuItem>
              <MenuItem value='link'>Link</MenuItem>
            </Select>
          </FormControl>
          <Box pb={2} />
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='video-ratio-label'>Ratio</InputLabel>
            <Select
              labelId='video-ratio-label'
              labelWidth={38}
              value={data.ratio}
              onChange={handleChange('ratio')}
            >
              <MenuItem value={1.78}>16:9</MenuItem>
              <MenuItem value={1.33}>4:3</MenuItem>
              <MenuItem value={2.33}>21:9</MenuItem>
              <MenuItem value={1}>1:1</MenuItem>
            </Select>
          </FormControl>
          <Box pb={2} />
          <TextField
            fullWidth
            variant='outlined'
            label={data.from === 'facebook' ? 'Embed Code' : 'URL'}
            value={data.value}
            onChange={handleChange('value')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button onClick={handleOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
})
