// import React, { useContext } from 'react'
import React from 'react'

import { PanelItem } from '../PanelContainer'
import { Container } from '../../Container'
import { VideoDisplay } from '../../VideoDisplay'
// import { ContentEditContext } from '../context'

import { DialogChange } from './dialog.change'

export const ContentVideo = ({ content, index, ...props }) => {
  // const { updateContent } = useContext(ContentEditContext)

  return (
    <PanelItem
      id={content.key}
      index={index}
      actions={<DialogChange content={content} index={index} />}
    >
      <VideoDisplay content={content} />
    </PanelItem>
  )
}
