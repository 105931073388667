import { faSignIn } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  Slide,
  makeStyles,
  withStyles
} from '@material-ui/core'
import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState
} from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { KGContext } from '../KGProvider'
import { MainContaxt } from './context'

const useStyles = makeStyles((theme) => ({
  main: {
    '& .firebaseui-container': {
      boxShadow: 'none'
    }
  }
}))

const avatarSize = 128
const Header = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    paddingTop: 'calc(100% / 3)',
    position: 'relative',
    marginBottom: avatarSize / 2
  },
  avatar: {
    position: 'absolute',
    bottom: 0 - Math.floor(avatarSize / 2),
    left: `calc(50% - ${avatarSize / 2}px)`,
    width: avatarSize,
    height: avatarSize,
    color: theme.palette.primary.main
  }
}))(({ classes, ...props }) => {
  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar} />
    </div>
  )
})

const Transition = forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
))

export const ProfileNotSign = (props) => {
  const classes = useStyles()
  const { firebase, ...store } = useContext(KGContext)
  const { open, handleOpen } = useContext(MainContaxt)
  const [uiConfig, setUIConfig] = useState(null)

  const handleSetOpen = (o) => () => handleOpen('signin', o)()

  useEffect(() => {
    setUIConfig({
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false
      }
    })
  }, [])

  return (
    <Fragment>
      <IconButton
        edge='end'
        style={{ width: 48 }}
        onClick={handleSetOpen(true)}
      >
        <FontAwesomeIcon icon={faSignIn} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={open.signin}
        onClose={handleSetOpen(false)}
        TransitionComponent={Transition}
      >
        <Header />
        <Box className={classes.main} p={2}>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </Box>
      </Dialog>
    </Fragment>
  )
}
