import React, { Fragment, useContext } from 'react'
import { QuizEditorContext, connectQuizEdit } from './context'
import { OptionsMatching } from './matching'
import { OptionsMultiple } from './multiple'
import { Question } from './question'
import propTypes from 'prop-types'

import { QuestionType } from './question.type'
import { OptionsTrueFalse } from './truefalse'
import { OptionsSorting } from './sorting'

export const QuizEditor = connectQuizEdit((props) => {
  const store = useContext(QuizEditorContext)
  const [state] = store.state
  const [data] = store.data

  return (
    <Fragment>
      <QuestionType />
      {data.type && <Question />}
      {data.type === 'multiple' && <OptionsMultiple />}
      {data.type === 'truefalse' && <OptionsTrueFalse />}
      {data.type === 'matching' && <OptionsMatching />}
      {data.type === "sorting" && <OptionsSorting />}
    </Fragment>
  )
})
QuizEditor.propTypes = {
  data: propTypes.array.isRequired,
}