import { Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Container = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    },
    '& .public-DraftStyleDefault-block': {
      margin: 0,
      '&:not(:las-child)': {
        marginBottom: '1em'
      }
    }
  }
}))(({ classes, type, ...props }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {type && (
        <Typography
          variant='h6'
          color='textPrimary'
          style={{ marginBottom: 16 }}
        >
          {t(`Question.D${type}`)}
        </Typography>
      )}
      <div className={classes.root} {...props} />
    </React.Fragment>
  )
})
