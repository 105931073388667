import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ImageDisplay } from './image.display'
import { ViewParagraph } from './view.paragraph'

export const Question = ({ value }) => {
  switch (value.qtype) {
    case 'image':
      return (
        <React.Fragment>
          <ImageDisplay image={value.question} />
          <Box mb={2} />
        </React.Fragment>
      )
    default:
      return (
        <React.Fragment>
          <ViewParagraph value={value.question} />
          <Box mb={2} />
        </React.Fragment>
      )
  }
}
