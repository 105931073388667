import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Slide
} from '@material-ui/core'
import React, { useState } from 'react'
import { ActionIcon } from '../../../ActionIcon'
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons'
import { injectPageEdit } from '../../edit.context'
import update from 'react-addons-update'

export const GridSize = injectPageEdit(
  ({
    store: {
      datas: [, setData]
    },
    content = {},
    index,
    ...props
  }) => {
    const [state, setState] = useState({
      open: false,
      anchor: null,
      size: ''
    })

    const handleOpen = (open) => () => setState((s) => ({ ...s, open }))
    const handleMenuOpen =
      (size) =>
      ({ currentTarget }) =>
        setState((s) => ({ ...s, size, anchor: currentTarget }))
    const handleMenuClose = () =>
      setState((s) => ({ ...s, size: '', anchor: null }))
    const handleChangeSize = (value) => () => {
      setData((d) =>
        update(d, { contents: { [index]: { [state.size]: { $set: value } } } })
      )
      setState((s) => ({ ...s, anchor: null, size: '' }))
    }

    return (
      <React.Fragment>
        <ListItem button onClick={handleOpen(true)}>
          <ListItemText primary='Size' />
          <ListItemSecondaryAction>
            <ActionIcon icon={faChevronRight} onClick={handleOpen(true)} />
          </ListItemSecondaryAction>
        </ListItem>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={state.open}
          onClose={handleOpen(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: 'right' }}
        >
          <DialogTitle>Size</DialogTitle>
          <DialogContent>
            <List>
              {[
                { label: 'Small', var: 'sm' },
                { label: 'Medium', var: 'md' },
                { label: 'Large', var: 'lg' },
                { label: 'Extra-large', var: 'xl' }
              ].map((item) => (
                <ListItem key={item.var}>
                  <ListItemText primary={item.label} />
                  <ListItemSecondaryAction>
                    <Button
                      size='small'
                      variant='outlined'
                      onClick={handleMenuOpen(item.var)}
                    >
                      {content[item.var]
                        ? ((content[item.var] / 12) * 100).toFixed(0) + '%'
                        : 'None'}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Menu
          open={Boolean(state.anchor)}
          anchorEl={state.anchor}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleChangeSize(null)}>None</MenuItem>
          {[...Array(12).keys()].map((num) => (
            <MenuItem key={num} onClick={handleChangeSize(num + 1)}>
              {(((num + 1) / 12) * 100).toFixed(0)}%
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )
  }
)
