import React, { useContext, useEffect } from 'react'
import { Breadcrumbs, Link, Typography, withStyles } from '@material-ui/core'
import { KGContext } from '../KGProvider'
import propTypes from 'prop-types'

export const ContentHeader = withStyles((theme) => ({
  rootContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: ({ bottom }) => theme.spacing(bottom || 6)
  },
  breadcrumbs: {
    fontSize: theme.typography.caption.fontSize,
  },
  content: {
    flex: 1
  },
  actions: {}
}))(({ classes, label, breadcrumbs, actions, secondary, ...props }) => {
  const store = useContext(KGContext)

  useEffect(() => {
    if (!store.Link) {
      console.error('Please defined "Link" in config')
    }
  }, [store.Link])

  return (
    <div className={classes.rootContentHeader}>
      <div className={classes.content}>
        {Boolean(breadcrumbs && breadcrumbs.length) && (
          <div style={{marginBottom:'0.5em'}}>
            <Breadcrumbs className={classes.breadcrumbs} separator="|">
              {breadcrumbs.map((item, index) => {
                if (item.to) {
                  return (
                    <Link component={store.Link} to={item.to} key={index}>
                      <Typography
                        variant='caption'
                        color='inherit'
                        style={{ fontWeight: 'bold' }}
                      >
                        {item.label}
                      </Typography>
                    </Link>
                  )
                } else {
                  return (
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      key={index}
                    >
                      {item.label}
                    </Typography>
                  )
                }
              })}
            </Breadcrumbs>
          </div>
        )}
        <Typography
          variant='h3'
          color={label ? 'textPrimary' : 'textSecondary'}
        >
          {label || 'No title'}
        </Typography>
        {secondary && (
          <Typography variant='caption' color='textSecondary' component="div" style={{paddingTop:8}}>
            {secondary}
          </Typography>
        )}
      </div>
      {Boolean(actions) && <div className={classes.actions}>{actions}</div>}
    </div>
  )
})
ContentHeader.propTypes = {
  secondary: propTypes.node,
}