import React, { useContext, useState } from 'react'
import propTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@material-ui/core'
import { faPaperclip } from '@fortawesome/pro-duotone-svg-icons'
import { FilePicker } from '../FilePicker'
import { Paragraph } from '../Paragraph'
import { useTranslation } from 'react-i18next'
import { ContextViewCourse } from './context'
import moment from 'moment'
import { DuotoneButton } from '../DuotoneButton'
import { SkeletonController } from '../functions'
import { KGContext } from '../KGProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import update from 'react-addons-update'

const parseTime = (time) => {
  const [yyyy, mm, dd] = time?.split('-')?.map((num) => parseFloat(num)) ?? [
    null,
    null,
    null
  ]
  if (
    typeof yyyy === 'number' &&
    typeof mm === 'number' &&
    typeof dd === 'number'
  ) {
    return new Date(yyyy, mm - 1, dd, 23, 59, 59, 0).getTime()
  } else {
    return Date.now()
  }
}

export const SubjectiveDialog = ({ open, index, onClose, ...props }) => {
  const studentID = useContext(ContextViewCourse)?.student?.studentID
  const {
    data: [data, setData]
  } = useContext(ContextViewCourse)
  const lesson = data?.lessons?.[index]
  const { firebase } = useContext(KGContext)
  const { t } = useTranslation()
  const [state, setState] = useState({
    value: null,
    file: null
  })

  const handleSend = async () => {
    if (lesson.id && studentID) {
      const control = new SkeletonController(firebase)
      await control.path('courses', lesson.id).update({
        [studentID]: {
          ...(lesson?.[studentID] ?? {}),
          student: { value: state?.value, file: state?.file, date: new Date() }
        }
      })
      setData((d) =>
        update(d, {
          lessons: {
            [index]: {
              [studentID]: {
                $set: {
                  student: {
                    value: state?.value,
                    file: state?.file,
                    date: new Date()
                  }
                }
              }
            }
          }
        })
      )
    }
  }

  const Question = (isLate = false) => (
    <React.Fragment>
      <Paragraph value={lesson.question} />
      <Typography variant='caption' color={isLate ? 'error' : 'textSecondary'}>
        {t('DateDue') + ' ' + moment(lesson.duedate).format('LL')}
        {isLate && ' - Lated'}
      </Typography>
      <Box mb={3} />
    </React.Fragment>
  )
  const CloseButton = <Button onClick={onClose}>{t('Close')}</Button>
  const display = () => {
    const duedate = parseTime(lesson?.duedate)
    const currentDate = Date.now()
    const isLate = currentDate > duedate

    if (!Boolean(lesson?.[studentID]?.student)) {
      return (
        <React.Fragment>
          <DialogContent>
            {Question(isLate)}
            {!Boolean(lesson?.[studentID]?.student) && (
              <React.Fragment>
                <Paragraph
                  editOnly
                  value={state?.value ?? null}
                  onChange={(value) => setState((s) => ({ ...s, value }))}
                />
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <FilePicker
              onConfirm={([file]) => setState((s) => ({ ...s, file }))}
            >
              <DuotoneButton icon={faPaperclip}>
                {state?.file?.content?.name ?? t('AttachFile')}
              </DuotoneButton>
            </FilePicker>
            <Box flex={1} />
            <Button
              color='primary'
              onClick={handleSend}
              disabled={!Boolean(state?.value)}
            >
              {t('Send')}
            </Button>
            {CloseButton}
          </DialogActions>
        </React.Fragment>
      )
    } else if (Boolean(lesson?.[studentID]?.student)) {
      const student = lesson?.[studentID]?.student
      const isSendLate = duedate > student?.date?.['_seconds']
      return (
        <React.Fragment>
          <DialogContent>
            {Question()}
            <Typography variant='body1'>
              <b>Your answer</b>
            </Typography>
            <Paragraph value={student?.value ?? null} />
            <Typography
              variant='caption'
              color={isSendLate ? 'error' : 'textSecondary'}
              paragraph
            >
              {moment(student?.date).format('LL')}{isSendLate && ' - Lated'}
            </Typography>
            {student?.file && (
              <Link
                variant='caption'
                color='textSecondary'
                href={student?.file?.content?.original}
                target='_blank'
              >
                <FontAwesomeIcon icon={faPaperclip} />
                &nbsp;
                {student?.file?.content?.name}
              </Link>
            )}
            <Box mt={3}>
              <Typography variant='body2' color='textSecondary'>
                Status:{' '}
                {lesson?.[studentID]?.status === 'complete'
                  ? 'Completed'
                  : 'Pending for review'}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>{CloseButton}</DialogActions>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  return studentID ? (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle>{lesson.title}</DialogTitle>
      {display()}
    </Dialog>
  ) : null
}
SubjectiveDialog.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  index: propTypes.number.isRequired
}
