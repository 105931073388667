import React, { useContext } from 'react'
import { Panel } from './panel'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { QuizEditorContext } from './context'
import { useTranslation } from 'react-i18next'

const lists = [
  { label: 'MultipleChoice', value: 'multiple' },
  { label: 'TrueFalse', value: 'truefalse' },
  { label: 'Matching', value: 'matching' },
  { label: 'Sorting', value: 'sorting' }
]

export const QuestionType = (props) => {
  const { t } = useTranslation()
  const { genKey, ...store } = useContext(QuizEditorContext)
  const [, setState] = store.state
  const [data, setData] = store.data

  const handleChangeType = ({ target }) => {
    const type = target.value
    const firstKey = genKey()
    const secondKey = genKey()
    let options =
      {
        multiple: [
          { key: firstKey, value: null, type: 'paragraph' },
          { key: secondKey, value: null, type: 'paragraph' },
          { key: genKey(), value: null, type: 'paragraph' },
          { key: genKey(), value: null, type: 'paragraph' },
        ],
        sorting: [
          { key: firstKey, value: null, type: 'paragraph' },
          { key: secondKey, value: null, type: 'paragraph' }
        ]
      }[type] || null
    let answers =
      {
        truefalse: true,
        matching: [
          {
            key: firstKey,
            label: null,
            type: 'paragraph',
            value: '',
            data: null
          },
          {
            key: secondKey,
            label: null,
            type: 'paragraph',
            value: '',
            data: null
          }
        ],
        sorting: [firstKey, secondKey]
      }[type] || null
    setData((d) => ({ type, options, answers }))
    setState((s) => ({ ...s, tab: 'question' }))
  }

  return (
    <Panel
      id='type'
      title={
        <React.Fragment>
          {t('Question.QuestionType')}
          {data.type &&
            ` (${t(
              `Question.${
                Object.assign(
                  {},
                  ...lists.map((v) => ({ [v.value]: v.label }))
                )[data.type]
              }`
            )})`}
        </React.Fragment>
      }
    >
      <FormControl fullWidth variant='outlined'>
        <InputLabel id='question-type-label'>
          {t('Question.QuestionType')}
        </InputLabel>
        <Select
          labelWidth={108}
          labelId='question-type-label'
          value={lists.map((l) => l.value).includes(data.type) ? data.type : ''}
          onChange={handleChangeType}
        >
          <MenuItem value='' disabled>
            -- {t('Question.QuestionType')} --
          </MenuItem>
          {lists.map((list) => (
            <MenuItem value={list.value} key={list.value}>
              {t(`Question.${list.label}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Panel>
  )
}
