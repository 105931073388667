import React, { createContext, useContext, useState } from 'react'
import { KGContext } from '../KGProvider'

const Ctx = createContext({})

const defaultData = {
  title: '',
  cover: null
}
const Pvd = ({ children, ...props }) => {
  const { user, t } = useContext(KGContext)
  const [data, setData] = useState({ ...defaultData })

  const handleChangeEvent =
    (key) =>
    ({ target: { value } }) =>
      setData((d = { ...d, [key]: value }))
  const handleChangeValue = (key) => (value) =>
    setData((d) => ({ ...d, [key]: value }))
  const handleSave = async () => {}

  const store = {
    ...props,
    datas: [data, setData],
    handleChangeEvent,
    handleChangeValue,
    handleSave,
    user,
    t
  }
  return <Ctx.Provider value={store}>{children}</Ctx.Provider>
}

export const connectPostEdit = (Comp) => (props) =>
  (
    <Pvd {...props}>
      <Comp {...props} />
    </Pvd>
  )

export const usePostEdit = () => useContext(Ctx)
