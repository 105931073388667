import React, { useContext } from 'react'

import {
  Accordion,
  AccordionActions,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Typography,
  withStyles
} from '@material-ui/core'
import { QuizEditorContext } from './context'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons'

const ExpandIcon = () => <FontAwesomeIcon size='xs' icon={faChevronDown} />

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)

export const Panel = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxShadow: 'none'
  }
}))(({ classes, title, actions, children, id, ...props }) => {
  const {
    state: [state, setState],
    data: [data]
  } = useContext(QuizEditorContext)
  const currentTab = data?.type ? state.tab : 'type'

  const handleChangeTab = () => setState((s) => ({ ...s, tab: id }))

  return (
    <Accordion
      className={classes.root}
      expanded={currentTab === id}
      onChange={handleChangeTab}
    >
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography variant='h6'>
          <strong>{title}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box flex={1}>{children}</Box>
      </AccordionDetails>
      {actions && <AccordionActions>{actions}</AccordionActions>}
    </Accordion>
  )
})
