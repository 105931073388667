import { IconButton, withStyles } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'

export const CardButton = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  icon: {
    width: '100%',
    height: '100%',
    fontSize: theme.spacing(8),
    borderRadius: theme.shape.borderRadius,
  }
}))(({ classes: cs, icon, onClick, ...props }) => {
  return (
    <div className={cs.root}>
      <IconButton className={cs.icon} onClick={onClick}>{icon}</IconButton>
    </div>
  )
})
CardButton.propTypes = {
  icon: propTypes.node.isRequired,
  onClick: propTypes.func.isRequired
}
