import React, {
  Fragment,
  forwardRef,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Grow,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { KGContext } from '../../KGProvider'
import { ContentEditContext } from '../context'

const Transition = forwardRef((props, ref) => <Grow ref={ref} {...props} />)

const defaultData = {
  category: '',
  amount: 10
}
export const DialogEdit = ({ content, open, onClose, onConfirm, ...props }) => {
  const { user, firebase } = useContext(KGContext)
  const { config, ...store } = useContext(ContentEditContext)
  const [state, setState] = store.state
  const [data, setData] = useState({ ...defaultData })

  const handleClose = () => onClose && onClose()
  const handleChange =
    (key) =>
    ({ target }) =>
      setData((s) => ({ ...s, [key]: target.value }))
  const handleConfirm = () => {
    if (onConfirm) {
      let value = state.cats.find((cat) => cat.id === data.category)
      if (value) {
        onConfirm(value, data.amount)
        onClose()
      }
    }
  }
  const handleReset = () => setData({ ...defaultData });

  useEffect(() => {
    if (firebase.apps.length && user && !state.quizFetched) {
      firebase
        .firestore()
        .collection('questions')
        .where('user', '==', user.uid)
        .where('type', 'in', [`${config.prefix}category`])
        .get()
        .then((snapshot) => {
          let cats = snapshot.docs
            .map((doc) => ({
              ...doc.data(),
              id: doc.id
            }))
            .sort((a, b) => {
              let getLabel = (c) => c.label || c.title
              return getLabel(a).localeCompare(getLabel(b))
            })
          setState((s) => ({ ...s, quizFetched: true, cats }))
        })
    }
  }, [firebase.apps, user, config, state.quizFetched])

  useEffect(() => {
    if (content.value && state.quizFetched && open) {
      setData((s) => ({
        ...s,
        category: content.value.id,
        amount: content.amount
      }))
    }
  }, [content, open, state.quizFetched])

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={Boolean(open)}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>Change</DialogTitle>
      <DialogContent>
        {state.quizFetched ? (
          <Fragment>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='bank-id'>Question Bank</InputLabel>
              <Select
                labelId='bank-id'
                labelWidth={108}
                value={data.category}
                onChange={handleChange('category')}
              >
                {state.cats.map((cat) => (
                  <MenuItem value={cat.id} key={cat.id}>
                    {cat.label || cat.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt={2} />
            <TextField
              type='number'
              fullWidth
              label='Amount'
              variant='outlined'
              value={data.amount}
              onChange={handleChange('amount')}
              helperText='เลือกจำนวนข้อ'
              min={0}
            />
          </Fragment>
        ) : (
          <Skeleton />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>Reset</Button>
        <Box flex={1} />
        <Button
          color='primary'
          disabled={!Boolean(data.category && data.amount > 0)}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
