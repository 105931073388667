import React, { forwardRef } from 'react'
import {
  Box,
  Container,
  Slide,
  Typography,
  withStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock } from '@fortawesome/pro-duotone-svg-icons'
import { DateToStr } from './functions'

export const Transition = forwardRef((props, ref) => (
  <Slide ref={ref} direction='down' {...props} />
))

export const FeatureTypography = withStyles((theme) => ({
  root: {
    ...theme.typography.h1,
    [theme.breakpoints.between('lg', 'xl')]: {
      ...theme.typography.h2
    },
    [theme.breakpoints.between('md', 'lg')]: {
      ...theme.typography.h3
    },
    [theme.breakpoints.between('sm', 'md')]: {
      ...theme.typography.h4
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h5
    }
  }
}))(({ classes, children, typographyProps, ...props }) => {
  return (
    <Container maxWidth='sm'>
      <Typography className={classes.root} component='div' {...typographyProps}>
        {children}
      </Typography>
    </Container>
  )
})

export const ShowDate = ({ data }) => {
  return (
    <Box display='flex' alignItems='center'>
      <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '0.5em' }} />
      {DateToStr(data, 'L')}
      <Box children={`|`} mx={1} />
      <FontAwesomeIcon icon={faClock} style={{ marginRight: '0.5em' }} />
      {DateToStr(data, 'LT')}
    </Box>
  )
}

export const ListItemTextLimit = withStyles((theme) => ({
  root: {
    '& .MuiListItemText-primary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical'
    }
  }
}))(({ classes, ...props }) => (
  <ListItemText className={classes.root} {...props} />
))