import React, { useContext } from 'react'
import { Panel } from './panel'
import { Box, Button, MenuItem, Select, Typography } from '@material-ui/core'
import { QuizEditorContext } from './context'

import { Paragraph } from '../Paragraph'
import { ImageDisplay } from '../ImageDisplay'
import { ImagePicker } from '../ImagePicker'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons'
import { useTranslation } from 'react-i18next'

export const Question = (props) => {
  const { t } = useTranslation()
  const store = useContext(QuizEditorContext)
  const [data, setData] = store.data

  const handleChangeQType = ({ target }) =>
    setData((d) => ({ ...d, qtype: target.value, question:null }))
  const handleChangeImage = ([img]) => {
    if (img) {
      const { content, id } = img
      setData((s) => ({ ...s, question: { ...content, id } }))
    }
  }
  const handleChangeParagraph = (value) =>
    setData((s) => ({ ...s, question: value || null }))

  return (
    <Panel id='question' title={t('Question.Question')}>
      <Box display='flex' justifyContent='flex-end' alignItems='center' mb={2}>
        <Typography variant='caption' color='textSecondary'>
          {t('Type')}:&nbsp;
        </Typography>
        <Select
          value={
            ['paragraph', 'image'].includes(data.qtype)
              ? data.qtype
              : 'paragraph'
          }
          onChange={handleChangeQType}
        >
          <MenuItem value='paragraph'>{t('Paragraph')}</MenuItem>
          <MenuItem value='image'>{t('Image')}</MenuItem>
        </Select>
      </Box>
      {(() => {
        switch (data.qtype) {
          case 'image':
            return (
              <Box textAlign='center'>
                {data.question && (
                  <Box mb={2}>
                    <ImageDisplay image={data.question} />
                  </Box>
                )}
                <ImagePicker onConfirm={handleChangeImage}>
                  <Button
                    variant='outlined'
                    startIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                  >
                    {t('Browse')}
                  </Button>
                </ImagePicker>
              </Box>
            )
          default:
            return (
              <Paragraph
                editOnly
                value={data.question}
                onChange={handleChangeParagraph}
              />
            )
        }
      })()}
    </Panel>
  )
}
