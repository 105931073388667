import React, { Fragment, useContext } from 'react'
import { AppBar, Toolbar } from './AppBar'
import { LeftSidebar, LeftSidebarToggle } from './LeftSidebar'
import { Contain } from './Contain'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { RightBar } from './RightBar'
import { Profile } from './Profile'
import { connectMain, MainContaxt } from './context'
import { KGContext } from '../KGProvider'
import { Alerts } from '../Alerts'
import { LogoLink } from './logo.link'

import { MCTNLoading } from './loading'
import { AppCollect } from './app.collect'
import { EnhanceSignInOnly } from './sign.in.only'
import propTypes from 'prop-types'
import { AccessDenied } from './access.denied/'

export const MainContainer = connectMain((props) => {
  const { restrict, signInOnly } = props
  const { user, ...store } = useContext(KGContext)
  const { rightBar } = useContext(MainContaxt)
  const [state] = store.state

  if (!Object.keys(store).length) throw new Error('Please use "connectKG"')

  let content = null

  if (restrict) {
    content = <AccessDenied />
  } else if (signInOnly && !user) {
    content = (
      <div>
        <Toolbar />
        <Box textAlign='center' py={12}>
          {state.userFetched ? <EnhanceSignInOnly /> : <CircularProgress />}
        </Box>
        <Alerts />
      </div>
    )
  } else {
    content = (
      <Fragment>
        <Contain>
          {props.loading ? <MCTNLoading dense={props.dense} /> : props.children}
        </Contain>
        <LeftSidebar />
        {rightBar && <RightBar />}
      </Fragment>
    )
  }

  return (
    <div>
      {content}
      <AppBar>
        <Toolbar>
          <LeftSidebarToggle />
          <LogoLink />
          {store.startActions}
          {props.startActions}
          <Box flex={1} />
          {store.endActions}
          {props.endActions}
          <AppCollect />
          <Profile />
        </Toolbar>
      </AppBar>
      <Alerts />
    </div>
  )
})
MainContainer.propTypes = {
  protection: propTypes.bool,
  dense: propTypes.bool,
  loading: propTypes.bool,
  sidebar: propTypes.node,
  startAction: propTypes.node,
  endAction: propTypes.node
}
