import React from 'react'
import propTypes from 'prop-types'
import { Button, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolderOpen,
  faPlus,
  faSave,
  faSignOut,
  faTrash
} from '@fortawesome/pro-duotone-svg-icons'

const FAicon = withStyles((theme) => ({
  root: {
    ...theme.palette.duotone
  }
}))(({ classes: cs, ...props }) => (
  <FontAwesomeIcon className={cs.root} {...props} />
))

function KuiButton(props) {
  const { children, tx, ...others } = props
  const { t } = useTranslation()

  let btnProps

  switch (tx) {
    case 'add':
      btnProps = {
        variant: 'outlined',
        startIcon: <FAicon icon={faPlus} />,
        children: children || t('Add')
      }
      break
    case 'bin':
      btnProps = {
        variant: 'outlined',
        startIcon: <FAicon icon={faTrash} />,
        children: children || t('Trash')
      }
      break
    case 'browse':
      btnProps = {
        variant: 'outlined',
        startIcon: <FAicon icon={faFolderOpen} />,
        component: 'span',
        children: children || t('Browse')
      }
      break
    case 'cancel':
      btnProps = {
        children: children || t('Cancel')
      }
      break
    case 'clear':
      btnProps = {
        color: 'secondary',
        children: children || t('Clear')
      }
      break
    case 'confirm':
      btnProps = {
        color: 'primary',
        children: children || t('Confirm')
      }
      break
    case 'close':
      btnProps = {
        children: children || t('Close')
      }
      break
    case 'remove':
      btnProps = {
        startIcon: <FontAwesomeIcon icon={faTrash} />,
        color: 'secondary',
        children: children || t('Remove')
      }
      break
    case 'save':
      btnProps = {
        variant: 'contained',
        startIcon: <FontAwesomeIcon icon={faSave} />,
        color: 'primary',
        children: children || t('Save')
      }
      break
    case 'signout':
      btnProps = {
        startIcon: <FontAwesomeIcon icon={faSignOut} />,
        color: 'secondary',
        children: children || t('SignOut')
      }
      break
    default:
      btnProps = {}
      break
  }
  return <Button {...others} {...btnProps} />
}
KuiButton.propTypes = {
  tx: propTypes.oneOf([
    'add',
    'bin',
    'browse',
    'cancel',
    'clear',
    'confirm',
    'close',
    'remove',
    'save',
    'signout'
  ]),
  children: propTypes.node,
  tsx: propTypes.string
}

export { KuiButton }
