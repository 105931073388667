import React, { useContext } from 'react'
import { PanelItem } from './PanelContainer'

import { Paragraph } from '../Paragraph'
import { ContentEditContext } from './context'
import { Box } from '@material-ui/core'

export const ContentParagraph = ({ content, index, ...props }) => {
  const { updateContent, width } = useContext(ContentEditContext)

  const handleChange = (value) => {
    updateContent(index, 'value', value)
  }

  console.log(width)

  return (
    <PanelItem id={content.key} index={index}>
      {["xs","sm"].includes(width) && <Box pb={6} />}
      <Paragraph editable value={content.value} onChange={handleChange} />
    </PanelItem>
  )
}
