import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Container } from '../Container'
import { ContentHeader } from '../ContentHeader'
import { Backdrop, Box, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export const MCTNLoading = (props) => {
  const {t} = useTranslation()
  return (
    <Box py={props.dense ? 6 : 0}>
      <Container maxWidth='sm'>
        <ContentHeader
          label={<Skeleton width='50%' />}
          secondary={<Skeleton width='30%' />}
        />
        <Backdrop open={true} style={{zIndex:9999}}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{color:'white'}}>
            <CircularProgress color="inherit" />
            <Box py={1} />
            {t("PleaseWait")}
          </Box>
        </Backdrop>
      </Container>
    </Box>
  )
}
