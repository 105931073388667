import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const ViewTypeHeader = ({ content }) => {
  return (
    <Box textAlign={content.textAlign || 'left'}>
      <Typography
        variant={content.variant || 'h6'}
        style={{ whiteSpace: 'pre' }}
      >
        {content.value}
      </Typography>
    </Box>
  )
}
