import React, { useContext, useRef } from 'react'
import { withStyles } from '@material-ui/core'
import Swiper from 'react-id-swiper'
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs
} from 'swiper'
import { ContextSlide, connectSlide } from './slide.ctx'
import { SlideItem } from './slide.item'
import { SwiperAdd } from './swiper.add'
import { PanelItem } from '../PanelContainer'
import { DialogRemove } from '../../DialogRemove'

SwiperCore.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs
])

export const ContentSlide = connectSlide(
  withStyles((theme) => ({
    root: {
      width: '100%',
      position: 'relative',
      '&:before': {
        content: "''",
        display: 'block',
        paddingTop: 'calc(100% * 1 / 3)'
      },
      [theme.breakpoints.down('md')]: {
        '&:before': {
          paddingTop: '100%'
        }
      }
    },
    content: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      '& .swiper-container': {
        width: '100%',
        height: '100%',
        position: 'relative',
        listStyle: 'none',
        overflow: 'hidden',
        zIndex: 1
      },
      '& .swiper-wrapper': {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        zIndex: 1
      },
      '& .swiper-slide': {
        width: '100%',
        height: '100%',
        flexShrink: 0,
        backgroundImage: theme.palette.background.gradient
      },
      '& .swiper-pagination': {
        bottom: 0,
        width: '100%',
        padding: 4,
        position: 'absolute'
      },
      '& .swiper-button-next, & .swiper-button-prev': {
        color: 'white'
      },
      '& .swiper-pagination-bullet-active': {
        background: 'white'
      }
    }
  }))(({ classes, ...props }) => {
    const { params, content, index, handleRemove, updateContent, ...store } =
      useContext(ContextSlide)
    const [state] = store.state

    const children = Array.isArray(content.values)
      ? content.values.map((slide) => (
          <SlideItem
            {...slide}
            key={slide.key}
            onRemove={handleRemove(slide)}
          />
        ))
      : []

    const handleRemoveConfirm = () => {
      if(state.remove){
        const values = content.values.filter(v=>v.key!==state.remove.key);
        updateContent(index,'values',values);
        handleRemove(null)();
      }
    }

    return (
      <PanelItem id={content.key} content={content} index={index}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Swiper {...params}>
              {children}
              <SwiperAdd />
            </Swiper>
          </div>
        </div>
        <DialogRemove
          title='Remove Slide'
          label='Do you want to remove this slide?'
          open={Boolean(state.remove)}
          onClose={handleRemove(null)}
          onConfirm={handleRemoveConfirm}
        />
      </PanelItem>
    )
  })
)
