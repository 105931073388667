import React, { useContext, useState } from 'react'
import { ListItemIcon, ListItemText } from '@material-ui/core'

import { PanelItem } from '../PanelContainer'
import { Container } from '../../Container'
import { ListItemButton } from '../../ListItemButton'
import { DialogEdit } from './dialog.edit'
import { ContentEditContext } from '../context'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListOl } from '@fortawesome/pro-duotone-svg-icons'

export const ContentQuiz = ({ index, content, ...props }) => {
  const { updateContent } = useContext(ContentEditContext)
  const [open, setOpen] = useState(false)

  const handleOpen = (op) => () => setOpen(op)
  const handleConfirm = (value, amount) => {
    updateContent(index, 'value', value)
    updateContent(index, 'amount', amount)
  }

  return (
    <PanelItem index={index} content={content}>
      <ListItemButton onClick={handleOpen(true)}>
        <ListItemIcon>
          <FontAwesomeIcon size='2x' icon={faListOl} />
        </ListItemIcon>
        {content.value ? (
          <ListItemText
            primary={content.value.label || content.value.title}
            secondary={`จำนวน ${content.amount} ข้อ`}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        ) : (
          <ListItemText
            primary='Please select quiz'
            primaryTypographyProps={{ color: 'textSecondary' }}
          />
        )}
      </ListItemButton>
      <DialogEdit
        content={content}
        open={open}
        onClose={handleOpen(false)}
        onConfirm={handleConfirm}
      />
    </PanelItem>
  )
}
