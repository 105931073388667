import { withStyles } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useContext } from 'react'
import { ContentBody } from './content.body'
import { Context } from './context'

export const Content = withStyles((theme) => ({
  root: {},
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 0, 6)
  }
}))(({ classes, ...props }) => {
  const store = useContext(Context)
  const [state] = store.state

  const handleChangePage = (e, n) => store.changePage(n)

  return (
    <div className={classes.root}>
      <ContentBody />
      <div className={classes.pagination}>
        <Pagination
          color='primary'
          page={parseInt(state.open)}
          count={state.pages.length}
          onChange={handleChangePage}
        />
      </div>
    </div>
  )
})
