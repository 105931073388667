import {
  Divider,
  List,
  ListItem,
  ListItemText as OListItemText
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import propsTypes from 'prop-types'

export const ListItemText = (props) => (
  <OListItemText
    primaryTypographyProps={{ variant: 'body1', style: { fontWeight: 'bold' } }}
    secondaryTypographyProps={{ variant: 'caption' }}
    {...props}
  />
)

export const ListDocs = ({ fetched, docs, component, divider, ...props }) => {
  return (
    <List disablePadding>
      {divider && <Divider />}
      {fetched ? (
        Boolean(docs.length) ? (
          docs.map((doc, index) => component(doc, index))
        ) : (
          <ListItem divider={divider}>
            <ListItemText secondary={'no item'} />
          </ListItem>
        )
      ) : (
        <ListItem divider={divider}>
          <ListItemText primary={<Skeleton width='50%' />} />
        </ListItem>
      )}
    </List>
  )
}
ListDocs.propTypes = {
  fetched: propsTypes.bool.isRequired,
  docs: propsTypes.array.isRequired,
  component: propsTypes.func.isRequired
}
