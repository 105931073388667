import { Box, Typography, withStyles, lighten } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { CETableContext } from './context'
import { Container } from '../../Container'
import clsx from 'clsx'
import { DataView } from './data.view'
import { EditCell } from './edit.cell'

const getChar = (n) => String.fromCharCode(65 + n)

const Cell = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: ({ selected }) =>
      selected ? lighten(theme.palette.primary.main, 0.85) : 'white',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    border: `solid 1px ${theme.palette.grey[300]}`,
    userSelect: 'none'
  }
}))(({ classes, className, head, ...props }) => {
  const newProps = {
    ...props,
    className: clsx(className, classes.root)
  }
  return head ? <th {...newProps} /> : <td {...newProps} />
})

const LabelCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey[300]}`,
    backgroundColor: ({ selected }) => theme.palette.grey[selected ? 200 : 50],
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  }
}))(({ classes, selected, ...props }) => (
  <td className={classes.root} {...props} />
))

export const TableEdit = withStyles((theme) => ({
  root: {
    overflowX: 'auto'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    boxSizing: 'border-box'
  }
}))(({ classes, ...props }) => {
  const {
    content,
    index,
    updateContent,
    addAlert,
    select: [selected, setSelected],
    states: [state, setState]
  } = useContext(CETableContext)

  const isSelect = (col, row) => {
    if (selected.col && !selected.row) {
      return col === selected.col
    } else if (!selected.col && selected.row) {
      return row === selected.row
    } else {
      return col === selected.col && row === selected.row
    }
  }
  const handleSelect = (col, row) => () => setSelected({ col, row })
  const handleEdit = (data) => () => setState((s) => ({ ...s, cell: data }))
  const handleSaveCell = (data) => {
    let values = { ...content.values }
    values[state.cell] = data
    updateContent(index, 'values', values)
    setState((s) => ({ ...s, cell: null }))
  }

  return (
    <React.Fragment>
      <Box mt={6} />
      {Array.isArray(content.columns) && Array.isArray(content.rows) ? (
        <div className={classes.root}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <LabelCell />
                {content.columns.map((col, index) => (
                  <LabelCell
                    key={col.key}
                    onClick={handleSelect(col.key, '')}
                    selected={selected.col === col.key && !selected.row}
                  >
                    {getChar(index)}
                  </LabelCell>
                ))}
              </tr>
              {content.rows.map((row, index) => (
                <tr key={row.key}>
                  <LabelCell
                    onClick={handleSelect('', row.key)}
                    children={index + 1}
                    selected={selected.row === row.key && !selected.col}
                    style={{ width: 48 }}
                  />
                  {content.columns.map((col) => (
                    <Cell
                      head={row.head || col.head}
                      key={col.key}
                      selected={isSelect(col.key, row.key)}
                      onClick={handleSelect(col.key, row.key)}
                      onDoubleClick={handleEdit(`${col.key}-${row.key}`)}
                    >
                      <DataView {...content.values[`${col.key}-${row.key}`]} />
                    </Cell>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Typography>Invalid data</Typography>
      )}
      <EditCell
        open={Boolean(state.cell)}
        defaultValue={state.cell && content.values[state.cell]}
        onClose={handleEdit(null)}
        onConfirm={handleSaveCell}
      />
    </React.Fragment>
  )
})
