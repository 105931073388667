import { faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { Box, Grid, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../Container'
import { DuotoneButton } from '../DuotoneButton'
import { PageEditAddMenu } from './edit.add'
import { injectPageEdit } from './edit.context'
import { genKey } from 'draft-js'
import update from 'react-addons-update'
import { PanelContainer, PanelItem } from './edit.panel'
import { arrayMove } from 'react-sortable-hoc'

import { EditTypeHeader, EditTypeParagraph } from './comp/edit'
import { ViewTypeHeader, ViewTypeParagraph } from './comp/view'

const Item = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 2px ${theme.palette.primary.light}`
    }
  }
}))((props) => <Grid item {...props} />)

const GridAdd = injectPageEdit(({ store: { t }, onAdd, ...props }) => {
  const [open, setOpen] = useState(null)
  return (
    <Item xs={12}>
      <Box
        flexGrow={1}
        display='flex'
        flexDirection='column'
        alignItems='center'
        p={3}
      >
        <DuotoneButton
          icon={faPlus}
          onClick={({ currentTarget }) => setOpen(currentTarget)}
        >
          {t('Add')}
        </DuotoneButton>
      </Box>
      <PageEditAddMenu
        anchor={open}
        onClose={() => setOpen(null)}
        onAdd={onAdd}
      />
    </Item>
  )
})

export const PageEditBody = injectPageEdit((props) => {
  const {
    datas: [data, setData],
    states: [state, setState]
  } = props.store

  const Body = state.limit ? Container : 'div'
  let bodyProps = {}
  if (state.limit) bodyProps.maxWidth = state.limit

  const handlePush = (type) => {
    setData((d) =>
      update(d, { contents: { $push: [{ type, key: genKey() }] } })
    )
  }
  const handleSortStart = () => setState((s) => ({ ...s, sorting: true }))
  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setData((d) => {
      let contents = arrayMove(d.contents, oldIndex, newIndex)
      return { ...d, contents }
    })
    setState((s) => ({ ...s, focus: newIndex, sorting: false }))
  }
  const handleFocus = (index) => () => setState((s) => ({ ...s, focus: index }))

  return (
    <Body {...bodyProps}>
      <PanelContainer
        useDragHandle
        onSortStart={handleSortStart}
        onSortEnd={handleSortEnd}
      >
        {(data.contents || []).map((content, index) => {
          const { xs = 12, sm, md, lg, xl } = content
          const sizes = state.sorting ? { xs: 12 } : { xs, sm, md, lg, xl }
          return (
            <PanelItem
              index={index}
              i={index}
              key={content.key}
              {...sizes}
              focus={state.focus === index || state.sorting}
              onClick={handleFocus(index)}
            >
              {(() => {
                switch (content.type) {
                  case 'header':
                    return state.focus === index && !state.sorting ? (
                      <EditTypeHeader index={index} content={content} />
                    ) : (
                      <ViewTypeHeader content={content} />
                    )
                  case 'paragraph':
                    return state.focus === index && !state.sorting ? (
                      <EditTypeParagraph index={index} content={content} />
                    ) : (
                      <ViewTypeParagraph content={content} />
                    )
                  default:
                    return content.type
                }
              })()}
            </PanelItem>
          )
        })}
      </PanelContainer>
      <GridAdd onAdd={handlePush} />
      focus:{state.focus}
    </Body>
  )
})
