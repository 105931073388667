import React from 'react'
import { AppBar as OAppBar, Toolbar as OToolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 60,
    maxHeight: 60
  },
  appbar: {
    boxShadow: 'none',
    backgroundColor: 'white',
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  }
}))

export const Toolbar = (props) => {
  const classes = useStyles()
  return <OToolbar className={classes.toolbar} {...props} />
}

export const AppBar = (props) => {
  const classes = useStyles()
  return <OAppBar className={classes.appbar} color='default' {...props} />
}
