import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { Box, Button, MenuItem, TextField, withStyles } from '@material-ui/core'

import { PanelItem } from './PanelContainer'
import { Container } from '../Container'
import { ImageDisplay } from '../ImageDisplay'
import { ImagePicker } from '../ImagePicker'
import { ContentEditContext } from './context'
import { DialogPre } from '../DialogPre'
import { DialogImagePosition } from '../DialogImagePosition'
import { ImageUrl, ImageUrlContent, URLProvider } from './image/url'

const RatioSetting = forwardRef(({ value, onChange, ...props }, ref) => {
  const [data, setData] = useState({
    width: 1,
    height: 1
  })

  const genProps = (key) => ({
    fullWidth: true,
    variant: 'outlined',
    type: 'number',
    value: data[key],
    onChange: handleChange(key),
    style: { marginBottom: 24 }
  })
  const handleChange =
    (side) =>
    ({ target }) =>
      setData((s) => ({ ...s, [side]: target.value }))
  const handleConfirm = () => onChange(data)

  useEffect(() => {
    if (value && value.width && value.height) {
      setData((s) => ({ ...s, width: value.width, height: value.height }))
    }
  }, [value])

  return (
    <DialogPre
      title='Image Ratio'
      button={<MenuItem ref={ref}>Ratio</MenuItem>}
      onConfirm={handleConfirm}
    >
      <TextField {...genProps('width')} label='Width' />
      <TextField {...genProps('height')} label='Height' />
    </DialogPre>
  )
})

const ImageWrapper = withStyles((theme) => ({
  root: {
    position: 'relative',
    cursor: 'pointer'
  },
  select: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)',
    opacity: 0,
    transition: 'all 0.25s',
    '&:hover': {
      opacity: 1
    }
  }
}))(({ classes, content, index, ...props }) => {
  const { updateContent } = useContext(ContentEditContext)

  const getRatio = () => {
    if (content.ratio && content.ratio.width && content.ratio.height) {
      return parseInt(content.ratio.height) / parseInt(content.ratio.width)
    }
    return 1
  }
  const handleConfirm = (imgs) => {
    const [img] = imgs
    if (img) {
      updateContent(index, 'value', { ...img.content, id: img.id })
    }
  }

  return (
    <Box className={classes.root}>
      <ImageDisplay
        image={content.value}
        pos={content.pos}
        ratio={getRatio()}
      />
      <ImagePicker onConfirm={handleConfirm}>
        <Box className={classes.select}>
          <Button variant='contained' disableElevation>
            Select
          </Button>
        </Box>
      </ImagePicker>
    </Box>
  )
})

export const ContentImage = ({ content, index, ...props }) => {
  const { updateContent } = useContext(ContentEditContext)

  const getRatio = () => {
    if (content.ratio && content.ratio.width && content.ratio.height) {
      return parseInt(content.ratio.height) / parseInt(content.ratio.width)
    }
    return 1
  }
  const handleChange = (key) => (value) => updateContent(index, key, value)

  return (
    <URLProvider>
      <PanelItem
        id={content.key}
        index={index}
        actions={[
          <DialogImagePosition
            key='position'
            image={content.value}
            value={content.pos}
            ratio={getRatio()}
            onConfirm={handleChange('pos')}
          />,
          <RatioSetting
            value={content.ratio}
            onChange={handleChange('ratio')}
            key='ratio'
          />,
          <ImageUrl key='url' />
        ]}
      >
        <ImageWrapper content={content} index={index} />
        <ImageUrlContent value={content.url} onChange={handleChange('url')} />
      </PanelItem>
    </URLProvider>
  )
}
