import {
  Box,
  IconButton,
  MenuItem,
  Slider,
  Typography
} from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/pro-duotone-svg-icons'

import { DialogPre } from '../DialogPre'
import { ImageDisplay } from '../ImageDisplay'

export const DialogImagePosition = forwardRef(
  ({ image, ratio, button, value, onConfirm, ...props }, ref) => {
    const [pos, setPos] = useState({
      top: 50,
      left: 50
    })

    const handleChange = (key) => (e, n) => setPos((p) => ({ ...p, [key]: n }));
    const handleConfirm = () => onConfirm && onConfirm(pos);

    useEffect(()=>{
      if(value && value.top && value.left){
        setPos(p=>({
          ...p,
          top: parseInt(value.top),
          left: parseInt(value.left),
        }))
      }
    }, [value])

    return (
      <DialogPre
        title='Image Position'
        maxWidth='sm'
        button={<MenuItem ref={ref}>Position</MenuItem>}
        onConfirm={handleConfirm}
      >
        <ImageDisplay image={image} pos={pos} ratio={ratio} />
        <Box mt={4}>
          <Typography>Top</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <Slider
                value={pos.top}
                min={0}
                max={100}
                step={5}
                valueLabelDisplay='auto'
                onChange={handleChange('top')}
              />
            </Grid>
            <Grid item>
              <IconButton size='small' color='primary'>
                <FontAwesomeIcon icon={faRedo} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Typography>Left</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <Slider
                value={pos.left}
                min={0}
                max={100}
                step={5}
                valueLabelDisplay='auto'
                onChange={handleChange('left')}
              />
            </Grid>
            <Grid item>
              <IconButton size='small' color='primary'>
                <FontAwesomeIcon icon={faRedo} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </DialogPre>
    )
  }
)
