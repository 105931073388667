import React, { Fragment, useContext } from 'react'
import { MainContainer } from '../MainContainer'
import { connectViewCourse, ContextViewCourse } from './context'
import { KGContext } from '../KGProvider'
import { NotFound } from '../NotFound'
import { ViewCourseSidebar } from './sidebar'
import { ContentView } from '../ContentView'
import { genKey } from 'draft-js'
import { useTranslation } from 'react-i18next'
import { StrToParagraph } from '../functions'
import pt from 'prop-types'

export const ViewCourse = connectViewCourse((props) => {
  const { t } = useTranslation()
  const { selected, breadcrumbs, baseURL, ...store } =
    useContext(ContextViewCourse)
  const [data] = store.data
  const [state] = store.state

  return (
    <MainContainer
      loading={!state.fetched}
      dense
      sidebar={<ViewCourseSidebar />}
      protection
    >
      {state.permission ? (
        <Fragment>
          {selected && selected == 'home' ? (
            data.home ? (
              <>
                <ContentView
                  breadcrumbs={breadcrumbs}
                  data={{
                    title: data.title,
                    contents: data.home.contents
                  }}
                  noContainer
                  post
                />
              </>
            ) : (
              <ContentView
                breadcrumbs={breadcrumbs}
                data={{
                  title: data.title,
                  contents: [
                    { key: genKey(), value: t('Teacher'), type: 'heading' },
                    {
                      key: genKey(),
                      value: StrToParagraph(data.teacher),
                      type: 'paragraph',
                      bottom: 6
                    },
                    {
                      key: genKey(),
                      value: t('Courses.Description'),
                      type: 'heading'
                    },
                    {
                      key: genKey(),
                      value: StrToParagraph(data.desc),
                      type: 'paragraph',
                      bottom: 6
                    },
                    { key: genKey(), value: t('Subject'), type: 'heading' },
                    {
                      key: genKey(),
                      value: StrToParagraph(data.category),
                      type: 'paragraph'
                    }
                  ]
                }}
                noContainer
                post
              />
            )
          ) : (
            (Array.isArray(data.lessons) &&
              data.lessons.find((lesson) => lesson.id === selected) && (
                <ContentView
                  breadcrumbs={
                    Array.isArray(breadcrumbs)
                      ? [
                          ...breadcrumbs.slice(0, -1),
                          { label: t('Course'), to: baseURL },
                          { label: t('Lesson') }
                        ]
                      : null
                  }
                  data={data.lessons.find((lesson) => lesson.id === selected)}
                  noContainer
                  post
                  key={`lesson-${selected}`}
                />
              )) || <NotFound />
          )}
        </Fragment>
      ) : (
        <NotFound />
      )}
      {props.children}
    </MainContainer>
  )
})
ViewCourse.propTypes = {
  sitename: pt.string.isRequired,
  id: pt.string,
  selected: pt.string,
  back: pt.string.isRequired,
  baseURL: pt.string.isRequired,
  breadcrumbs: pt.arrayOf(
    pt.shape({
      label: pt.string.isRequired,
      to: pt.string
    })
  ),
  prefix: pt.string,
}
