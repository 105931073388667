import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@material-ui/core'
import React, { forwardRef } from 'react'
import propsTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
))

export const DialogRemove = ({ open, onClose, onConfirm, ...props }) => {
  const { t } = useTranslation()
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>{props.title || t('Remove')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.label || 'Do you want to remove?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button
          color='secondary'
          {...props.confirmButtonProps}
          onClick={onConfirm}
        >
          {t('Remove')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
DialogRemove.propTypes = {
  open: propsTypes.bool.isRequired,
  title: propsTypes.node,
  label: propsTypes.node,
  onClose: propsTypes.func.isRequired,
  onConfirm: propsTypes.func.isRequired,
  confirmButtonProps: propsTypes.object
}
