import React, { useState } from 'react'
import { useContext } from 'react'
import { BackLink } from '../BackLink'
import { ContextViewCourse } from './context'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  faChalkboard,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faListOl,
  faQuestion,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItemLink } from '../ListItemLink'
import { FeatureImage } from '../FeatureImage'
import { KGContext } from '../KGProvider'
import update from 'react-addons-update'
import { SubjectiveDialog } from './subjective.dialog'

const useStyles = makeStyles((theme) => ({
  cover: {
    position: 'relative'
  },
  more: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    color: 'white'
  }
}))

const icons = {
  lesson: faChalkboard,
  question: faListOl,
  subjective: faFileAlt
}

const iconsFile = {
  noflie: faChalkboard,
  word: faFileWord,
  excel: faFileExcel,
  pwpoint: faFilePowerpoint,
  pdf: faFilePdf
}

export const ViewCourseSidebar = (props) => {
  const {
    back,
    baseURL,
    selected,
    student,
    data: datas,
    state: states,
    more
  } = useContext(ContextViewCourse)
  const { user, firebase, addAlert } = useContext(KGContext)
  const [data, setData] = datas
  const [state] = states
  const cs = useStyles()
  const [open, setOpen] = useState(null)

  const handleOpen = (id) => () => setOpen(id)

  const selectFile = async (id) => {
    if (id) {
      const file = await firebase
        .firestore()
        .collection('documents')
        .doc(id)
        .get()
      return file.data()
    }
  }
  const studentDetail = async (id) => {
    if (id) {
      const userdetail = await firebase
        .firestore()
        .collection('students')
        .doc(id)
        .get()
      return userdetail.data()
    }
  }
  const fileName = async (lesson) => {
    const student = await studentDetail(user.uid)
    const value = Object.keys(lesson).find((key) =>
      key.includes(student.studentID)
    )
    if (value) {
      const file = await selectFile(lesson[value])
      return file.content
    }
  }

  const chkStatus = (lesson) => {
    const value = Object.keys(lesson)
      .sort((a, b) => {
        const atime = a.split('-')?.[2]
        const btime = b.split('-')?.[2]
        return parseInt(btime) - parseInt(atime)
      })
      .find((key) => key.includes(student?.studentID))
    if (value) {
      let status = value.slice(0, 3)
      switch (status) {
        case 's01':
          return 1
        case 's02':
          return 2
        case 's03':
          return 3
        case 't01':
          return 0
        default:
          return 0
      }
    }
    return 0
  }
  const steps = ['Uploaded', 'Pending review', 'Done']
  const handlegetStep = (lesson) => {
    return chkStatus(lesson)
  }

  const handleDownload = (lesson) => async () => {
    const student = await studentDetail(user.uid)
    const value = Object.keys(lesson).find((key) =>
      key.includes(student.studentID)
    )
    if (value) {
      const file = await selectFile(lesson[value])
      if (file) {
        window.open(`${file.content.original}`)
        addAlert({ label: 'Download success.', severity: 'success' })
      }
    }
  }

  const handleUpload = (lesson, index) => async (files) => {
    const student = await studentDetail(user.uid)
    const value = Object.keys(lesson).find((key) =>
      key.includes(student.studentID)
    )
    if (files) {
      await firebase
        .firestore()
        .collection('courses')
        .doc(lesson.id)
        .update({
          [`s01-${student.studentID}-${Date.now()}`]: files[0].id,
          [value]: firebase.firestore.FieldValue.delete()
        })
      setData((d) =>
        update(d, {
          lessons: {
            [index]: {
              $merge: {
                [`s01-${student.studentID}-${Date.now()}`]: files[0].id
              }
            }
          }
        })
      )
      addAlert({ label: 'Upload success.', severity: 'success' })
    }
  }

  const getType = (type) => `${type}`.split('-').pop()

  return (
    <React.Fragment>
      {back && <BackLink to={back} />}
      <div className={cs.cover}>
        {state.fetched ? (
          data.cover && <FeatureImage image={data.cover} />
        ) : (
          <Skeleton
            variant='rect'
            width='100%'
            style={{ paddingTop: 'calc((100% / 3) - 19px)' }}
          />
        )}
        {more && <div className={cs.more}>{more}</div>}
      </div>
      <List>
        <ListItemLink
          button
          divider
          to={baseURL}
          selected={selected === 'home'}
        >
          <ListItemText
            primary={state.fetched ? data.title : <Skeleton width='75%' />}
            secondary={state.fetched ? data.teacher : <Skeleton width='50%' />}
            primaryTypographyProps={{ variant: 'h6' }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </ListItemLink>
        {state.fetched === false ? (
          <ListItem divider>
            <ListItemText primary={<Skeleton width='50%' />} />
          </ListItem>
        ) : Array.isArray(data.lessons) && data.lessons.length ? (
          data.lessons
            .sort((a, b) => a.sort - b.sort)
            .map((lesson, index) => {
              switch (getType(lesson.type)) {
                case 'subjective':
                  return (
                    <React.Fragment key={lesson.id}>
                      <ListItemLink
                        divider
                        button
                        dense
                        to={'#'}
                        onClick={handleOpen(lesson.id)}
                      >
                        <ListItemIcon>
                          <Box textAlign='center' style={{ width: 40 }}>
                            <FontAwesomeIcon
                              size='2x'
                              icon={faFileAlt}
                              color='inherit'
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={lesson.title}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItemLink>
                      <SubjectiveDialog
                        open={open === lesson.id}
                        index={index}
                        onClose={handleOpen(null)}
                      />
                    </React.Fragment>
                  )
                case 'lesson':
                case 'question':
                  return (
                    <ListItemLink
                      divider
                      button
                      dense
                      key={lesson.id}
                      to={baseURL + `/${getType(lesson.type)}/${lesson.id}`}
                      target={
                        getType(lesson.type) === 'lesson' ? '_self' : '_blank'
                      }
                      selected={selected === lesson.id}
                    >
                      <ListItemIcon>
                        <Box textAlign='center' style={{ width: 40 }}>
                          <FontAwesomeIcon
                            size='2x'
                            icon={icons[getType(lesson.type)] || faQuestion}
                            color='inherit'
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        primary={lesson.title}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItemLink>
                  )
                default:
                  return null
              }
            })
        ) : null}
      </List>
    </React.Fragment>
  )
}
