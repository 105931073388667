import { faSave, faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, List, ListItem } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const SaveButton = ({ loading, onSave, ...props }) => {
  const { t } = useTranslation()

  return (
    <List disablePadding>
      <ListItem divider>
        <Button
          fullWidth
          size='large'
          variant='contained'
          color='primary'
          disableElevation
          startIcon={
            <FontAwesomeIcon
              icon={loading ? faSpinner : faSave}
              pulse={Boolean(loading)}
            />
          }
          disabled={loading || props.disabled}
          onClick={onSave}
        >
          {loading ? t('PleaseWait') : t('Save')}
        </Button>
      </ListItem>
    </List>
  )
}
SaveButton.propTypes = {
  loading: propTypes.bool,
  onSave: propTypes.func.isRequired
}
