import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography
} from '@material-ui/core'
import { KGContext } from '../KGProvider'
import QRCode from 'react-qr-code'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faUnlink } from '@fortawesome/pro-duotone-svg-icons'

export const SettingLine = (props) => {
  const { firebase, user, Link } = useContext(KGContext)
  const [open, setOpen] = useState(false)
  const [line, setLine] = useState('')

  const handleOpen = (o) => () => setOpen(o)
  const handleUnlink = async () => {
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .update({ line: firebase.firestore.FieldValue.delete() })
  }

  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          const { line } = snapshot.data() || {}
          setLine(line || '')
          if (line) {
            setOpen(false)
          }
        })
    } else {
      setLine('')
    }
  }, [user])

  return (
    <Box display='flex' alignItems='center'>
      {/* <Typography
        variant='caption'
        color={line ? 'textPrimary' : 'textSecondary'}
      >
        {line || 'not link'}
      </Typography>
      <Box flex={1} /> */}
      <Button
        variant='outlined'
        size='small'
        onClick={handleOpen(true)}
        startIcon={<FontAwesomeIcon icon={faLink} />}
      >
        {line ? 'Change' : 'Link'}
      </Button>
      {line && (
        <Fragment>
          &nbsp;
          <Button
            variant='outlined'
            size='small'
            color='secondary'
            onClick={handleUnlink}
            startIcon={<FontAwesomeIcon icon={faUnlink} />}
          >
            Unlink
          </Button>
        </Fragment>
      )}
      <Dialog
        fullWidth
        maxWidth='xs'
        open={open}
        onClose={handleOpen(false)}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <DialogTitle>Line QRCode</DialogTitle>
        <DialogContent>
          <Box textAlign='center'>
            <QRCode
              value={`https://line.me/R/oaMessage/@920ooxaj/?register:${user?.uid}`}
            />
            <Box mb={2} />
            <Button
              fullWidth
              variant="outlined"
              component={Link}
              to={`https://line.me/R/oaMessage/@920ooxaj/?register:${user?.uid}`}
              target='_blank'
              size="large"
              startIcon={<FontAwesomeIcon icon={faLink} />}
            >
              Link
            </Button>
            <Box mb={2} />
            <Typography variant='caption'>
              หากยังไม่เคยเพิ่มเพื่อนกรุณากดเพิ่มเพื่อนแล้วแสกน QR Code อีกครั้ง
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
