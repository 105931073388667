import moment from 'moment'
import 'moment/locale/th'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import { genKey } from 'draft-js'

const api_path = `https://oauth-phrain.vercel.app/api`

export const DateToStr = (data, fmt) => {
  if (data.datemodified || data.dateedit || data.datecreate) {
    const tmpDate = data.datemodified || data.dateedit || data.datecreate
    if (tmpDate.toMillis) {
      return moment(tmpDate.toMillis()).format(fmt || 'LL เวลา LT น.')
    } else if (tmpDate['_seconds']) {
      return moment(tmpDate['_seconds'] * 1000).format(fmt || 'LL เวลา LT น.')
    }
  }
  return moment().format(fmt || 'LL เวลา LT น.')
}

export const getUserFromEmail = async (email) => {
  if (!email) {
    return { error: 'params/invalid-email', message: 'Email invalid' }
  }
  if (firebase.apps.length) {
    const user = firebase.auth().currentUser
    if (user) {
      const token = await user.getIdToken()
      const result = await axios.get(
        `${api_path}/q/user/email/${encodeURI(email)}?token=${token}`
      )
      return result.data
    } else {
      return { error: 'user/user-not-signed', message: 'User not signed' }
    }
  } else {
    return {
      error: 'firebase/firebase-not-initialize',
      message: 'Firebase not initialize'
    }
  }
}

export const getGMT = (date) => {
  const localGMT = localStorage.getItem('myGMT')
  if (localGMT) return localGMT
  const offset = date.getTimezoneOffset() / 60
  const num = `${Math.abs(offset)}`.padStart(2, 0)
  const before = offset > -1 ? '+' : '-'
  return before + num + ':00'
}

export const template = (string, obj) => {
  var s = string
  for (var prop in obj) {
    s = s.replace(new RegExp('{' + prop + '}', 'g'), obj[prop])
  }
  return s
}

export const StrToParagraph = (text) => {
  return {
    entityMap: {},
    blocks: [
      {
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        key: genKey(),
        text: text || '',
        data: {},
        type: 'unstyled'
      }
    ]
  }
}

export const getStudentsInfo = async (ids, prefix) => {
  const token = await firebase.auth().currentUser.getIdToken()
  const result = await axios.post('https://oauth.mek.network/api/q/student', {
    ids,
    prefix,
    token
  })
  return result.data
}

export const getUserFromUids = async (uids) => {
  const token = await firebase.auth().currentUser.getIdToken()
  const result = await axios.post('https://oauth.mek.network/api/q/users', {
    uids,
    token
  })
  return result.data
}

export class SkeletonController {
  constructor(firebase) {
    this.firebase = firebase
  }
  db = () => this?.firebase?.firestore() ?? null
  currentUser = () => this?.firebase?.auth()?.currentUser ?? null
  check = () => {
    if (this.firebase) {
      return true
    } else {
      throw `Missing firebase`
    }
  }
  path = (...params) => {
    this.check()
    if (params.length) {
      let db = this.firebase.firestore()
      for (let i = 0; i < params.length; i++) {
        const mod = i % 2
        db = mod === 0 ? db.collection(params[i]) : db.doc(params[i])
      }
      return db
    } else {
      throw 'Missing path parameters'
    }
  }

  arrayUnion = (data) => this?.firebase?.firestore?.FieldValue?.arrayUnion(data)
  arrayRemove = (data) =>
    this?.firebase?.firestore?.FieldValue?.arrayRemove(data)
  delete = () => this?.firebase?.firestore?.FieldValue?.delete()
  increment = (n) => this?.firebase?.firestore?.FieldValue?.increment(n)
  timestamp = () => this?.firebase?.firestore?.FieldValue?.serverTimestamp()

  setupdate = (ref, setData, updateData) =>
    new Promise((res, rej) => {
      this.db()
        .runTransaction((transaction) =>
          transaction.get(ref).then((doc) => {
            if (!doc.exists) {
              transaction.set(ref, setData)
            } else {
              transaction.update(ref, updateData)
            }
          })
        )
        .then(() => res(true))
        .catch((err) => rej(err))
    })

  uploadURL = 'https://cdn.okkc.in:8082'
  upload = async (files, callback) => {
    if (!files.length) throw 'File not found'
    const file = files[0]

    const token = await this?.currentUser()?.getIdToken()
    const data = new FormData()
    data.append('attach', file)
    data.append('token', token)

    const config = {
      onUploadProgress: (progressEvent) => {
        let progress = (progressEvent.loaded / progressEvent.total) * 100
        progress = Math.floor(progress * 1)
        typeof callback === 'function' && callback({ progress })
      }
    }
    const result = await axios.post(`${this.uploadURL}/upload`, data, config)
    if (result?.date?.error) throw result?.data?.message
    return result?.data
  }
}
