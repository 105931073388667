import React, { useContext, useState } from 'react'
import { QuizEditorContext } from '../context'
import { Panel } from '../panel'
import {
  Box,
  Button,
  // FormControl,
  // InputAdornment,
  // Input,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import { SelectType } from '../select.type'

import { DialogRemove } from '../../DialogRemove'
import { useTranslation } from 'react-i18next'

const AnswerBox = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `solid 1px ${theme.palette.grey[300]}`,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  }
}))(Box)

const Label = ({ children, ...props }) => (
  <Typography variant='body1' {...props}>
    <strong>{children}</strong>
  </Typography>
)

export const OptionsMatching = (props) => {
  const { t } = useTranslation()
  const { genKey, ...store } = useContext(QuizEditorContext)
  const [data, setData] = store.data
  const [del, setDel] = useState(null)

  const handleTypeChange = (index) => (type) =>
    setData((d) => {
      let answers = [...(d.answers || [])]
      answers[index] = { ...answers[index], type }
      return { ...d, answers }
    })
  const handleValueChange = (index) => (value) =>
    setData((d) => {
      let answers = [...(d.answers || [])]
      if (answers[index].type === 'image') {
        answers[index].data = value
      } else if (answers[index].type === 'paragraph') {
        answers[index].label = value
      }
      return { ...d, answers }
    })
  const handleChange =
    (index) =>
    ({ target }) =>
      setData((d) => {
        let answers = [...(d.answers || [])]
        answers[index].value = target.value
        return { ...d, answers }
      })
  const handleAdd = () =>
    setData((d) => {
      let answers = [...(d.answers || [])]
      answers.push({
        key: genKey(),
        label: null,
        type: 'paragraph',
        value: '',
        data: null
      })
      return { ...d, answers }
    })
  const handleRemove = (key) => () => setDel(key)
  const handleRemoveConfirm = () =>
    setData((d) => {
      let answers = [...(d.answers || [])].filter((a) => a.key !== del)
      setDel(null)
      return { ...d, answers }
    })

  return (
    <Panel
      id='answer'
      title={t('Question.Answer')}
      actions={
        <Button color='primary' onClick={handleAdd}>
          {t('Question.AddMatching')}
        </Button>
      }
    >
      {Array.isArray(data && data.answers) &&
        data.answers.map((ans, index) => (
          <AnswerBox key={ans.key}>
            <Label>
              {t('Question.SubQuestion')}&nbsp;{index + 1}
            </Label>
            <SelectType
              type={ans.type}
              onTypeChange={handleTypeChange(index)}
              value={ans.type === 'image' ? ans.data : ans.label}
              onValueChange={handleValueChange(index)}
            />
            <Box pt={2} />
            <Label paragraph>{t('Question.Answer') + ` ` + (index + 1)}</Label>
            <TextField
              fullWidth
              label={t('Question.AnswerText')}
              variant='outlined'
              value={ans.value}
              onChange={handleChange(index)}
            />
            <Box display="flex" mt={2}>
              {/* <FormControl variant="outlined">
                <Input
                  type="number"
                  value={ans.score || 0}
                  onChange={e=>console.log(e.target.value)}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </FormControl> */}
              {data.answers.length > 2 && (
                <Button color='secondary' onClick={handleRemove(ans.key)}>
                  {t('Remove')}
                </Button>
              )}
            </Box>
          </AnswerBox>
        ))}
      <DialogRemove
        open={Boolean(del)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </Panel>
  )
}
