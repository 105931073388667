import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import styles from './paragraph.module.css'
import { convertFromRaw, EditorState } from 'draft-js'
import { Typography, withStyles } from '@material-ui/core'

/**
 * https://jpuri.github.io/react-draft-wysiwyg/#/docs
 */

const toolbar = {
  options: ['inline', 'textAlign', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough']
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo']
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right']
  }
}

export const ParagraphEdit = withStyles((theme) => ({
  root: {
    '& .public-DraftEditor-content': {
      ...theme.typography.body1
    },
    '& .public-DraftStyleDefault-block': {
      margin: 0
    }
  }
}))(({ classes, value, onChange, ...props }) => {
  const ref = useRef()
  const [editorState, setEditorState] = useState(null)
  const [contentState, setContentState] = useState(null)

  const handleEditorStateChange = (editorState) => setEditorState(editorState)
  const handleContentStateChange = (contentState) =>
    setContentState(contentState)
  const handleBlur = (e) => onChange && onChange(contentState)

  useEffect(() => {
    if (value) {
      const contentState = convertFromRaw(value)
      const cacheState = EditorState.createWithContent(contentState)
      setEditorState(cacheState)
    } else {
      setEditorState(null)
    }
    if (props.show && !props.editOnly) {
      ref.current.focusEditor()
    }
  }, [value, props.show])

  return (
    <div
      className={classes.root}
      style={{ display: Boolean(props.show) ? 'block' : 'none' }}
    >
      <Typography variant='body1' component='div' color='textSecondary'>
        <Editor
          ref={ref}
          editorClassName={styles.editor}
          onBlur={handleBlur}
          editorState={editorState}
          toolbar={toolbar}
          stripPastedStyles={true}
          onEditorStateChange={handleEditorStateChange}
          onContentStateChange={handleContentStateChange}
        />
      </Typography>
    </div>
  )
})
