import { Box } from '@material-ui/core'
import React, { useContext } from 'react'
import { KGContext } from '../../KGProvider'

const parseURL = (url) => {
  if (url) {
    return !url.includes('//') ? `//${url}` : url
  }
  return ''
}

export const CVImageURL = ({ children, content, ...props }) => {
  const { Link } = useContext(KGContext)
  const url = parseURL(content?.url)
  return (
    <Box component={url ? 'a' : null} href={url || null} target='_blank'>
      {children}
    </Box>
  )
}
