import React, { createContext, useContext, useRef, useState } from 'react'
import { ContentEditContext } from '../context'

export const ContextSlide = createContext({})

const ProviderSlide = ({ children, ...props }) => {
  const { updateContent } = useContext(ContentEditContext);
  const swiperRef = useRef(null)
  const params = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    observer: true,
    ref: swiperRef
  }
  const [state, setState] = useState({
    remove: null
  })

  const handleRemove = remove => () => setState(s=>({ ...s, remove }))

  const store = {
    ...props,
    swiperRef,
    params,
    state: [state, setState],
    handleRemove,
    updateContent,
  }

  return <ContextSlide.Provider value={store}>{children}</ContextSlide.Provider>
}

export const connectSlide = (Comp) => (props) =>
  (
    <ProviderSlide {...props}>
      <Comp {...props} />
    </ProviderSlide>
  )
