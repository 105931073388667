import React, { useContext, useState } from 'react'
import {
  Button,
  Divider,
  ListSubheader,
  Menu,
  MenuItem
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/pro-duotone-svg-icons'
import { genKey } from 'draft-js'
import { CETableContext } from './context'

export const ColRowMenu = React.forwardRef(({ ...props }, ref) => {
  const {
    content,
    index,
    genKey,
    updateContent,
    select: [selected, setSelected]
  } = useContext(CETableContext)
  const [anchor, setAnchor] = useState(null)

  const handleOpen = (e) => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const handleChange = (type) => () => {
    let { rows, columns } = { ...content }
    let rowIndex = rows.findIndex((r) => r.key === selected.row)
    let colIndex = columns.findIndex((c) => c.key === selected.col)
    switch (type) {
      case 'InsertRowBefore':
        rows.splice(rowIndex, 0, { key: genKey() })
        updateContent(index, 'rows', rows)
        break
      case 'InsertRowAfter':
        rows.splice(rowIndex + 1, 0, { key: genKey() })
        updateContent(index, 'rows', rows)
        break
      case 'DeleteRow':
        rows.splice(rowIndex, 1)
        setSelected({ col: '', row: '' })
        updateContent(index, 'rows', rows)
        break
      case 'InsertColumnBefore':
        columns.splice(colIndex, 0, { key: genKey() })
        updateContent(index, 'columns', columns)
        break
      case 'InsertColumnAfter':
        columns.splice(colIndex + 1, 0, { key: genKey() })
        updateContent(index, 'columns', columns)
        break
      case 'DeleteColumn':
        columns.splice(colIndex, 1)
        updateContent(index, 'columns', columns)
        break
      default:
        break
    }
    handleClose()
  }

  return (
    <React.Fragment>
      <Button ref={ref} {...props} onClick={handleOpen}>
        <FontAwesomeIcon icon={faTable} />
      </Button>
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <ListSubheader>Row</ListSubheader>
        <MenuItem
          onClick={handleChange('InsertRowBefore')}
          disabled={!selected.row}
        >
          Insert Row Before
        </MenuItem>
        <MenuItem
          onClick={handleChange('InsertRowAfter')}
          disabled={!selected.row}
        >
          Insert Row After
        </MenuItem>
        <MenuItem onClick={handleChange('DeleteRow')} disabled={!selected.row}>
          Delete Row
        </MenuItem>
        <Divider />
        <ListSubheader>Column</ListSubheader>
        <MenuItem
          onClick={handleChange('InsertColumnBefore')}
          disabled={!selected.col}
        >
          Insert Column Before
        </MenuItem>
        <MenuItem
          onClick={handleChange('InsertColumnAfter')}
          disabled={!selected.col}
        >
          Insert Column After
        </MenuItem>
        <MenuItem
          onClick={handleChange('DeleteColumn')}
          disabled={!selected.col}
        >
          Delete Column
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
})
