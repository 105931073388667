import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-duotone-svg-icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  fa: {
    ...theme.typography.caption,
    color: 'white',
    cursor: 'move',
  },
  item: {
    position: 'relative',
    border: `solid 1px transparent`,
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.palette.grey[300],
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none'
    }
  },
  itemFocus: {
    paddingTop: 24,
    borderColor: theme.palette.primary.main,
  },
  actions: {
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    padding: theme.spacing(0, 1),
    top: 0,
    left: 0,
    height: 24,
    width: '100%'
  }
}))

const PanelHandle = sortableHandle((props) => {
  const classes = useStyles()
  return (
    <IconButton size='small'>
      <FontAwesomeIcon className={classes.fa} icon={faBars} />
    </IconButton>
  )
})

export const PanelItem = sortableElement(({ children, i, focus, ...props }) => {
  const classes = useStyles()
  return (
    <Grid
      className={clsx(classes.item, { [classes.itemFocus]: focus })}
      item
      {...props}
    >
      {focus && (
        <div className={classes.actions}>
          <PanelHandle />
        </div>
      )}
      {children}
    </Grid>
  )
})

export const PanelContainer = sortableContainer(({ children }) => (
  <Grid container alignItems="center">{children}</Grid>
))
