import React from 'react';
import { List, ListItem, withStyles } from '@material-ui/core';

export const ListItemButton = withStyles(theme=>({
  root: {
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
}))(({ classes, ...props }) => {
  return (<List className={classes.root} disablePadding>
    <ListItem button {...props} />
  </List>)
})