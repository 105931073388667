import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Context, connect } from './index.context'
import { RemoveImage } from './index.remove'
import { UploadFileButton } from './index.uploadFile.button'
import { TabBrowse } from './tab.browse'

const Transition = React.forwardRef((props, ref) => (
    <Slide direction='down' ref={ref} {...props} />
))

export const FilePicker = connect()(({ ...props }) => {
    const { t } = useTranslation()
    const store = React.useContext(Context)
    const [state, setState] = store.state
    const [open, setOpen] = React.useState(false)

    const handleOpen = (o) => () => setOpen(o)
    const handleConfirm = () => {
        store.onConfirm(state.selected)
        handleOpen(false)()
    }
    
    return (<React.Fragment>
        {props.children &&
            React.cloneElement(props.children, {
                onClick: handleOpen(!open)
            })}
        <Dialog
            fullWidth
            maxWidth='sm'
            open={open}
            TransitionComponent={Transition}
            onClose={handleOpen(false)}
        >
            <DialogTitle>File Picker</DialogTitle>
            <DialogContent style={{padding: 0}}>
                <div id="test" />
                <TabBrowse />
            </DialogContent>
            <DialogActions>
                <UploadFileButton />
                <RemoveImage />
                <Box flex={1} />
                {typeof store.onConfirm === 'function' && (
                    <Button
                        disabled={!Boolean(state.selected.length)}
                        color='primary'
                        onClick={handleConfirm}
                    >
                        {t("Confirm")}
                    </Button>
                )}
                <Button onClick={handleOpen(false)}>{t("Close")}</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>)
})