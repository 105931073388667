import { faEdit } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KuiActionIcon } from '../Theme'
import propTypes from 'prop-types'

export const TitleEdit = ({ value, onChange, ...props }) => {
  const [edit, setEdit] = useState(false)
  const { t } = useTranslation()
  const [title, setTitle] = useState('')

  const handleToggle = () => setEdit((e) => !e)
  const handleChange = ({ target: { value } }) => setTitle(value)
  const handleCheck = () => {
    onChange(title)
    setEdit(false)
  }

  useEffect(() => {
    if (value) {
      setTitle(value)
    }
  }, [value])

  return (
    <List disablePadding>
      {edit ? (
        <ListItem divider>
          <Box pt={1}>
            <TextField
              autoFocus
              size='small'
              fullWidth
              label={t('Title')}
              value={title}
              onChange={handleChange}
            />
            <Box display='flex' justifyContent='flex-end' pt={1}>
              <KuiActionIcon
                tx='check'
                disabled={!Boolean(title && title !== value)}
                onClick={handleCheck}
              />
              <KuiActionIcon tx='cancel' onClick={handleToggle} />
            </Box>
          </Box>
        </ListItem>
      ) : (
        <ListItem button divider dense onClick={handleToggle}>
          <ListItemText
            primary={t('Title')}
            secondary={value || t('TitleEdit.NoTitle')}
            primaryTypographyProps={{
              variant: 'caption',
              color: 'textSecondary'
            }}
            secondaryTypographyProps={{
              variant: 'h6',
              color: Boolean(value) ? 'textPrimary' : 'textSecondary'
            }}
          />
          <ListItemSecondaryAction>
            <IconButton size='small' onClick={handleToggle}>
              <FontAwesomeIcon size='xs' icon={faEdit} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  )
}
TitleEdit.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired
}
