import { Box, List } from '@material-ui/core'
import React from 'react'
import { GridSize } from './grid.size'
import { Back } from './others'
import { Paragraph } from '../../../Paragraph'
import { injectPageEdit } from '../../edit.context'

export const EditTypeParagraph = injectPageEdit(
  ({
    store: {
      update,
      datas: [, setData]
    },
    content,
    index,
    ...props
  }) => {
    const handleChange = (value) =>
      setData((d) =>
        update(d, { contents: { [index]: { value: { $set: value } } } })
      )
    return (
      <Box p={2}>
        <Paragraph value={content.value} editOnly onChange={handleChange} />
      </Box>
    )
  }
)

export const EditTypeParagraphSidebar = ({ content, index, ...props }) => {
  return (
    <React.Fragment>
      <Back />
      <List>
        <GridSize content={content} index={index} />
      </List>
    </React.Fragment>
  )
}
