import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import { BackLink } from '../BackLink'
import { SaveButton } from '../SaveButton'
import { injectPageEdit } from './edit.context'
import update from 'react-addons-update'
import { EditTypeHeaderSidebar } from './comp/edit/header'
import { EditTypeParagraphSidebar } from './comp/edit/paragraph'

const ViewLimit = ({ value, onChange, ...props }) => {
  const [anchor, setAchor] = useState(null)

  const handleOpen = ({ currentTarget }) => setAchor(currentTarget)
  const handleClose = () => setAchor(null)
  const handleChange = (size) => () => onChange(size) || setAchor(null)

  return (
    <React.Fragment>
      <ListItem divider>
        <ListItemText primary='View Size' />
        <ListItemSecondaryAction>
          <IconButton size='small' onClick={handleOpen}>
            <Typography variant='body2'>
              {(value || 'None').toLocaleUpperCase()}
            </Typography>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={handleClose}>
        <MenuItem onClick={handleChange('')}>None</MenuItem>
        {['xs', 'sm', 'md', 'lg', 'xl'].map((size) => (
          <MenuItem key={size} onClick={handleChange(size)}>
            {size.toLocaleUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

export const PageEditSidebar = injectPageEdit(({ store, ...props }) => {
  const {
    back,
    onSave,
    datas: [data, setData],
    states: [state, setState]
  } = store

  const handleSave = () => onSave(store.datas[0])
  const handleChangeViewLimit = (size) =>
    setState((s) => update(s, { limit: { $set: size } }))

  return (
    <React.Fragment>
      {state.focus !== null ? (
        (() => {
          const content = data.contents[state.focus] || {}
          switch (content.type) {
            case 'header':
              return (
                <EditTypeHeaderSidebar index={state.focus} content={content} />
              )
            case 'paragraph':
              return (
                <EditTypeParagraphSidebar
                  index={state.focus}
                  content={content}
                />
              )
            default:
              return content.type
          }
        })()
      ) : (
        <React.Fragment>
          {back && <BackLink to={back} />}
          {typeof onSave === 'function' && <SaveButton onSave={handleSave} />}
          <List>
            <ViewLimit value={state.limit} onChange={handleChangeViewLimit} />
          </List>
        </React.Fragment>
      )}
    </React.Fragment>
  )
})
