import {
  faBook,
  faCalendar,
  faChevronUp,
  faEdit
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useContext, useState } from 'react'
import { BackLink } from '../BackLink'
import clsx from 'clsx'

import { Context } from './context'
import { ImageDisplay } from '../ImageDisplay'
import { DateToStr } from '../functions'

const greyCode = 300
const ListItemPost = withStyles((theme) => ({
  root: {
    borderRight: `solid 1px ${theme.palette.grey[greyCode]}`,
    boxSizing: 'border-box',
    padding: theme.spacing(0, 1),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  },
  selected: {
    backgroundColor: theme.palette.primary.main + ' !important',
    color: theme.palette.primary.contrastText
  }
}))(({ classes, item, ...props }) => {
  const store = useContext(Context)
  const [state] = store.state
  const selected =
    Array.isArray(state.pagesKey) && state.pagesKey[state.open - 1]
  const handleOpen = () => {
    const index = state.pagesKey.indexOf(`${item.key}`)
    store.changePage(index + 1)
  }
  return (
    <ListItem
      dense
      className={clsx(classes.root, {
        [classes.selected]: selected === `${item.key}`
      })}
      button
      selected={selected === `${item.key}`}
      onClick={handleOpen}
      {...props}
    >
      <ListItemText
        primary={item.title}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
})
const ListItemFolder = withStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[greyCode]}`,
    borderTop: 'none',
    borderBottom: 'none',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  },
  head: {
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.grey[greyCode],
    '&:hover': {
      backgroundColor: theme.palette.grey[greyCode + 100]
    }
  },
  item: {
    padding: theme.spacing(0, 1)
  },
  selected: {
    backgroundColor: theme.palette.primary.main + ' !important',
    color: theme.palette.primary.contrastText
  }
}))(({ classes, item, ...props }) => {
  const { t, ...store } = useContext(Context)
  const [state] = store.state
  const selected =
    Array.isArray(state.pagesKey) && state.pagesKey[state.open - 1]
  const [open, setOpen] = useState(true)
  const handleToggle = () => setOpen((o) => !o)
  const handleOpen = (key) => () => {
    const index = state.pagesKey.indexOf(`${key}`)
    store.changePage(index + 1)
  }
  return (
    <List className={classes.root} disablePadding>
      <ListItem button dense className={classes.head} onClick={handleToggle}>
        <ListItemText
          primary={<strong>{item.title}</strong>}
          primaryTypographyProps={{ variant: 'body2' }}
        />
        <ListItemSecondaryAction>
          <IconButton size='small' onClick={handleToggle}>
            <FontAwesomeIcon
              icon={faChevronUp}
              rotation={open ? 0 : 180}
              size='xs'
              style={{ transition: 'all 0.25s' }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open}>
        {Array.isArray(item.child) && item.child.length ? (
          item.child.map((c) => (
            <ListItem
              className={clsx(classes.item, {
                [classes.selected]: selected === `${item.key}${c.key}`
              })}
              button
              dense
              divider
              key={c.key}
              selected={selected === `${item.key}${c.key}`}
              onClick={handleOpen(`${item.key}${c.key}`)}
            >
              <ListItemText
                primary={c.title}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          ))
        ) : (
          <ListItem className={classes.item} dense divider>
            <ListItemText secondary={t('Empty')} />
          </ListItem>
        )}
      </Collapse>
    </List>
  )
})

export const Sidebar = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  customPadding: {
    padding: theme.spacing(1)
  }
}))(({ classes, ...props }) => {
  const { back, fetched, data, ...store } = useContext(Context)
  const [state] = store.state

  const getData = (key) =>
    fetched ? (
      data && data[key]
    ) : (
      <Skeleton width='50%' style={{ display: 'inline-block' }} />
    )

  const handleView = (open) => () => store.changePage(open)

  return (
    <React.Fragment>
      {back && <BackLink to={back} />}
      <List disablePadding>
        {data && data.cover && (
          <ListItem className={classes.customPadding}>
            <ImageDisplay
              image={data.cover}
              ratio={1 / 3}
              style={{ width: '100%' }}
            />
          </ListItem>
        )}
        <ListItem
          className={classes.customPadding}
          button
          onClick={handleView('cover')}
        >
          <ListItemText
            primary={
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faBook}
                  style={{ marginRight: '0.5em' }}
                />
                {getData('title')}
              </React.Fragment>
            }
            primaryTypographyProps={{
              variant: 'h6',
              style: { marginBottom: '0.25em' }
            }}
            secondary={
              data ? (
                <span>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ marginRight: '0.5em' }}
                  />
                  {DateToStr(data, 'L LT')}
                  <span style={{ margin: '0 0.5rem' }}>|</span>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: '0.5em' }}
                  />
                  {state.usersFetched ? (
                    state.users[data.user] ? (
                      state.users[data.user].displayName ||
                      state.users[data.user].email ||
                      state.users[data.user].uid
                    ) : (
                      data.user
                    )
                  ) : (
                    <Skeleton width='20%' />
                  )}
                </span>
              ) : (
                <Skeleton width='35%' />
              )
            }
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </ListItem>
      </List>
      <List className={classes.root}>
        {data &&
          Array.isArray(data.items) &&
          data.items.map((item) => {
            switch (item.type) {
              case 'post':
                return <ListItemPost item={item} key={item.key} />
              case 'folder':
                return <ListItemFolder item={item} key={item.key} />
              default:
                if (process.env.NODE_ENV === 'development') {
                  return JSON.stringify(item)
                } else {
                  return null
                }
            }
          })}
      </List>
    </React.Fragment>
  )
})
