import { withWidth } from '@material-ui/core'
import React, { useState } from 'react'
import { createContext } from 'react'

export const MainContaxt = createContext({})

const MainProvider = withWidth()(({ children, ...props }) => {
  const [open, setOpen] = useState({
    left: false,
    right: false,
    signin: false
  })

  const handleOpen = (key, value) => () =>
    setOpen((o) => ({ ...o, [key]: value }))

  const store = {
    ...props,
    open,
    handleOpen,
    isMobile: ['xs', 'sm'].includes(props.width)
  }

  return <MainContaxt.Provider value={store}>{children}</MainContaxt.Provider>
})

export const connectMain = (Comp) => (props) =>
  (
    <MainProvider {...props}>
      <Comp {...props} />
    </MainProvider>
  )
