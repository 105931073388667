import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import { KGContext } from '../KGProvider'
import { ContentEditContext } from './context'

const defaultData = {
  from: 'post',
  id: ''
}

export const SelectContent = (props) => {
  const { docs } = useContext(ContentEditContext).state[0]
  const { t } = useContext(KGContext)
  const [data, setData] = useState({ ...defaultData })
  const [open, setOpen] = useState(false)
  const handleOpen = o => () => setOpen(o)
  const isComplete = Boolean(data.from && data.id)
  const handleChange =
    (key) =>
    ({ target }) =>
      setData((d) => ({ ...d, [key]: target.value }))
  const getDocs = () => docs.filter((d) => d.type === data.from)
  const handleAdd = () => {
    const item = docs.find((d) => d.id === data.id)
    if (item && props.onConfirm && typeof props.onConfirm === 'function') {
      props.onConfirm(item)
      handleOpen(false)()
    }
  }
  useEffect(() => {
    if (open) {
      setData({ ...defaultData })
    }
  }, [open])
  return (
    <React.Fragment>
      {props.children &&
        React.cloneElement(props.children, { onClick: handleOpen(true) })}
      <Dialog fullWidth maxWidth='xs' open={open} onClose={handleOpen(false)}>
        <DialogTitle>{t('Add')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant='outlined'>
            <InputLabel>{t('From')}</InputLabel>
            <Select
              labelWidth={40}
              value={data.from}
              onChange={handleChange('from')}
            >
              <MenuItem value='post'>Post</MenuItem>
              <MenuItem value='book'>Book</MenuItem>
            </Select>
          </FormControl>
          <Box mb={2} />
          <FormControl fullWidth variant='outlined'>
            <InputLabel>{t('Choose')}</InputLabel>
            <Select
              labelWidth={42}
              value={
                getDocs()
                  .map((d) => d.id)
                  .includes(data.id)
                  ? data.id
                  : ''
              }
              onChange={handleChange('id')}
            >
              <MenuItem value='' disabled>
                -- {t('Choose')} --
              </MenuItem>
              {getDocs().map((doc) => (
                <MenuItem value={doc.id} key={doc.id}>
                  {doc.label || doc.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color='primary' disabled={!isComplete} onClick={handleAdd}>
            {t('Add')}
          </Button>
          <Button onClick={handleOpen(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
