import { MenuItem, Slider, Typography } from '@material-ui/core'
import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { DialogPre } from '../DialogPre'
import { ContentEditContext } from './context'

export const Spacing = forwardRef(({ index, ...props }, ref) => {
  const { updateContent, ...store } = useContext(ContentEditContext)
  const [data] = store.data
  const [sp, setSp] = useState({
    top: 0,
    bottom: 2
  })

  const handleChange = (key) => (e, n) => setSp((s) => ({ ...s, [key]: n }))
  const handleSave = () => {
    updateContent(index, 'top', sp.top)
    updateContent(index, 'bottom', sp.bottom)
  }

  useEffect(() => {
    if (index > -1 && data.contents[index]) {
      setSp({
        top: data.contents[index].top || 0,
        bottom: data.contents[index].bottom || 3
      })
    }
  }, [index])

  return (
    index > -1 && (
      <DialogPre
        button={<MenuItem ref={ref}>Spacing</MenuItem>}
        onConfirm={handleSave}
      >
        <Typography gutterBottom>Top</Typography>
        <Slider
          min={0}
          max={12}
          value={sp.top}
          onChange={handleChange('top')}
          valueLabelDisplay="auto"
        />
        <Typography gutterBottom>Botton</Typography>
        <Slider
          min={0}
          max={12}
          value={sp.bottom}
          onChange={handleChange('bottom')}
          valueLabelDisplay="auto"
        />
      </DialogPre>
    )
  )
})
