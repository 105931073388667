import {
  Checkbox,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { useRef, useEffect, useState } from 'react'
import { Blurhash } from 'react-blurhash'

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect()
      }
    }
  }, [ref])

  return isIntersecting
}

export const ImageBlock = withStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
    border: `solid 0px ${theme.palette.primary.main}`,
    borderWidth: ({ selected }) => (selected ? 6 : 0),
    transition: 'all 0.125s',
    '&:before': {
      display: 'block',
      paddingTop: '100%',
      content: "''"
    }
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[400]
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '&:hover': {
      objectFit: 'contain'
    }
  },
  checkbox: {
    position: 'absolute',
    top: 4,
    right: 4
  },
  blurhash: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100% !important',
    height: '100% !important'
  }
}))(({ file, classes, loading, noImage, selected, ...props }) => {
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  return (
    <div ref={ref} className={classes.root} {...props}>
      {(() => {
        if (loading) {
          return (
            <div className={classes.loading}>
              <CircularProgress color='inherit' />
            </div>
          )
        } else if (noImage) {
          return (
            <div className={classes.loading}>
              <Typography>No Image</Typography>
            </div>
          )
        } else {
          return Boolean(file && isVisible) ? (
            <React.Fragment>
              {file.blurhash && (
                <Blurhash
                  className={classes.blurhash}
                  hash={file.blurhash}
                  width={400}
                  height={300}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              )}
              <img
                className={classes.image}
                src={file.content.thumbnail || file.content.original}
              />
              <Checkbox
                className={classes.checkbox}
                checked={Boolean(selected)}
                color='primary'
              />
            </React.Fragment>
          ) : (
            Boolean(file && file.blurhash) && (
              <Blurhash
                className={classes.blurhash}
                hash={file.blurhash}
                width={150}
                height={150}
                resolutionX={32}
                resolutionY={32}
              />
            )
          )
        }
      })()}
    </div>
  )
})
