import { CircularProgress, IconButton } from '@material-ui/core'
import React, { useContext } from 'react'
import { KGContext } from '../KGProvider'
import { ProfileNotSign } from './Profile.notsign'
import { ProfileSigned } from './Profile.signed'

export const Profile = (props) => {
  const { user, ...store } = useContext(KGContext);
  const [state] = store.state;

  if (!state.userFetched) {
    return (
      <IconButton edge='end' disabled>
        <CircularProgress size={18} color='inherit' />
      </IconButton>
    )
  } else {
    return Boolean(user) ? <ProfileSigned /> : <ProfileNotSign />
  }
}
