import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, withWidth } from '@material-ui/core'
import React from 'react'

export const ActionIcon = withWidth()(({ icon, width, ...props }) => {
  const isMobile = ["xs","sm","md"].includes(width);

  return (
    <IconButton size="small" {...props}>
      <FontAwesomeIcon size={isMobile ? '1x' : 'xs'} icon={icon} />
    </IconButton>
  )
})
