import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField
} from '@material-ui/core'
import React, {
  cloneElement,
  forwardRef,
  Fragment,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef((props, ref) => <Grow ref={ref} {...props} />)

export const DialogPrompt = forwardRef(
  ({ value, onConfirm, ...props }, ref) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [v, setV] = useState('')
    const isComplete = Boolean(v && v !== value)

    const handleOpen = (o) => () => setOpen(o)
    const handleChange = ({ target }) => setV(target.value)
    const handleConfirm = () => {
      if (onConfirm) {
        onConfirm(v)
        setOpen(false)
        if (props.clearAfterConfirm) {
          setV('')
        }
      }
    }

    useEffect(() => {
      setV(value)
    }, [value])

    return (
      <Fragment>
        {props.children &&
          cloneElement(props.children, {
            onClick: handleOpen(true)
          })}
        <Dialog
          fullWidth
          maxWidth='xs'
          open={open}
          onClose={handleOpen(false)}
          TransitionComponent={Transition}
          disableRestoreFocus
        >
          <DialogTitle>{props.title || 'Prompt'}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              variant='outlined'
              label={props.label || 'Input'}
              value={v || ''}
              onChange={handleChange}
              ref={ref}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              disabled={!isComplete}
              onClick={handleConfirm}
            >
              {t('Confirm')}
            </Button>
            <Button onClick={handleOpen(false)}>{t('Close')}</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
)
