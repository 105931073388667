import React from 'react'
import { Box } from '@material-ui/core'
import { Paragraph } from '../../../Paragraph'

export const ViewTypeParagraph = ({ content, index, ...props }) => {
  return (
    <Box>
      <Paragraph value={content.value} />
    </Box>
  )
}
