import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import propTypes from 'prop-types'
import { CETableContext } from './context'
import { Paragraph } from '../../Paragraph'
import { ImageDisplay } from '../../ImageDisplay'
import { ImagePicker } from '../../ImagePicker'
import { DuotoneButton } from '../../DuotoneButton'
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons'

const defaultData = {
  type: 'paragraph',
  value: null
}
export const EditCell = ({ open, onClose, onConfirm, defaultValue, ...props }) => {
  const { t } = useContext(CETableContext)
  const [data, setData] = useState({ ...defaultData })

  const handleChangeType = ({ target }) =>
    setData((d) => ({ ...d, type: target.value, value: null }))
  const handleConfirm = () => {
    if(typeof onConfirm === "function"){
      onConfirm(data);
      setData({ ...defaultData })
    }
  }

  useEffect(()=>{
    if(defaultValue){
      setData({ ...defaultData, ...defaultValue })
    } else {
      setData({ ...defaultData })
    }
  }, [defaultValue])

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle>{t('Edit')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant='outlined'>
          <InputLabel id='type-label'>{t('Type')}</InputLabel>
          <Select
            labelWidth={60}
            value={data.type}
            onChange={handleChangeType}
            style={{ marginBottom: '1rem' }}
          >
            <MenuItem value='paragraph'>{t('Paragraph')}</MenuItem>
            <MenuItem value='image'>{t('Image')}</MenuItem>
            <MenuItem value='number'>{t('Number')}</MenuItem>
          </Select>
          {(() => {
            switch (data.type) {
              case 'paragraph':
                return (
                  <Paragraph
                    value={data.value}
                    editOnly
                    onChange={(text) => setData((d) => ({ ...d, value: text }))}
                  />
                )
              case 'image':
                return (
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    {data.value && (
                      <ImageDisplay
                        image={data.value}
                        style={{ marginBottom: '1rem' }}
                      />
                    )}
                    <ImagePicker
                      onConfirm={([image]) => {
                        const { content, blurhash, id } = image
                        setData((d) => ({
                          ...d,
                          value: { ...content, blurhash, id }
                        }))
                      }}
                    >
                      <DuotoneButton variant='outlined' icon={faFolderOpen}>
                        {t('Select')}
                      </DuotoneButton>
                    </ImagePicker>
                  </Box>
                )
              case 'number':
                return (
                  <TextField
                    type='number'
                    label={t('Value')}
                    fullWidth
                    variant='outlined'
                    value={data.value || 0}
                    onChange={({ target }) =>
                      setData((d) => ({ ...d, value: target.value }))
                    }
                  />
                )
              default:
                return null
            }
          })()}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          disabled={!Boolean(data.type && data.value !== null)}
          onClick={handleConfirm}
        >
          {t('Confirm')}
        </Button>
        <Button onClick={onClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  )
}
EditCell.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
}
