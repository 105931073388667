import React from 'react'
import propTypes from 'prop-types'
import { Paragraph } from '../../Paragraph'
import { ImageDisplay } from '../../ImageDisplay'
import { Box } from '@material-ui/core'

export const DataView = ({ type, value, ...props }) => {
  switch (type) {
    case 'paragraph':
      return <Paragraph value={value} />
    case 'image':
      return (
        <Box display="flex" justifyContent="center">
          <ImageDisplay image={value} style={{ width: 272 }} />
        </Box>
      )
    case 'number':
      return <Box textAlign='right'>{value}</Box>
    default:
      return ''
  }
}
DataView.propTypes = {
  type: propTypes.string
}
