import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import React, { useContext } from 'react'
import { KGContext } from '../KGProvider'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DuotoneButton } from '../DuotoneButton'

export const BackLink = (props) => {
  const { Link } = useContext(KGContext)
  const { t } = useTranslation();

  return (
    <List disablePadding>
      <ListItem divider>
        {Link ? (
          <DuotoneButton variant="text" to={props.to} component={Link} icon={faChevronLeft}>
            {t('Back')}
          </DuotoneButton>
        ) : (
          <ListItemText
            primary='Please define "Link" in config'
            primaryTypographyProps={{ color: 'error' }}
          />
        )}
      </ListItem>
    </List>
  )
}
BackLink.propTypes = {
  to: propTypes.string.isRequired,
}