import { faLink } from '@fortawesome/pro-duotone-svg-icons'
import React, { useContext } from 'react'
import { DialogPrompt } from '../DialogPrompt'
import { DuotoneButton } from '../DuotoneButton'
import axios from 'axios'
import { KGContext } from '../KGProvider'
import { encode } from 'blurhash'

const encodeBlurhash = (url) =>
  new Promise((res) => {
    const img = new Image()
    img.src = url
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const width = Math.floor(img.width / 10),
        height = Math.floor(img.height / 10)
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, width, height)
      const data = context.getImageData(0, 0, width, height)
      const hash = encode(data.data, width, height, 4, 4)
      res(hash)
    }
  })

const uploadUrl = location.protocol + '//nas.phra.in/uploadUrl.php'
const handleUploadUrl = async (firebase, url, uid) => {
  const result = await axios.get(
    `${uploadUrl}?url=${encodeURIComponent(url)}&user=${uid}`
  )
  if (result.data.error) {
    return result.data
  } else {
    const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp
    const query = await firebase
      .firestore()
      .collection('documents')
      .where('user', '==', uid)
      .where('md5', '==', result.data.md5)
      .get()
    if (query.docs.length) {
      await firebase
        .firestore()
        .collection('documents')
        .doc(query.docs[0].id)
        .update({ dateedit: dbTimestamp() })
      return query.docs[0].id
    } else {
      const blurhash = await encodeBlurhash(result.data.original)
      const { md5, ...content } = result.data
      const imageData = {
        content,
        md5,
        user: uid,
        dateedit: dbTimestamp(),
        visibility: 'private',
        type: 'image',
        parent: 0,
        blurhash
      }
      const addResult = await firebase
        .firestore()
        .collection('documents')
        .add({
          ...imageData,
          datecreate: dbTimestamp()
        })
      return addResult.id
    }
  }
}

export const FromUrl = (props) => {
  const { user, firebase } = useContext(KGContext)
  const handleUrl = async (url) =>
    await handleUploadUrl(firebase, url, user.uid)

  return (
    <DialogPrompt
      title='Add From URL'
      label='Image URL'
      clearAfterConfirm
      onConfirm={handleUrl}
    >
      <DuotoneButton icon={faLink}>From URL</DuotoneButton>
    </DialogPrompt>
  )
}
