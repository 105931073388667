import React, { forwardRef, useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Slide,
  Slider,
  Typography,
  withWidth
} from '@material-ui/core'
import { KGContext } from '../KGProvider'
import { ImageDisplay } from '../ImageDisplay'
import { ImagePicker } from '../ImagePicker'
import propTypes from 'prop-types'
import { ActionIcon } from '../ActionIcon'
import {
  faArrowsAlt,
  faDesktop,
  faMobile
} from '@fortawesome/pro-duotone-svg-icons'
import { useTranslation } from 'react-i18next'

export const FeatureImage = withWidth()(({ width, image, ...props }) => {
  const isMobile = ['xs', 'sm'].includes(width)
  return image ? (
    <ImageDisplay
      size={props.size}
      image={image}
      ratio={isMobile ? 1 : 1 / 4}
      pos={image.pos}
      {...props}
    />
  ) : null
})

export const FeatureImageEdit = ({ value, onChange, ...props }) => {
  const { t } = useTranslation()
  const { user, firebaseConfig } = useContext(KGContext)
  const [open, setOpen] = useState({
    move: false
  })
  const [pc, setPc] = useState(true)

  const handleChange = (imgs) => {
    if (imgs.length) {
      const { thumbnail, medium, url, original } = imgs[0].content
      onChange({
        thumbnail,
        medium,
        url,
        original,
        id: imgs[0].id,
        blurhash: imgs[0].blurhash
      })
    }
  }
  const handleRemove = () => onChange(null)
  const handleOpen = (key, value) => () =>
    setOpen((o) => ({ ...o, [key]: value }))
  const handleMoveSave = (pos) => {
    onChange({ ...value, pos })
  }

  return (
    <List disablePadding>
      <ListItem divider>
        <Box flex={1} flexDirection='column'>
          <Box display='flex' alignItems='center' mb={1}>
            <Typography variant='caption' color='textSecondary'>
              {t('FeatureImage')}
            </Typography>
            <Box flex={1} />
            <ActionIcon
              icon={pc ? faDesktop : faMobile}
              onClick={() => setPc(!pc)}
            />
            <ActionIcon
              icon={faArrowsAlt}
              onClick={handleOpen('move', !open.move)}
            />
            <DialogMove
              image={value}
              open={open.move}
              onClose={handleOpen('move', false)}
              onSave={handleMoveSave}
            />
          </Box>
          <Grid container justifyContent='center' spacing={1}>
            {Boolean(value) && (
              <Grid item xs={12}>
                <ImageDisplay
                  image={value}
                  pos={value.pos}
                  ratio={pc ? 1 / 3 : 1}
                />
              </Grid>
            )}
            <Grid item xs={Boolean(value) ? 6 : 12}>
              <ImagePicker
                user={user}
                config={firebaseConfig}
                onConfirm={handleChange}
              >
                <Button fullWidth variant='outlined'>
                  {t('Change')}
                </Button>
              </ImagePicker>
            </Grid>
            {Boolean(value) && (
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='secondary'
                  onClick={handleRemove}
                >
                  {t('Remove')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </ListItem>
    </List>
  )
}
FeatureImageEdit.propTypes = {
  value: propTypes.any,
  onChange: propTypes.func.isRequired
}

const Transition = forwardRef((props, ref) => (
  <Slide ref={ref} direction='right' {...props} />
))

export const DialogMove = (props) => {
  const [pos, setPos] = useState({
    left: '50%',
    top: '50%'
  })

  const handleChange = (key) => (e, n) =>
    setPos((s) => ({ ...s, [key]: `${n}%` }))
  const handleSave = () => {
    if (props.onSave && props.onClose) {
      props.onSave(pos)
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.image && props.image.pos) {
      setPos((s) => ({
        left: props.image.pos.left || '50%',
        top: props.image.pos.top || '50%'
      }))
    }
  }, [props.open, props.image])

  return props.image ? (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>Position</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              style={{ height: '100%' }}
              flex={1}
            >
              <ImageDisplay
                image={props.image}
                ratio={1 / 4}
                pos={pos}
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageDisplay image={props.image} ratio={1} pos={pos} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' color='textSecondary'>
              Left
            </Typography>
            <Slider
              value={parseInt(pos.left)}
              onChange={handleChange('left')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' color='textSecondary'>
              Top
            </Typography>
            <Slider value={parseInt(pos.top)} onChange={handleChange('top')} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color='primary'>
          Save
        </Button>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  ) : null
}
DialogMove.propTypes = {
  open: propTypes.bool.isRequired,
  onSave: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired
}
