import React from 'react'
import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import { ImageDisplay } from '../ImageDisplay'
import propTypes from 'prop-types'
import { CardButton } from './button'
import { CardMore } from './more'

export const CardContainer = (props) => {
  return <Grid container spacing={2} {...props} />
}

export const Card = withStyles((theme) => ({
  container: {
    position: 'relative',
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius * 2,
    transition: 'transform 0.25s',
    overflow: 'hidden',
    '&:before': {
      content: "''",
      display: 'block',
      paddingTop: 'calc(100%)'
    },
    '&:hover': {
      transform: 'translateY(-8px)'
    }
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden'
  },
  actions: {
    padding: theme.spacing(1, 2),
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    '& [class*="MuiButton"]:not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  }
}))(
  ({
    classes,
    primary,
    primaryTypographyProps,
    secondary,
    secondaryTypographyProps,
    tertiary,
    tertiaryTypographyProps,
    cover,
    actions,
    GridItemProps,
    ...props
  }) => {
    return (
      <Grid item xl={2} lg={3} md={4} sm={6} xs={12} {...GridItemProps}>
        <div className={classes.container}>
          {props.button ? (
            <CardButton {...props} />
          ) : (
            <div className={classes.content}>
              <div className={classes.body}>
                <ImageDisplay image={cover} ratio={1 / 3} />
                <Box p={2}>
                  {primary && (
                    <Box mb={0.5}>
                      <Typography variant='h6' {...primaryTypographyProps}>
                        {primary}
                      </Typography>
                    </Box>
                  )}
                  {secondary && (
                    <Box mb={0.5}>
                      <Typography
                        variant='body1'
                        color='textSecondary'
                        noWrap
                        {...secondaryTypographyProps}
                      >
                        {secondary}
                      </Typography>
                    </Box>
                  )}
                  {tertiary && (
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      noWrap
                      {...tertiaryTypographyProps}
                    >
                      {tertiary}
                    </Typography>
                  )}
                </Box>
              </div>
              {actions && <div className={classes.actions}>{actions}</div>}
            </div>
          )}
          { props.more && <CardMore>{props.more}</CardMore> }
        </div>
      </Grid>
    )
  }
)
Card.propTypes = {
  primary: propTypes.node,
  primaryTypographyProps: propTypes.object,
  secondary: propTypes.node,
  secondaryTypographyProps: propTypes.object,
  tertiary: propTypes.node,
  tertiaryTypographyProps: propTypes.object,
  cover: propTypes.object,
  actions: propTypes.node,
  more: propTypes.node,
}
