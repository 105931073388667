import React from 'react'
import clsx from 'clsx'
import { Box, Button, IconButton, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen, faTrash } from '@fortawesome/pro-duotone-svg-icons'

import { ImageDisplay } from '../../ImageDisplay'
import { FeatureTypography } from '../../components'

export const SlideItem = withStyles((theme) => ({
  root: {},
  full: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  image: {
    '&>img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      filter: 'brightness(70%)'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  },
  button: {
    borderColor: 'white',
    color: 'inherit',
  },
  remove: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: 'white',
  },
}))(({ classes, cover, title, className, ...props }) => {
  return (
    <div className={clsx(className)}>
      <ImageDisplay
        className={clsx(classes.image, classes.full)}
        image={cover}
      />
      <div className={clsx(classes.content, classes.full)}>
        <Box textAlign="center">
          <FeatureTypography>{title}</FeatureTypography>
          <Box mb={2} />
          <Button className={classes.button} variant="outlined" startIcon={<FontAwesomeIcon icon={faBookOpen} />}>อ่านต่อ</Button>
        </Box>
      </div>
      <IconButton size="small" className={classes.remove} color="secondary" onClick={props.onRemove}>
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    </div>
  )
})
