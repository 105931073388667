import React, { useEffect } from 'react'

import { Container } from '../container'
import { Question } from '../question'
import { shuffle } from '../shuffle'
import { ViewParagraph } from '../view.paragraph'
import { ImageDisplay } from '../image.display'
import arrayMove from 'array-move'

import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import { Box, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/pro-duotone-svg-icons'

const AnswerBox = withStyles((theme) => ({
  root: {
    cursor: 'move',
    display: 'flex',
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.modal + 1 || 1301,
    color: theme.palette.text.secondary,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    },
    '& .public-DraftStyleDefault-block': {
      margin: '0 !important'
    }
  }
}))(Box)

const Primary = ({ value, ...props }) => {
  if (!value || !value.type) {
    return props.label || 'Empty content'
  } else {
    switch (value.type) {
      case 'paragraph':
        return <ViewParagraph value={value.value} />
      case 'image':
        if (value.value && value.value.thumbnail) {
          return <ImageDisplay image={value.value} />
        }
      default:
        return 'Empty content'
    }
  }
}

export const SortListItem = sortableElement(({ children, ...props }) => (
  <AnswerBox>
    <Box mr={2}>
      <FontAwesomeIcon icon={faArrowsAlt} color='inherit' />
    </Box>
    <Box flex={1}>{children}</Box>
  </AnswerBox>
))

const SortList = sortableContainer(({ children }) => {
  return <div style={{ marginTop: 16 }}>{children}</div>
})

export const Sorting = ({ value, onCheckChange, checked, ...props }) => {
  const handleCheck = (value) => onCheckChange && onCheckChange(value)
  const handleDragEnd = ({ oldIndex, newIndex }) => {
    let newArray = arrayMove(checked, oldIndex, newIndex)
    handleCheck(newArray)
  }

  useEffect(() => {
    if (value.options && !checked) {
      let cache = shuffle(value.options.map((o) => o.key))
      handleCheck(cache)
    }
  }, [value.options, checked])

  return (
    <Container type={value.type}>
      <Question value={value} />
      <SortList onSortEnd={handleDragEnd}>
        {Array.isArray(checked) &&
          checked.map((key, index) => {
            const data = value.options.find((o) => o.key === key)
            return (
              <SortListItem index={index} key={key}>
                <Primary value={data} />
              </SortListItem>
            )
          })}
      </SortList>
    </Container>
  )
}
