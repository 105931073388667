import React from 'react'
import { usePostEdit } from '../context'
import { BackLink } from '../../BackLink'
import { TitleEdit } from '../../TitleEdit'
import { FeatureImageEdit } from '../../FeatureImage'
import { SaveButton } from '../../SaveButton'
import { PEMap } from '../map'

export const PESidebar = (props) => {
  const {
    back,
    datas: [data],
    handleChangeValue,
    handleSave,
  } = usePostEdit()

  return (
    <React.Fragment>
      {back && <BackLink to={back} />}
      <SaveButton onSave={handleSave} />
      <TitleEdit
        value={data.title || ''}
        onChange={handleChangeValue('title')}
      />
      <FeatureImageEdit
        value={data.cover}
        onChange={handleChangeValue('cover')}
      />
      <PEMap />
    </React.Fragment>
  )
}
