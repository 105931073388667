import { ListItem, Typography, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { KGContext } from '../KGProvider'

export const ListItemLink = withStyles((theme) => ({
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white',
    transition: 'background-color 0.25s',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark} !important`
    },
    '& [class*="MuiListItemIcon"]': {
      color: 'white'
    },
    '& [class*="MuiListItemText-secondary"]': {
      color: 'rgba(255, 255, 255, 0.5)'
    }
  }
}))((props) => {
  const { Link } = useContext(KGContext)

  return Link ? (
    props.selected || props.disabled ? (
      <ListItem button {...props} />
    ) : (
      <ListItem button component={Link} {...props} />
    )
  ) : (
    <Typography color='error'>Please define "Link" to config</Typography>
  )
})
