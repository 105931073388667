import { faBars, faCaretDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Typography,
  withStyles
} from '@material-ui/core'
import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState
} from 'react'
import { Container } from '../Container'

import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc'
import { ContentEditContext } from './context'
import { Spacing } from './spacing'

const Handle = sortableHandle(
  forwardRef((props, ref) => (
    <Button {...props}>
      <FontAwesomeIcon icon={faBars} />
    </Button>
  ))
)

const MenuItemIcon = forwardRef(({ children, icon, color, ...props }, ref) => (
  <MenuItem ref={ref} {...props}>
    <Typography color={color || 'textPrimary'}>
      {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />}
      {children}
    </Typography>
  </MenuItem>
))

export const PanelItem = sortableElement(
  withStyles((theme) => ({
    root: {
      backgroundColor: 'none',
      border: 'solid 1px',
      borderColor: 'transparent',
      transition: 'background-color 1s, border-color 1s',
      position: 'relative',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        borderColor: theme.palette.grey[300]
      }
    },
    control: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 3),
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1
    },
    spacing: {
      transition: 'background-color 1s',
      opacity: 0.125,
      '&:hover': {
        backgroundColor: theme.palette.warning.light
      }
    }
  }))(({ classes, children, actions, ...props }) => {
    const store = useContext(ContentEditContext)
    const [index, setIndex] = useState(-1)
    const [data, setData] = store.data
    const [anchor, setAnchor] = useState(null)

    const handleOpen = (e) => setAnchor(e.currentTarget)
    const handleClose = () => setAnchor(null)
    const handleRemove = () => {
      setAnchor(null)
      setData((s) => {
        const contents = [...(s.contents || [])].filter(
          (content) => content.key !== props.id
        )
        return { ...s, contents }
      })
    }

    useEffect(() => {
      if (props.id) {
        const index = data.contents.findIndex(
          (content) => content.key === props.id
        )
        setIndex(index)
      }
    }, [props.id])

    return (
      <Fragment>
        <Box className={classes.root}>
          {index > -1 &&
            data.contents[index] &&
            Boolean(data.contents[index].top) && (
              <Box className={classes.spacing} pb={data.contents[index].top} />
            )}
          <Container maxWidth={store.post ? "sm" : "lg"}>{children}</Container>
          <Box
            className={classes.spacing}
            pb={
              Boolean(
                index > -1 &&
                  data.contents[index] &&
                  data.contents[index].bottom &&
                  data.contents[index].bottom > -1
              )
                ? data.contents[index].bottom
                : 3
            }
          />
          <div className={classes.control}>
            <ButtonGroup variant='outlined' color='primary'>
              <Handle />
              {props.secondaryActions}
              <Button onClick={handleOpen}>
                <FontAwesomeIcon icon={faCaretDown} />
              </Button>
            </ButtonGroup>
          </div>
        </Box>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={handleClose}
        >
          {actions}
          <Spacing index={index} />
          <MenuItemIcon color='secondary' onClick={handleRemove}>
            Remove
          </MenuItemIcon>
        </Menu>
      </Fragment>
    )
  })
)

export const PanelContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>
})
