import {
  CircularProgress,
  IconButton,
  Menu,
  makeStyles,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core'
import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faBook,
  faChalkboardTeacher,
  faCodeBranch,
  faDrawPolygon,
  faFileAlt,
  faFileVideo,
  faGlobe,
  faGraduationCap,
  faImage,
  faImagePolaroid,
  faMapMarkerAlt,
  faPaperclip,
  faQuestion,
  faRoute,
  faScroll
} from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'react-router-dom'
import { KGContext } from '../KGProvider'
import { AppIcon } from '../Icons'

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.shape.sidebarWidth || 270,
    maxWidth: '100%',
    padding: theme.spacing(0, 1)
  },
  icon: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...theme.palette.duotone,
    boxSizing: 'border-box',
    '& span': {
      display: 'block'
    },
    "& [class*='MuiTypography']": {
      marginTop: theme.spacing(1)
    }
  }
}))

const icons = {
  feed: faScroll,
  profile: faAddressCard,
  study: faGraduationCap,
  teach: faChalkboardTeacher,
  post: faFileAlt,
  book: faBook,
  slideshow: faImagePolaroid,
  website: faGlobe,
  marker: faMapMarkerAlt,
  route: faCodeBranch,
  area: faDrawPolygon,
  mappack: faRoute,
  image: faImage,
  files: faPaperclip,
  vdo: faFileVideo,
}

const Icon = withStyles((theme) => ({
  root: {
    ...theme.palette.duotone,
    fontSize: 24,
    width: 48,
    height: 48
  }
}))((props) => (
  <IconButton {...props} children={<AppIcon open={props.open} />} />
))

const Content = forwardRef((props, ref) => {
  const { firebase, baseDomain } = useContext(KGContext)
  const classes = useStyles()
  const [state, setState] = useState({
    fetched: false,
    lists: []
  })

  useEffect(() => {
    firebase
      .firestore()
      .collection('settings')
      .doc('menus')
      .get()
      .then((snapshot) => {
        const { lists } = snapshot.data()
        setState((s) => ({ ...s, fetched: true, lists }))
      })
  }, [])

  return (
    <div className={classes.content} ref={ref}>
      <Grid container spacing={1}>
        {!state.fetched ? (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </Grid>
        ) : (
          state.lists.map((item) => (
            <Grid item xs={4} key={item.key}>
              <IconButton
                className={classes.icon}
                component={Link}
                to={item.url}
                target='_blank'
              >
                <FontAwesomeIcon size='2x' icon={icons[item.key] || faQuestion} />
                <Typography>
                  <b>{item.label.toLocaleUpperCase()}</b>
                </Typography>
              </IconButton>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  )
})

export const AppCollect = (props) => {
  const { showCollect } = useContext(KGContext)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return showCollect ? (
    <React.Fragment>
      <Icon open={Boolean(anchorEl)} onClick={handleClick} />
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Content />
      </Menu>
    </React.Fragment>
  ) : null
}
