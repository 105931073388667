import { Checkbox, Grid, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React, { useContext } from 'react'
// import { ImageBlock } from './image.block'
import { Context } from './index.context'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { ListDocs } from '../ListDocs'
const defaultData = {
  fetched: false,
  docs: []
}
export const TabBrowse = (props) => {
  const { user, multiple, ...store } = useContext(Context)
  const [state, setState] = store.state
  const [data, setData] = React.useState({ ...defaultData })

  const isIncludes = (selected, doc) =>
    selected.map((i) => i.id).includes(doc.id)
  const handleSelected = (doc) => () =>
    setState((s) => {
      let selected = [...s.selected]
      if (multiple) {
        if (isIncludes(selected, doc)) {
          selected = selected.filter((i) => i.id !== doc.id)
        } else {
          selected.push(doc)
        }
      } else {
        selected = isIncludes(selected, doc) ? [] : [doc]
      }
      return { ...s, selected }
    })

  React.useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(store.firebaseConfig)
    }
    const unwatch = firebase
      .firestore()
      .collection('documents')
      .where('user', '==', user.uid)
      .where('type', '==', 'file')
      .orderBy('dateedit', 'desc')
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        setData((s) => ({ ...s, fetched: true, docs }))
      })
    return () => {
      unwatch()
      setState((s) => ({ ...s, selected: [] }))
    }
  }, [])

  return (
    <React.Fragment>
      <ListDocs
        fetched={data.fetched}
        divider
        docs={data.docs}
        component={(doc, index) => <ListItem divider dense key={index}>
          <ListItemIcon>
            <Checkbox
              checked={Boolean(isIncludes(state.selected, doc))}
              onClick={handleSelected(doc)}
              color='primary' />
          </ListItemIcon>
          <ListItemText primary={doc.content.name} onClick={handleSelected(doc)} />
        </ListItem>}
      />

      {/* {data.fetched ? (
          data.docs.length ? (
            data.docs.map((doc, index) => (
              <Grid item xs={6} sm={4} md={3} key={doc.id}>
                <ImageBlock
                  file={doc}
                  selected={isIncludes(state.selected, doc)}
                  onClick={handleSelected(doc)}
                />
              </Grid>
            ))
          ) 
          : (
            <Grid item xs={6} sm={4} md={3}>
              <ImageBlock noImage />
            </Grid>
          )
        ) : (
          <Grid item xs={6} sm={4} md={3}>
            <ImageBlock loading />
          </Grid>
        )
        } */}
    </React.Fragment>
  )
}
