import {
  Avatar,
  Box,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { useContext } from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons'
import { useTranslation } from 'react-i18next'
import { ContextChat } from './ctx'

export const BubbleContainer = withStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column-reverse'
  }
}))(({ classes, ...props }) => {
  return (
    <div className={classes.root}>
      <Box className={classes.container} {...props} />
      <Toolbar />
    </div>
  )
})

export const BubbleItem = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: ({ isMy }) => (isMy ? 'row-reverse' : 'row'),
    padding: theme.spacing(1, 3),
    alignItems: 'flex-end'
  }
}))(({ isMy, ...props }) => <Box {...props} />)
BubbleItem.propTypes = {
  isMy: propTypes.bool.isRequired
}

export const BubbleText = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: ({ msg }) =>
      msg?.isMy ? theme.palette.primary.main : theme.palette.grey[200],
    color: ({ msg }) =>
      msg?.isMy ? theme.palette.primary.contrastText : theme.palette.grey[600],
    ...theme.typography.body2,
    whiteSpace: 'pre',
    borderRadius: theme.spacing(2)
  }
}))(({ classes: cs, msg, ...props }) => {
  return (
    <Box className={cs.root} component='span'>
      {msg?.value}
    </Box>
  )
})
BubbleText.propTypes = {
  msg: propTypes.shape({
    type: propTypes.oneOf(['file', 'text']).isRequired
  }).isRequired
}

export const BubbleFile = withStyles((theme) => ({
  root: {
    backgroundColor: ({ msg }) =>
      msg?.isMy ? theme.palette.primary.main : theme.palette.grey[200],
    color: ({ msg }) =>
      msg?.isMy ? theme.palette.primary.contrastText : theme.palette.grey[600],
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    padding: theme.spacing(0, 2, 0, 1),
  }
}))(({ classes: cs, msg, ...props }) => {
  const { t, control } = useContext(ContextChat)

  const params = new URLSearchParams({
    md5: msg?.md5,
    ext: msg?.content?.ext,
    name: msg?.content?.name
  }).toString()

  const getSize = () => {
    let size = msg?.content?.size
    if (size > 1000 * 1000 * 1000) {
      return (size / (1000 * 1000 * 1000)).toFixed(2) + ' GB'
    } else if (size > 1000 * 1000) {
      return (size / (1000 * 1000)).toFixed(2) + ' MB'
    } else if (size > 1000) {
      return Math.ceil(size / 1000) + ' KB'
    } else {
      return `${size} byte`
    }
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ListItem
        dense
        component={Link}
        className={cs.root}
        ContainerComponent='div'
        href={`${control?.uploadURL}/download?${params}`}
        target="_blank"
      >
        <ListItemAvatar>
          {msg?.content?.thumbnail ? (
            <Avatar src={msg?.content?.thumbnail} variant='square' />
          ) : (
            <Avatar variant='square'>
              <FontAwesomeIcon icon={faFileAlt} size='1x' />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={msg?.content?.name}
          secondary={
            <React.Fragment>{`${t('Size')} ${getSize()}`}</React.Fragment>
          }
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'caption', color: 'inherit' }}
        />
      </ListItem>
    </Box>
  )
})
BubbleFile.propTypes = {
  msg: propTypes.shape({
    type: propTypes.oneOf(['file', 'text']).isRequired
  }).isRequired
}

export const BubbleTime = ({ date }) => {
  return (
    <Typography
      variant='caption'
      color='textSecondary'
      style={{ margin: '0 8px' }}
    >
      {moment(date || Date.now()).format('HH:mm')}
    </Typography>
  )
}

export const BubbleDate = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  text: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
    padding: theme.spacing(0, 1)
  }
}))(({ classes, current, after }) => {
  const Cdate = moment(current).format('YYYY-MM')
  const Adate = moment(after).format('YYYY-MM')
  return Cdate !== Adate ? (
    <div className={classes.root}>
      <span className={classes.text}>{moment(current).format('D MMM')}</span>
    </div>
  ) : null
})
