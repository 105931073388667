import React, { useContext, useState, useEffect } from 'react'
import { Fragment } from 'react'
import {
  Avatar,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faEdit } from '@fortawesome/pro-duotone-svg-icons'

import { DialogPre } from '../DialogPre'
import { DialogPrompt } from '../DialogPrompt'
import { KGContext } from '../KGProvider'
import { ImagePicker } from '../ImagePicker'
import { SettingLine } from './setting.line'

const Tr = (props) => <tr {...props} />
const EditIcon = (props) => (
  <IconButton size='small' style={{ marginRight: 8 }} {...props}>
    <FontAwesomeIcon size='xs' icon={faEdit} />
  </IconButton>
)

export const Setting = (props) => {
  const { t } = useTranslation()
  const lang = i18next.language
  const { user } = useContext(KGContext)
 
  const displayName = user && (user.displayName || user.email || user.uid)

  const handleChangeLang = ({ target }) => {
    const value = target.value
    i18next.changeLanguage(value)
    window.localStorage.setItem('defaultLanguage', value)
  }
  const handleChangeDisplayName = async (name) => {
    if (user && user.updateProfile) {
      await user.updateProfile({ displayName: name })
    }
    window.location.reload()
  }
  const handleChangeProfileImage = async ([img]) => {
    if (img && img.content) {
      const { thumbnail, medium, large, original } = img.content
      await user.updateProfile({
        photoURL: thumbnail || medium || large || original
      })
      window.location.reload()
    }
  }

  return (
    <Fragment>
      <DialogPre
        maxWidth='sm'
        title={t('Setting')}
        button={
          <ListItem button>
            <ListItemIcon>
              <FontAwesomeIcon icon={faCog} />
            </ListItemIcon>
            <ListItemText primary={t('Setting')} />
          </ListItem>
        }
      >
        <table border={0} cellSpacing={16} style={{ width: '100%' }}>
          <tbody>
            <Tr>
              <td>{t('Users.DisplayName')}</td>
              <td>
                <DialogPrompt
                  title={t("Change Name")}
                  label={t("Name")}
                  value={displayName}
                  onConfirm={handleChangeDisplayName}
                >
                  <EditIcon />
                </DialogPrompt>
                {displayName}
              </td>
            </Tr>
            <Tr>
              <td rowSpan={2}>{t('Users.ProfileImage')}</td>
              <td align='center'>
                <Avatar
                  src={user && user.photoURL}
                  style={{ width: 128, height: 128 }}
                />
              </td>
            </Tr>
            <Tr>
              <td align='center'>
                <ImagePicker onConfirm={handleChangeProfileImage}>
                  <Button variant='outlined'>{t('Change')}</Button>
                </ImagePicker>
              </td>
            </Tr>
            <Tr>
              <td>{t('Language')}</td>
              <td>
                <Select
                  fullWidth
                  variant='outlined'
                  value={['en', 'th'].includes(lang) ? lang : 'th'}
                  onChange={handleChangeLang}
                >
                  <MenuItem value='en'>English</MenuItem>
                  <MenuItem value='th'>ไทย</MenuItem>
                </Select>
              </td>
            </Tr>
            <Tr>
              <td>{t('Line')}</td>
              <td>
                <SettingLine />
              </td>
            </Tr>
          </tbody>
        </table>
      </DialogPre>
    </Fragment>
  )
}
