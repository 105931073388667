import { TextField, Toolbar, withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { ContextChat } from './ctx'
import { ChatUploadButton } from './upload.button'

export const ChatInputMsgBox = withStyles((theme) => ({
  container: {
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
    position: 'fixed',
    width: `calc(100% - ${theme.shape.sidebarWidth}px)`,
    bottom: 0,
    right: 0,
    backgroundColor: `rgba(255,255,255,0.75)`,
    [theme.breakpoints.down('sm')]: {
      width: `100%`
    }
  }
}))(({ classes, ...props }) => {
  const [text, setText] = useState('')
  const { as, view, control } = useContext(ContextChat)

  const handleSend = async () => {
    await control.send(as, view, {
      type: 'text',
      value: text,
      date: new Date()
    })
    setText('')
  }

  return (
    <div className={classes.container}>
      <Toolbar>
        <ChatUploadButton />
        <TextField
          size='small'
          placeholder='Aa'
          value={text}
          onChange={({ target: { value } }) => setText(value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              handleSend()
            }
          }}
        />
      </Toolbar>
    </div>
  )
})
