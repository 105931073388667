export const defaultTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 704,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    secondary: { main: '#C73D5D' },
    error: { main: '#DC4E34' },
    success: { main: '#6E953B' },
    warning: { main: '#EEA320' },
    primary: { main: '#3C77AE' },
    common: {
      black: '#2C261E'
    },
    background: {
      gradient:
        'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)'
    },
    duotone: {
      '--fa-primary-color': '#3C77AE',
      '--fa-secondary-color': '#EEA320'
    },
    text: {
      primary: '#1d1d1f',
      secondary: '#86868b'
      // primary: '#666'
    }
  },
  typography: {
    body1: {
      fontSize: 18,
      lineHeight: 1.5
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.5
    },
    h1: {
      fontSize: 72,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h2: {
      fontSize: 60,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h3: {
      fontSize: 48,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h4: {
      fontSize: 36,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h5: {
      fontSize: 30,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h6: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    caption: {
      fontSize: 12
    },
    fontFamily: `"Sarabun", "Roboto", "Helvetica", "Arial", sans-serif`,
    htmlFontSize: 16,
    fontSize: 16
  },
  shape: {
    toolbarHeight: 60,
    sidebarWidth: 270
  },
  overrides: {
    MuiListItem: {
      dense: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
        paddingRight: 8
      }
    },
    MuiPaper: {
      elevation0: {
        border: 'solid 1px #e0e0e0'
      }
    },
    MuiToolbar: {
      root: {
        height: 60,
        maxHeight: 60,
        minHeight: '60px !important',
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiContainer: {
      root: {
        maxWidth: 'sm'
      }
    },
    MuiListItemText: {
      secondaryTypographyProps: {
        variant: 'caption'
      }
    },
    MuiList: {
      disablePadding: true
    },
    MuiMenu: {
      elevation: 1,
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    },
    MuiPaper: {
      elevation: 0
    },
    MuiTextField: {
      fullWidth: true,
      variant: 'outlined'
    }
  }
}
