import React from 'react'
// import WYSIWYG from 'react-draft-wysiwyg'
import { Editor } from 'react-draft-wysiwyg'
import { Box, Typography, withStyles } from '@material-ui/core'
import { convertFromRaw, EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useEffect } from 'react'

const Wrapper = withStyles((theme) => ({
  root: {
    '& .public-DraftStyleDefault-block': {
      margin: '0 !important',
      fontSize: theme.typography.body1.fontSize
    },
    '& .rdw-editor-toolbar': {
      display: 'none'
    }
  }
}))(Box)

export const ParagraphView = ({ value, ...props }) => {
  const isHasText =
    value &&
    Array.isArray(value.blocks) &&
    value.blocks.length &&
    value.blocks[0].text

  return isHasText ? (
    <Wrapper>
      <Typography variant='body1' color="textSecondary" component="div">
        <Editor
          editorState={(() => {
            const contentState = convertFromRaw(value)
            const editorState = EditorState.createWithContent(contentState)
            return editorState
          })()}
          readOnly
        />
      </Typography>
    </Wrapper>
  ) : props.displayEmpty ? (
    <Typography>&nbsp;</Typography>
  ) : (
    <Typography color='textSecondary'>[Paragraph]</Typography>
  )
}
