import React, { cloneElement, forwardRef, Fragment, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grow
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef((props, ref) => <Grow ref={ref} {...props} />)

export const DialogAlert = (props) => {
  const [open, setOpen] = useState(false)
  const {t} = useTranslation()

  const handleOpen = (op) => () => setOpen(op)
  const handleClick = (func) => () => {
    if (typeof func === 'function') {
      func()
    }
    setOpen(false)
  }

  return (
    <Fragment>
      {props.children &&
        cloneElement(props.children, {
          onClick: handleOpen(!open)
        })}
      <Dialog
        fullWidth
        maxWidth='xs'
        open={open}
        onClose={handleOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle>{props.title || t('Title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.label}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClick(props.onConfirm)}>
            {props.confirmLabel || t('Confirm')}
          </Button>
          {Array.isArray(props.secondaryButtons) &&
            props.secondaryButtons.map((item, index) => {
              return (
                <Button
                  color={item.color}
                  onClick={handleClick(item.onClick)}
                  key={index}
                >
                  {item.label}
                </Button>
              )
            })}
          <Button onClick={handleOpen(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
