import React from 'react'
import propTypes from 'prop-types'
import { ListItemText } from '@material-ui/core'
import { merge } from 'lodash'

export const KuiListItemText = ({ tx, ...props }) => {
  const { primaryTypographyProps, secondaryTypographyProps, ...others } = props
  switch (tx) {
    case 'sidebar':
      return (
        <ListItemText
          {...others}
          primaryTypographyProps={merge(
            { variant: 'body2' },
            primaryTypographyProps
          )}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      )
    default:
      return (
        <ListItemText
          {...others}
          primaryTypographyProps={merge(
            { color: 'textSecondary' },
            primaryTypographyProps
          )}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      )
  }
}
KuiListItemText.propTypes = {
  tx: propTypes.oneOf(['sidebar', 'body']).isRequired
}
