import React from 'react'
import axios from 'axios'
import { DuotoneButton } from '../DuotoneButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUpload } from '@fortawesome/pro-duotone-svg-icons';
import { genKey } from 'draft-js';
import { KGContext } from '../KGProvider';
import { useTranslation } from 'react-i18next';
import { encodeBlurhash } from '../ImagePicker/index.upload.button';

const uploadURL = 'https://nas.phra.in/upload.php'
const Uploading = async (fileReceive, { user, callback, firebase }) => {
    let file;
    if (fileReceive.length) {
        file = fileReceive[0]
    } else {
        file = fileReceive
    }
    const data = new FormData()
    data.append('upload', file)
    data.append('uid', user.uid)
    const config = {
        onUploadProgress: (progressEvent) => {
            let progress = (progressEvent.loaded / progressEvent.total) * 100
            progress = Math.floor(progress * 1)
            if (typeof callback === 'function') {
                if (progress < 100) {
                    callback(progress, 'Uploading')
                } else {
                    callback(0, 'Converting')
                }
            }
        }
    }
    const result = await axios.post(uploadURL, data, config)
    if (result.data.error) {
        return result.data
    } else {
        const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp
        const query = await firebase
            .firestore()
            .collection('documents')
            .where('user', '==', user.uid)
            .where('md5', '==', result.data.md5)
            .get()
        if (callback) {
            callback(0, 'Fetching')
        }
        if (query.docs.length) {
            await firebase
                .firestore()
                .collection('documents')
                .doc(query.docs[0].id)
                .update({ dateedit: dbTimestamp() })
            return query.docs[0].id
        } else {
            const { md5, ...content } = result.data
            const fileData = {
                content,
                md5,
                user: user.uid,
                dateedit: dbTimestamp(),
                visibility: 'public',
                type: content.mime.includes('image') ? 'image' : 'file',
                parent: 0,
                blurhash: content.mime.includes('image') ? await encodeBlurhash(file) : null,
            }
            const addResult = await firebase
                .firestore()
                .collection('documents')
                .add({
                    ...fileData,
                    datecreate: dbTimestamp()
                })
            return addResult.id
        }
    }
}

const defaultUpload = {
    uploading: false,
    file: null,
    label: '',
    progress: 0
}

export const UploadFileButton = ({ onUploadSuccess, ...props }) => {
    const { t } = useTranslation()
    const { addAlert, user, firebase } = React.useContext(KGContext)
    const [upload, setUpload] = React.useState({ ...defaultUpload })

    const key = genKey()

    const handleUploadFiles = async ({ target }) => {
        if (target.files.length) {
            ;[...target.files].forEach(async (file) => {
                setUpload((s) => ({
                    ...s,
                    uploading: true,
                    file,
                    progress: 0
                }))
                const config = {
                    user: user,
                    callback: (progress, label) =>
                        setUpload((s) => ({ ...s, label, progress })),
                    firebase: firebase
                }
                const result = await Uploading(file, config)
                if (result.error) {
                    console.log(result)
                    addAlert({ label: result.message, severity: 'error' })
                }
                if (onUploadSuccess) {
                    onUploadSuccess(result)
                }
                setUpload({ ...defaultUpload })
            })
        }
    }

    return (
        <React.Fragment>
            <input
                type='file'
                id={`upload-${key}`}
                hidden
                onChange={handleUploadFiles}
            />
            <label htmlFor={`upload-${key}`}>
                <DuotoneButton
                    component='span'
                    variant='outlined'
                    startIcon={
                        <FontAwesomeIcon
                            icon={upload.uploading ? faSpinner : faUpload}
                            pulse={upload.uploading}
                        />
                    }
                    disabled={upload.uploading}
                >
                    {upload.uploading
                        ? `${upload.label} ${upload.progress}%`
                        : "upload"}
                </DuotoneButton>
            </label>
        </React.Fragment>
    )
}