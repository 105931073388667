import React from 'react'
import { ListItem } from '@material-ui/core'
import { DuotoneButton } from '../../../DuotoneButton'
import { injectPageEdit } from '../../edit.context'
import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons'

export const Back = injectPageEdit(
  ({
    store: {
      t,
      states: [, setState]
    },
    ...props
  }) => {
    return (
      <ListItem divider>
        <DuotoneButton
          variant='text'
          size="small"
          icon={faChevronLeft}
          onClick={() => setState((s) => ({ ...s, focus: null }))}
        >
          {t('Back')}
        </DuotoneButton>
      </ListItem>
    )
  }
)
