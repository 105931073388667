import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from '@material-ui/core'
import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState
} from 'react'
import { KuiButton } from '../../Theme'
import pt from 'prop-types'

const Ctx = createContext({})
export const URLProvider = ({ children, ...props }) => {
  const [open, setOpen] = useState(false)
  const store = {
    opens: [open, setOpen]
  }
  return <Ctx.Provider value={store}>{children}</Ctx.Provider>
}
const useURL = () => useContext(Ctx)

export const ImageUrl = forwardRef((props, ref) => {
  const {
    opens: [, setOpen]
  } = useURL()
  return (
    <MenuItem ref={ref} onClick={() => setOpen(true)}>
      {'URL'}
    </MenuItem>
  )
})

export const ImageUrlContent = ({
  value: defaultValue,
  onChange,
  ...props
}) => {
  const {
    opens: [open, setOpen]
  } = useURL()
  const [value, setValue] = useState('')

  const handleClose = () => setOpen(false)
  const handleChange = ({ target: { value } }) => setValue(value)
  const handleConfirm = () => {
    onChange(value)
    setOpen(false)
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>Link URL</DialogTitle>
      <DialogContent>
        <TextField label='URL' value={value} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <KuiButton
          tx='confirm'
          disabled={!Boolean(value)}
          onClick={handleConfirm}
        />
        <KuiButton tx='close' onClick={handleClose} />
      </DialogActions>
    </Dialog>
  )
}
ImageUrlContent.propTypes = {
  value: pt.string,
  onChange: pt.func.isRequired
}
