import React from 'react'
import { genKey } from 'draft-js'
import propTypes from 'prop-types'

const reading = (f) => {
  return new Promise((resolved) => {
    const file = f.length ? f[0] : f
    const reader = new FileReader()
    reader.onload = (e) => {
      const lines = (e?.target?.result || '')
        .split('\n')
        .map((line) => line.split(',').map((cell) => cell.trim()))
      resolved(lines)
    }
    reader.readAsText(file)
  })
}

export const ReadTxt = ({ children, onReady, ...props }) => {
  const key = genKey()

  const handleReadFile = async ({ target: { files } }) =>
    onReady(await reading(files[0]))

  return (
    <React.Fragment>
      <input
        accept='.txt'
        id={`${key}-button-file`}
        type='file'
        hidden
        onChange={handleReadFile}
      />
      <label htmlFor={`${key}-button-file`}>
        {children &&
          React.cloneElement(children, {
            component: 'span'
          })}
      </label>
    </React.Fragment>
  )
}
ReadTxt.propTypes = {
  children: propTypes.node.isRequired,
  onReady: propTypes.func.isRequired
}
