import { createContext, useState, useEffect } from 'react'
import React from 'react'

export const QuizEditorContext = createContext({})

const genKey = () => Math.floor(Math.random() * 1000000);

const QuizEditorProvider = ({ children, onChange, data, ...props }) => {
  const [state, setState] = useState({
    tab: "type"
  })

  const store = {
    ...props,
    data,
    state: [state, setState],
    genKey,
  }

  useEffect(()=>{
    if(typeof onChange === "function"){
      onChange(data);
    }
  }, [data])

  return (
    <QuizEditorContext.Provider value={store}>
      {children}
    </QuizEditorContext.Provider>
  )
}

export const connectQuizEdit = (Comp) => (props) =>
  (
    <QuizEditorProvider {...props}>
      <Comp {...props} />
    </QuizEditorProvider>
  )
